import { Box, Button, Typography } from "@material-ui/core";
import { GoogleMap } from "@react-google-maps/api";
import clsx from "clsx";
import { ActionButton, Icon, IconName, useDeviceTypes } from "halifax";
import React, { useEffect, useMemo } from "react";
import { RouteComponentProps } from "react-router";
import {
  BoundingBox,
  ICoordinates,
  IIdLodgings,
  IResult,
  LocationDescriptorEnum,
  SEARCH_THIS_AREA,
} from "redmond";

import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  AVAILABLE,
  VIEW_HOTELS_NEAR,
  getExperimentVariant,
  useExperiments,
} from "../../../../context/experiments";
import { ViewHotelsNearLocationAutocomplete } from "../ViewHotelsNearLocationAutocomplete";
import { AvailabilityMapContent } from "./components/AvailabilityMapContent";
import { MobileViewHotelsNearSearch } from "./components/MobileViewHotelsNearSearch";
import { AvailabilityMapConnectorProps } from "./container";
import { convertGoogleMapCoordsToICoordinates } from "./googleMapsHelpers";
import "./styles.scss";

export interface IAvailabilityMapProps
  extends AvailabilityMapConnectorProps,
    RouteComponentProps {
  isPreview?: boolean;
  onClick?: () => void;
}

const UPDATED_MAP_STYLES: google.maps.MapTypeStyle[] = [
  {
    featureType: "all",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape.natural",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
];

export const AvailabilityMap = (props: IAvailabilityMapProps) => {
  const {
    lodgingIdInFocus,
    setSearchedMapBound,
    fetchInitialHotelAvailability,
    history,
    isPreview,
    onClick: onClickHandler,
    setViewHotelsNearLocation,
    fetchViewHotelsNearLocationCategories,
    viewHotelsNearLocation,
    viewHotelsNearAvailabilityProperties,
    onClick,
    lodgings,
    setLodgingIdInFocus,
    // TODO: Implement and integrate filters
    // resetFilters,
  } = props;

  const { matchesMobile, matchesDesktop } = useDeviceTypes();

  const [mapRef, setMapRef] = React.useState<google.maps.Map | null>(null);
  const [isMapMoved, setIsMapMoved] = React.useState(false);
  const [viewHotelsNearCoordinates, setViewHotelsNearCoordinates] =
    React.useState<ICoordinates | null>(null);
  const [
    viewHotelsNearMobileSearchOpened,
    setViewHotelsNearMobileSearchOpened,
  ] = React.useState(false);
  const [mapHeight, setMapHeight] = React.useState<string>();

  const expState = useExperiments();

  const viewHotelsNearExperiment = getExperimentVariant(
    expState.experiments,
    VIEW_HOTELS_NEAR
  );
  const isViewHotelsNear = useMemo(() => {
    return viewHotelsNearExperiment === AVAILABLE;
  }, [viewHotelsNearExperiment]);

  useEffect(() => {
    if (!matchesMobile && lodgingIdInFocus) {
      const coords = lodgings.find(
        (lodging) => lodging.lodging.id === lodgingIdInFocus
      )?.lodging.location.coordinates;

      if (coords) {
        mapRef?.panTo({
          lat: coords.lat,
          lng: coords.lon,
        });
        // ensure map tooltip is also in view
        mapRef?.panBy(0, -200);
        setIsMapMoved(true);
      }
    }
    return undefined;
  }, [matchesMobile, lodgingIdInFocus]);

  // clear map searches when map unmounts
  useEffect(() => {
    return () => {
      if (!matchesMobile) {
        setSearchedMapBound(null);
        setViewHotelsNearLocation(null);
      }
    };
  }, []);

  useEffect(() => {
    // do not show view hotels near if map is in preview mode or search by map
    if (isPreview) {
      return;
    }
    if (viewHotelsNearLocation) {
      const geocoder = new google.maps.Geocoder();
      geocoder
        .geocode({
          placeId: (viewHotelsNearLocation.id as IIdLodgings).lodgingSelection
            .placeId,
        })
        .then(({ results }) => {
          setIsMapMoved(false);
          setViewHotelsNearCoordinates({
            lat: results[0].geometry.location.lat(),
            lon: results[0].geometry.location.lng(),
          });
          trackEvent({
            eventName: "view_hotels_near",
            ...viewHotelsNearAvailabilityProperties,
          });
          fetchInitialHotelAvailability(history, false, true);
        });
    }
  }, [viewHotelsNearLocation]);

  const updateMapHeight = () => {
    if (matchesDesktop) {
      // note: it has to subtract the height of header
      setMapHeight("100%");
    } else if (isPreview) {
      setMapHeight("130px");
    } else {
      setMapHeight(`${window.innerHeight}px`);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateMapHeight);

    return () => {
      window.removeEventListener("resize", updateMapHeight);
    };
  }, []);

  useEffect(() => {
    updateMapHeight();
  }, [matchesDesktop, isPreview]);

  const checkForDrag = () => {
    onClickHandler && onClickHandler();
  };

  const onMoveMap = () => {
    setIsMapMoved(true);
  };

  const onClickRedoSearchButton = () => {
    setIsMapMoved(false);
    trackEvent({
      eventName: SEARCH_THIS_AREA,
      properties: {},
    });

    const mapBoundFromGoogleMaps = mapRef?.getBounds();

    if (mapBoundFromGoogleMaps) {
      const newMapBound: BoundingBox = {
        northEast: convertGoogleMapCoordsToICoordinates(
          mapBoundFromGoogleMaps.getNorthEast()
        ),
        southWest: convertGoogleMapCoordsToICoordinates(
          mapBoundFromGoogleMaps.getSouthWest()
        ),
        LocationDescriptor: LocationDescriptorEnum.BoundingBox,
      };

      setSearchedMapBound(newMapBound);
      setViewHotelsNearCoordinates(null);
      fetchViewHotelsNearLocationCategories("");
      fetchInitialHotelAvailability(history, true);
      // TODO: Implement and integrate filters
      // resetFilters([HotelAvailabilityFilterType.Neighborhood]);
    }
  };

  const showSearchButton = isMapMoved && !isPreview && !lodgingIdInFocus;

  const getSelectedOption = (option: IResult | null, value: IResult | null) => {
    const selection = value ? (value.id as IIdLodgings) : null;
    const opt = option ? (option.id as IIdLodgings) : null;
    return (
      !!selection &&
      !!opt &&
      selection.lodgingSelection.placeId === opt.lodgingSelection.placeId
    );
  };

  return (
    <Box
      className="lodging-availability-map-holder"
      onClick={(e) => {
        if (
          !(e.target instanceof HTMLInputElement) &&
          !(e.target instanceof HTMLButtonElement)
        ) {
          setLodgingIdInFocus(null);
        }
        checkForDrag();
      }}
    >
      {showSearchButton && (
        <Button
          className={clsx("lodging-availability-map-search-button", {
            "view-hotels-near-enabled": isViewHotelsNear,
          })}
          onClick={onClickRedoSearchButton}
        >
          <Icon name={IconName.MagnifyingGlass} />
          <Typography className="lodging-availability-map-search-button-text">
            Search this area
          </Typography>
        </Button>
      )}
      {isViewHotelsNear && !isPreview ? (
        <div
          onClick={
            matchesMobile
              ? () => setViewHotelsNearMobileSearchOpened(true)
              : undefined
          }
          className={clsx("view-hotels-near-wrapper", {
            mobile: matchesMobile,
          })}
        >
          <ViewHotelsNearLocationAutocomplete
            className={clsx("view-hotels-near-auto-complete", "b2b", {
              mobile: matchesMobile,
            })}
            label={"View hotels near..."}
            isMobile={matchesMobile}
            clearValue={!viewHotelsNearCoordinates}
            getOptionSelected={getSelectedOption}
            customIcon={
              <Icon
                name={IconName.MagnifyingGlass}
                ariaLabel=""
                aria-hidden={true}
              />
            }
            endIcon={viewHotelsNearCoordinates ? faTimesCircle : undefined}
            endIconOnClick={() => {
              setViewHotelsNearCoordinates(null);
              fetchViewHotelsNearLocationCategories("");
            }}
          />
        </div>
      ) : null}
      {isViewHotelsNear && matchesMobile ? (
        <MobileViewHotelsNearSearch
          open={viewHotelsNearMobileSearchOpened}
          onClose={() => setViewHotelsNearMobileSearchOpened(false)}
          onComplete={(value) => {
            if (value) {
              setViewHotelsNearLocation(value);
            }
            setViewHotelsNearMobileSearchOpened(false);
          }}
        />
      ) : null}
      <GoogleMap
        id={clsx("lodging-availability-map", {
          mobile: matchesMobile,
          preview: isPreview,
        })}
        mapContainerStyle={{
          height: mapHeight,
          width: "100%",
          ...(isPreview && { borderRadius: "4px" }),
        }}
        zoom={11}
        onDragEnd={onMoveMap}
        onZoomChanged={onMoveMap}
        onLoad={() => setIsMapMoved(false)}
        options={{
          clickableIcons: false,
          zoomControl: isPreview ? false : matchesDesktop,
          streetViewControl: false,
          mapTypeControl: false,
          disableDoubleClickZoom: true,
          styles: UPDATED_MAP_STYLES,
          fullscreenControl: matchesDesktop,
          ...(isPreview && { gestureHandling: "none" }),
        }}
      >
        <AvailabilityMapContent
          mapProps={props}
          mapRef={mapRef}
          setMapRef={setMapRef}
          history={history}
          viewHotelsNearCoordinates={viewHotelsNearCoordinates}
        />
        {isPreview && (
          <ActionButton
            className={"show-map-button"}
            onClick={() => onClick && onClick()}
            message={
              <Box className="toggle-map-button-text">View Hotels on Map</Box>
            }
          />
        )}
      </GoogleMap>
    </Box>
  );
};
