import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { AddOnCustomize } from "./component";
import {
  selectedCfarIdSelector,
  fetchCfarQuotesCallStateSelector,
  isHotelCfarRefundDisplayShowAmountAdditionalPlacesEnabledSelector,
} from "../../../../ancillary/reducer";
import {
  isCfarAvailableInChosenRoomProductSelector,
  hotelCfarQuoteFromChosenRoomProductSelector,
  cancellationPolicyFromChosenRoomProductSelector,
  additionalInfoFromChosenRoomProductSelector,
  isOptionSelectionCompleteSelector,
  getHotelShopChosenProduct,
  getRefundableRoomProductCorrespondingToChosenRoom,
} from "../../../../shop/reducer";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../../rewards/reducer";
import { IStoreState } from "../../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => {
  return {
    isCfarAvailable: isCfarAvailableInChosenRoomProductSelector(state),
    selectedCfarId: selectedCfarIdSelector(state),
    cfarQuote: hotelCfarQuoteFromChosenRoomProductSelector(state),
    cancellationPolicy: cancellationPolicyFromChosenRoomProductSelector(state),
    additionalInfo: additionalInfoFromChosenRoomProductSelector(state),
    isOptionSelectionComplete: isOptionSelectionCompleteSelector(state),
    fetchCfarQuotesCallState: fetchCfarQuotesCallStateSelector(state),
    rewardsKey: getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    hotelShopChosenProduct: getHotelShopChosenProduct(state),
    refundableRoomProductCorrespondingToChosenRoom:
      getRefundableRoomProductCorrespondingToChosenRoom(state),
    isHotelCfarRefundDisplayShowAmountAdditionalPlaces:
      isHotelCfarRefundDisplayShowAmountAdditionalPlacesEnabledSelector(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AddOnCustomizeConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedAddOnCustomize = withRouter(connector(AddOnCustomize));
