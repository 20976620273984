import { createSelector } from "@reduxjs/toolkit";
import {
  getTotalPriceText,
  twoDecimalFormatter,
  emailRegex,
  phoneRegex,
  getRewardsString,
  roundToTwoDecimals,
  getAgentErrorTitle,
  getAgentErrorSubtitle,
  CurrencyFormatters,
  TEST_CARD_LAST_FOURS,
  IHotelPriceLineItem,
  getSubtotalWithAncillariesLegacy,
  IconName,
  truncateToTwoDecimals,
  getCheckoutCreditBreakdown,
  getIsFreeBreakfast,
} from "halifax";
import {
  Lodging,
  HotelBookErrorType,
  FiatPrice,
  RewardsPrice,
  CallState,
  PaymentAmountEnum,
  PaymentSplitRequest,
  PaymentSplitRequestEnum,
  HotelQuoteScheduleRequest,
  PaymentErrorEnum,
  PaymentVerifyResultEnum,
  ReviewDetailsHotelCheckoutProperties,
  CancellationPolicyEnum,
  HotelCancellationPolicyV2Enum,
  CompleteBuyHotelProperties,
  ModalAlertProperties,
  MODAL_ALERT,
  NO_AVAILABILITY_HOTELS,
  ModalCategoryType,
  ModalScreens,
  RewardsAccountMinimumRequirementState,
  getRewardsAccountMinimumRequirementState,
  PaymentType,
  UserCardPaymentType,
  TypeOfPaymentEnum,
  SplitPaymentType,
  RewardsPaymentType,
  ErrorTitles,
  PriceQuoteHotelProperties,
  AvailableProductEnum,
  MerchantOfRecord,
  PaymentV2Enum,
  Prices,
  ITrackingProperties,
  RewardsAccount,
  AncillaryKindEnum,
  HotelPriceQuoteScheduleRequestV2,
  PartialCompleteBuyHotelHotelCfarOfferFacts,
  getCompleteBuyHotelHotelCfarOfferFacts,
  HotelPriceFreezePurchaseProperties,
  getHotelPriceFreezePurchaseProperties,
  PassengerAncillaryPricing,
  HotelCfarQuote,
  HotelBookType,
  HotelPriceFreezePriceQuoteScheduleRequest,
  HotelPriceFreezeState,
  GetMatchingRoomErrorEnum,
  GetLodgingErrorEnum,
  PriceFreezeHotelPriceQuoteData,
  HotelPricing,
  ViewedHotelDetailsProperties,
  LodgingCollectionEnum,
  ErrorModalType,
  CorpLodging,
  ProductClassEnum,
  CorpRoomProduct,
  Place,
  Cap1HotelCfarRRProperties,
} from "redmond";
import { IStoreState } from "../../../../reducers/types";
import {
  getSearchedNightCount as getSearchedNightCountInAvailability,
  getSelectedLodgingIndex as getSelectedLodgingIndexInAvailability,
  getHotelAvailabilityFromDate,
  getHotelAvailabilityUntilDate,
} from "../../../availability/reducer";
import {
  getAddedAncillariesPricing as getAddedAncillariesPricingInShop,
  hotelCfarQuoteFromChosenRoomProductSelector as hotelCfarQuoteFromChosenRoomProductInShopSelector,
  additionalInfoFromChosenRoomProductSelector,
  getHotelShopEntryPoint,
  getHotelShopChosenProduct,
  getHotelShopChosenProductIndex,
  getHotelShopChosenRoomInfo,
  getHotelShopSelectedLodging,
  // TODO - PF exercise: some of its properties will be missing in the PF exercise flow (because /hotel/shop is not called)
  getHotelCfarOfferChoicePropertiesSelector,
  getHotelShopChosenPriceFreezeOffer,
  getViewedHotelDetailsProperties,
  getHotelShopRecommendedBasedOnPreferences,
  hotelCfarQuoteFromChosenRoomProductSelector,
} from "../../../shop/reducer/selectors";
import {
  getAgentEmail,
  getAllowRewardsWithPolicy,
  getRewardsAccounts,
  getRewardsAccountWithLargestValue,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
} from "../../../rewards/reducer";
import * as textConstants from "./textConstants";
import {
  getFromDate as getFromDateInSearch,
  getNightCount as getNightCountInSearch,
  getUntilDate as getUntilDateInSearch,
  getRoomsCount as getRoomsCountInSearch,
  getAdultsCount,
  getChildrenCount,
} from "../../../search/reducer";
import dayjs from "dayjs";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  ErrorCode,
  Payment,
  PaymentError,
  PaymentOpaqueValue,
  PurchaseError,
  PurchaseErrorEnum,
  ProductError,
} from "@b2bportal/purchase-api";
import {
  DO_NOT_APPLY_OPTION_KEY,
  selectedCfarIdSelector,
  getHasAddedCfarFromModalSelector,
} from "../../../ancillary/reducer";
import {
  hotelPriceFreezeLodgingSelector,
  hotelPriceFreezeVoucherSelector,
  hotelPriceFreezeVoucherReservationSelector,
  nightCountFromPriceFreezeVoucherSelector,
  hotelPriceFreezeTotalFrozenPricingSelector,
  hotelPriceFreezeChosenProductSelector,
  hotelPriceFreezeRoomInfoProductsSelector,
  fetchHotelPriceFreezeDetailsCallStateSelector,
  hotelPriceFreezeGetCreditsStatementSelector,
  hotelPriceFreezeGetCreditsPaymentsSelector,
  getCompleteBuyHotelPriceFreezeExercisePropertiesSelector,
  getPriceQuoteHotelPriceFreezeExercisePropertiesSelector,
  hotelPriceFreezeRoomWithPricingErrSelector,
  hotelPriceFreezeLodgingErrSelector,
  priceFreezePurchaseEntrySelector,
  hotelAvailabilityTrackingSelector,
} from "../../../freeze/reducer";
import { isCorpTenant } from "@capone/common";
import { config } from "../../../../api/config";
import queryStringParser from "query-string";

export const getUserPassengers = (state: IStoreState) =>
  state.hotelBook.userPassengers;

export const getUserSelectedTravelerId = (state: IStoreState) =>
  state.hotelBook.userSelectedTravelerId;

export const getUserSelectedTravelerIds = (state: IStoreState) =>
  state.hotelBook.userSelectedTravelerIds;

export const getUserSelectedTravelersList = (state: IStoreState) =>
  state.hotelBook.userSelectedTravelersList;

export const getUserSelectedTravelersFromIds = createSelector(
  getUserPassengers,
  getUserSelectedTravelerIds,
  (passengers, selectedIds) => {
    return passengers.filter((passenger) => selectedIds.includes(passenger.id));
  }
);

export const getUserPassengerCallState = (state: IStoreState) =>
  state.hotelBook.userPassengerCallState;

export const getConfirmationEmail = (state: IStoreState) =>
  state.hotelBook.confirmationEmailAddress;

export const getConfirmationPhoneNumber = (state: IStoreState) =>
  state.hotelBook.confirmationPhoneNumber;

export const getPaymentMethods = (state: IStoreState) =>
  state.hotelBook.paymentMethods;

export const getPaymentMethod = (state: IStoreState) =>
  state.hotelBook.paymentMethod;

export const getSelectedPaymentMethodId = (state: IStoreState) =>
  state.hotelBook.selectedPaymentMethodId;

export const getListPaymentMethodsCallState = (state: IStoreState) =>
  state.hotelBook.listPaymentMethodCallState;

export const getDeletePaymentMethodCallState = (state: IStoreState) =>
  state.hotelBook.deletePaymentMethodCallState;

export const getFetchPaymentMethodCallState = (state: IStoreState) =>
  state.hotelBook.fetchPaymentMethodCallState;

export const getVerifyPaymentMethodCallState = (state: IStoreState) =>
  state.hotelBook.verifyPaymentMethodCallState;

export const getSession = (state: IStoreState) => state.hotelBook.session;

export const getPriceQuote = (state: IStoreState) => state.hotelBook.priceQuote;

export const getPricingWithAncillaries = (state: IStoreState) =>
  state.hotelBook.pricingWithAncillaries;

export const getHotelAncillaryQuotes = (state: IStoreState) =>
  state.hotelBook.hotelAncillaryQuotes;

export const getHotelPriceFreezePriceQuote = (state: IStoreState) =>
  state.hotelBook.hotelPriceFreezePriceQuote;

export const hotelBookTypeSelector = (state: IStoreState) =>
  state.hotelBook.hotelBookType;

export const isReadyToDisplayHotelSummaryDetailsSelector = createSelector(
  fetchHotelPriceFreezeDetailsCallStateSelector,
  hotelBookTypeSelector,
  (fetchHotelPriceFreezeDetailsCallState, hotelBookType) => {
    switch (hotelBookType) {
      case HotelBookType.PRICE_FREEZE_EXERCISE:
        return fetchHotelPriceFreezeDetailsCallState === CallState.Success;
      case HotelBookType.DEFAULT:
      default:
        return true;
    }
  }
);

export const getHotelBookChosenProduct = createSelector(
  getHotelShopChosenProduct,
  hotelPriceFreezeChosenProductSelector,
  hotelBookTypeSelector,
  (hotelShopChosenProduct, hotelPriceFreezeChosenProduct, hotelBookType) => {
    switch (hotelBookType) {
      case HotelBookType.PRICE_FREEZE_EXERCISE:
        return hotelPriceFreezeChosenProduct;
      case HotelBookType.PRICE_FREEZE_PURCHASE:
      case HotelBookType.DEFAULT:
      default:
        return hotelShopChosenProduct;
    }
  }
);

export const getHotelBookChosenProductIndex = createSelector(
  getHotelShopChosenProductIndex,
  hotelBookTypeSelector,
  (hotelShopChosenProductIndex, hotelBookType) => {
    switch (hotelBookType) {
      case HotelBookType.PRICE_FREEZE_EXERCISE:
        // note: in the PF exercise flow, the frozen room product is the one that's being returned
        return 0;
      case HotelBookType.PRICE_FREEZE_PURCHASE:
      case HotelBookType.DEFAULT:
      default:
        return hotelShopChosenProductIndex;
    }
  }
);

export const getHotelBookChosenRoomInfo = createSelector(
  getHotelShopChosenRoomInfo,
  hotelPriceFreezeRoomInfoProductsSelector,
  hotelBookTypeSelector,
  (
    hotelShopChosenRoomInfo,
    hotelPriceFreezeRoomInfoProducts,
    hotelBookType
  ) => {
    switch (hotelBookType) {
      case HotelBookType.PRICE_FREEZE_EXERCISE:
        return hotelPriceFreezeRoomInfoProducts ?? null;
      case HotelBookType.PRICE_FREEZE_PURCHASE:
      case HotelBookType.DEFAULT:
      default:
        return hotelShopChosenRoomInfo;
    }
  }
);

export const getHotelBookSelectedAvailability = createSelector(
  getHotelShopSelectedLodging,
  hotelPriceFreezeLodgingSelector,
  hotelBookTypeSelector,
  (
    hotelShopSelectedAvailability,
    hotelPriceFreezeLodging,
    hotelBookType
  ): Lodging | CorpLodging | null => {
    switch (hotelBookType) {
      case HotelBookType.PRICE_FREEZE_EXERCISE:
        return hotelPriceFreezeLodging;
      case HotelBookType.PRICE_FREEZE_PURCHASE:
      case HotelBookType.DEFAULT:
      default:
        return hotelShopSelectedAvailability;
    }
  }
);

export const getFromDateInBook = createSelector(
  getFromDateInSearch,
  getHotelAvailabilityFromDate,
  hotelPriceFreezeVoucherReservationSelector,
  hotelBookTypeSelector,
  (
    fromDateInSearch,
    hotelAvailabilityFromDate,
    hotelPriceFreezeVoucherReservation,
    hotelBookType
  ): Date | null => {
    switch (hotelBookType) {
      case HotelBookType.PRICE_FREEZE_EXERCISE:
        return hotelPriceFreezeVoucherReservation
          ? dayjs(hotelPriceFreezeVoucherReservation.start).toDate()
          : null;
      case HotelBookType.DEFAULT:
      default:
        return hotelAvailabilityFromDate ?? fromDateInSearch;
    }
  }
);

export const getNightCountInBook = createSelector(
  getNightCountInSearch,
  nightCountFromPriceFreezeVoucherSelector,
  hotelBookTypeSelector,
  (nightCountInSearch, nightCountFromPriceFreezeVoucher, hotelBookType) => {
    switch (hotelBookType) {
      case HotelBookType.PRICE_FREEZE_EXERCISE:
        return nightCountFromPriceFreezeVoucher;
      case HotelBookType.DEFAULT:
      default:
        return nightCountInSearch;
    }
  }
);

export const getUntilDateInBook = createSelector(
  getUntilDateInSearch,
  getHotelAvailabilityUntilDate,
  hotelPriceFreezeVoucherReservationSelector,
  hotelBookTypeSelector,
  (
    untilDateInSearch,
    hotelAvailabilityUntilDate,
    hotelPriceFreezeVoucherReservation,
    hotelBookType
  ): Date | null => {
    switch (hotelBookType) {
      case HotelBookType.PRICE_FREEZE_EXERCISE:
        return hotelPriceFreezeVoucherReservation
          ? dayjs(hotelPriceFreezeVoucherReservation.end).toDate()
          : null;
      case HotelBookType.DEFAULT:
      default:
        return hotelAvailabilityUntilDate ?? untilDateInSearch;
    }
  }
);

export const getRoomsCountInBook = createSelector(
  getRoomsCountInSearch,
  hotelPriceFreezeChosenProductSelector,
  hotelBookTypeSelector,
  (roomsCountInSearch, hotelPriceFreezeChosenProduct, hotelBookType) => {
    switch (hotelBookType) {
      case HotelBookType.PRICE_FREEZE_EXERCISE:
        // note: PF hotels isn't enabled with multi-room, so "1" is a safe default value
        return hotelPriceFreezeChosenProduct?.bedTypes.rooms.length ?? 1;
      case HotelBookType.PRICE_FREEZE_PURCHASE:
      case HotelBookType.DEFAULT:
      default:
        return roomsCountInSearch;
    }
  }
);

export const getSearchedNightCountInBook = createSelector(
  getSearchedNightCountInAvailability,
  nightCountFromPriceFreezeVoucherSelector,
  hotelBookTypeSelector,
  (
    searchedNightCountInAvailability,
    nightCountFromPriceFreezeVoucher,
    hotelBookType
  ) => {
    switch (hotelBookType) {
      case HotelBookType.PRICE_FREEZE_EXERCISE:
        return nightCountFromPriceFreezeVoucher;
      case HotelBookType.DEFAULT:
      default:
        return searchedNightCountInAvailability;
    }
  }
);

export const getSelectedLodgingIndexInBook = createSelector(
  getSelectedLodgingIndexInAvailability,
  hotelBookTypeSelector,
  (selectedLodgingIndexInAvailability, hotelBookType) => {
    switch (hotelBookType) {
      case HotelBookType.PRICE_FREEZE_EXERCISE:
        // note: in the PF exercise flow, the frozen room product is the one that's being returned
        return 0;
      case HotelBookType.DEFAULT:
      default:
        return selectedLodgingIndexInAvailability;
    }
  }
);

export const getPurchasePriceQuotePricing = createSelector(
  getPriceQuote,
  getPricingWithAncillaries,
  getHotelPriceFreezePriceQuote,
  hotelBookTypeSelector,
  (
    priceQuote,
    pricingWithAncillaries,
    hotelPriceFreezePriceQuote,
    hotelBookType
  ):
    | {
        payNowTotal: Prices;
        hotelPricing?: HotelPricing;
        priceFreezeDeposit?: PriceFreezeHotelPriceQuoteData;
      }
    | undefined => {
    switch (hotelBookType) {
      case HotelBookType.PRICE_FREEZE_PURCHASE: {
        return hotelPriceFreezePriceQuote
          ? {
              payNowTotal: hotelPriceFreezePriceQuote.totalAmount,
              priceFreezeDeposit: hotelPriceFreezePriceQuote,
            }
          : undefined;
      }
      /*
        For Hotel Ancillaries, the priceQuote is only the hotelQuote and doesn't include
        the ancillary costs. The ancillary costs are included in the combinedPricing that
        comes from the BE and is stored in the priceQuoteWithAncillaries. If
        priceQuoteWithAncillaries is available, we want to use that for the total amount due.
      */
      case HotelBookType.PRICE_FREEZE_EXERCISE:
      case HotelBookType.DEFAULT:
      default: {
        const hotelPricing = pricingWithAncillaries
          ? pricingWithAncillaries
          : priceQuote?.pricing;

        return hotelPricing
          ? {
              payNowTotal: hotelPricing.payNowTotal,
              hotelPricing,
            }
          : undefined;
      }
    }
  }
);

export const getHotelCfarQuoteInCheckout = createSelector(
  getHotelAncillaryQuotes,
  hotelCfarQuoteFromChosenRoomProductInShopSelector,
  (
    hotelAncillaryQuotes,
    hotelCfarQuoteFromChosenRoomProductInShop
  ): HotelCfarQuote | null =>
    hotelAncillaryQuotes?.cfar ?? hotelCfarQuoteFromChosenRoomProductInShop
);

export const getPriceQuoteErrors = (state: IStoreState) =>
  state.hotelBook.priceQuoteErrors;

export const getSchedulePriceQuoteError = (state: IStoreState) =>
  state.hotelBook.schedulePriceQuoteError;

export const getScheduleBookError = (state: IStoreState) =>
  state.hotelBook.scheduleBookError;

export const getPollPriceQuoteCallState = (state: IStoreState) =>
  state.hotelBook.pollPriceQuoteCallState;

export const getSchedulePriceQuoteCallState = (state: IStoreState) =>
  state.hotelBook.schedulePriceQuoteCallState;

export const getConfirmationDetails = (state: IStoreState) =>
  state.hotelBook.confirmationDetails;

export const getHotelPriceFreezeConfirmationDetails = (state: IStoreState) =>
  state.hotelBook.hotelPriceFreezeConfirmationDetails;

export const getConfirmationDetailsError = (state: IStoreState) =>
  state.hotelBook.confirmationDetailsErrors;

export const getConfirmationDetailsCallState = (state: IStoreState) =>
  state.hotelBook.confirmationDetailsCallState;

export const getOffers = (state: IStoreState) => state.hotelBook.offers;

export const getOfferToApply = (state: IStoreState) =>
  state.hotelBook.offerToApply;

export const getOfferAmountToApplySelector = createSelector(
  getOfferToApply,
  (offer) => offer?.amount
);

export const getCredit = (state: IStoreState) => {
  const credit = state.hotelBook.credit;
  if (credit) {
    credit.amount.amount = truncateToTwoDecimals(credit.amount.amount);
  }
  return credit;
};

export const getCreditToApply = (state: IStoreState) => {
  const credit = state.hotelBook.creditToApply;
  if (credit) {
    credit.amount.amount = truncateToTwoDecimals(credit.amount.amount);
  }
  return credit;
};

export const getTravelWalletItemsToApply = createSelector(
  getOfferToApply,
  getCreditToApply,
  (offerToApply, creditToApply) => ({
    offerToApply,
    creditToApply,
  })
);

export const isTravelOffersOrCreditAvailableSelector = createSelector(
  getOffers,
  getCredit,
  (offers, credit): boolean => (!!offers && offers.length > 0) || !!credit
);

export const getFetchApplicableTravelWalletItemsCallState = (
  state: IStoreState
) => state.hotelBook.fetchApplicableTravelWalletItemsCallState;

export const getBestOfferOverall = (state: IStoreState) =>
  state.hotelBook.bestOfferOverall;

export const getRoomProductPricingEstimate = createSelector(
  getHotelBookChosenProduct,
  (product) => {
    return product?.total || null;
  }
);

export const getRoomProductPricingEstimateWithAllFees = createSelector(
  getHotelBookChosenProduct,
  (product) => {
    return product?.tripTotal || null;
  }
);

export const getAddedAncillariesPricingInCheckout = createSelector(
  getAddedAncillariesPricingInShop,
  getHotelCfarQuoteInCheckout,
  (addedAncillaryPricing, hotelCfarQuote): PassengerAncillaryPricing[] => {
    return addedAncillaryPricing.map((pricing) => {
      switch (pricing.kind) {
        case AncillaryKindEnum.Cfar: {
          if (hotelCfarQuote) {
            return {
              ...pricing,
              premium: hotelCfarQuote.premiumPrices,
              coverageAmount: hotelCfarQuote.coverageAmount,
              coverageOverriding: hotelCfarQuote.coverageOverriding,
            };
          }
          return pricing;
        }
        default: {
          return pricing;
        }
      }
    });
  }
);

export const getPricingEstimateTotal = createSelector(
  getRoomProductPricingEstimate,
  getAddedAncillariesPricingInShop,
  getHotelShopChosenPriceFreezeOffer,
  hotelBookTypeSelector,
  (
    roomProductEstimate,
    addedAncillaryPricing,
    priceFreezeOffer,
    hotelBookType
  ): Prices | null => {
    switch (hotelBookType) {
      case HotelBookType.PRICE_FREEZE_PURCHASE: {
        return priceFreezeOffer?.pricing.deposit ?? null;
      }
      case HotelBookType.PRICE_FREEZE_EXERCISE:
      case HotelBookType.DEFAULT:
      default: {
        return roomProductEstimate
          ? getSubtotalWithAncillariesLegacy(
              roomProductEstimate,
              addedAncillaryPricing
            )
          : null;
      }
    }
  }
);

export const getPricingEstimateTotaWithAllFees = createSelector(
  getRoomProductPricingEstimateWithAllFees,
  getAddedAncillariesPricingInShop,
  getHotelShopChosenPriceFreezeOffer,
  hotelBookTypeSelector,
  (
    roomProductEstimate,
    addedAncillaryPricing,
    priceFreezeOffer,
    hotelBookType
  ): Prices | null => {
    switch (hotelBookType) {
      case HotelBookType.PRICE_FREEZE_PURCHASE: {
        return priceFreezeOffer?.pricing.deposit ?? null;
      }
      case HotelBookType.PRICE_FREEZE_EXERCISE:
      case HotelBookType.DEFAULT:
      default: {
        return roomProductEstimate
          ? getSubtotalWithAncillariesLegacy(
              roomProductEstimate,
              addedAncillaryPricing
            )
          : null;
      }
    }
  }
);

export const getPriceDifferenceAcknowledged = (state: IStoreState) =>
  state.hotelBook.priceDifferenceAcknowledged;

export const getPriceDifference = createSelector(
  getPricingEstimateTotal,
  getPriceDifferenceAcknowledged,
  getPurchasePriceQuotePricing,
  (
    estimate,
    acknowledged,
    purchasePriceQuotePricing
  ): {
    hasDifference: boolean;
    isIncrease: boolean;
    predictedTotal: number;
    priceQuoteTotal: number;
    amount?: string;
  } => {
    if (!purchasePriceQuotePricing || !estimate || acknowledged) {
      return {
        hasDifference: false,
        isIncrease: false,
        predictedTotal: 0,
        priceQuoteTotal: 0,
      };
    }

    const predictedTotal = estimate.fiat.value;
    const priceQuoteTotal = purchasePriceQuotePricing.payNowTotal.fiat.value;

    return {
      // If the difference is within a dollar we don't show the error modal
      hasDifference: Math.abs(priceQuoteTotal - predictedTotal) >= 1,
      isIncrease: priceQuoteTotal > predictedTotal,
      amount: Math.abs(priceQuoteTotal - predictedTotal)
        .toFixed(0)
        .toString(),
      predictedTotal,
      priceQuoteTotal,
    };
  }
);

export const getRewardsConversionFailed = (state: IStoreState) =>
  state.hotelBook.rewardsConversionFailed;

interface IHotelPriceFreezeExerciseProps {
  voucherId: string;
  refundAmount: FiatPrice;
  depositFiat?: FiatPrice;
  getDetailsProps: {
    voucherState: HotelPriceFreezeState;
    isCallStateFailed: boolean;
    isLodgingNotAvailable: boolean;
  };
  depositOpaqueValue?: any;
  discountOpaqueValue?: any;
}

export const hotelPriceFreezeExercisePropsSelector = createSelector(
  hotelPriceFreezeVoucherSelector,
  hotelPriceFreezeTotalFrozenPricingSelector,
  hotelPriceFreezeGetCreditsStatementSelector,
  hotelPriceFreezeGetCreditsPaymentsSelector,
  hotelPriceFreezeLodgingErrSelector,
  hotelPriceFreezeRoomWithPricingErrSelector,
  fetchHotelPriceFreezeDetailsCallStateSelector,
  (
    hotelPriceFreezeVoucher,
    totalFrozenPricing,
    getCreditsStatement,
    getCreditsPayments,
    lodgingErr,
    roomWithPricingErr,
    fetchHotelPriceFreezeDetailsCallState
  ): IHotelPriceFreezeExerciseProps | undefined => {
    if (!!hotelPriceFreezeVoucher && !!totalFrozenPricing) {
      const isCallStateFailed =
        fetchHotelPriceFreezeDetailsCallState === CallState.Failed ||
        (fetchHotelPriceFreezeDetailsCallState === CallState.Success &&
          !!roomWithPricingErr);
      // note: see https://hopper-jira.atlassian.net/wiki/spaces/CAPONE/pages/5826611519/Technical+Details#Failure-Modes.2
      const isLodgingNotAvailable =
        fetchHotelPriceFreezeDetailsCallState === CallState.Success &&
        ((!!roomWithPricingErr?.GetMatchingRoomError &&
          [
            GetMatchingRoomErrorEnum.NoLodgingDetailAvailable,
            GetMatchingRoomErrorEnum.LodgingNotAvailable,
            GetMatchingRoomErrorEnum.NoMatchingRoomForVoucher,
          ].includes(roomWithPricingErr.GetMatchingRoomError)) ||
          (!!lodgingErr?.GetLodgingError &&
            [GetLodgingErrorEnum.LodgingNotFound].includes(
              lodgingErr.GetLodgingError
            )));

      return {
        voucherId: hotelPriceFreezeVoucher.id,
        refundAmount: hotelPriceFreezeVoucher.deposit.fiat,
        depositFiat:
          getCreditsStatement?.depositCredit.fiat ??
          hotelPriceFreezeVoucher.deposit.fiat,
        getDetailsProps: {
          voucherState: hotelPriceFreezeVoucher.state,
          isCallStateFailed,
          isLodgingNotAvailable,
        },
        depositOpaqueValue: getCreditsPayments?.deposit.value,
        discountOpaqueValue: getCreditsPayments?.discount.value,
      };
    }

    return undefined;
  }
);

export const getHasError = createSelector(
  getUserPassengerCallState,
  getPriceDifference,
  getPriceDifferenceAcknowledged,
  getPollPriceQuoteCallState,
  getScheduleBookError,
  getSchedulePriceQuoteError,
  getConfirmationDetailsCallState,
  getRewardsConversionFailed,
  getVerifyPaymentMethodCallState,
  hotelPriceFreezeExercisePropsSelector,
  hotelBookTypeSelector,
  (
    passengerCallState,
    priceDifference,
    priceDifferenceAcknowledged,
    priceQuoteCallState,
    scheduleBookError,
    schedulePriceQuoteError,
    confirmationDetailsCallState,
    rewardsConversionFailed,
    verifyPaymentMethodCallState,
    hotelPriceFreezeExerciseProps,
    hotelBookType
  ): boolean =>
    passengerCallState === CallState.Failed ||
    (priceDifference.hasDifference && !priceDifferenceAcknowledged) ||
    !!rewardsConversionFailed ||
    !!scheduleBookError ||
    !!schedulePriceQuoteError ||
    verifyPaymentMethodCallState === CallState.Failed ||
    confirmationDetailsCallState === CallState.Failed ||
    priceQuoteCallState === CallState.Failed ||
    (hotelBookType === HotelBookType.PRICE_FREEZE_EXERCISE &&
      !!hotelPriceFreezeExerciseProps &&
      (hotelPriceFreezeExerciseProps.getDetailsProps.isCallStateFailed ||
        hotelPriceFreezeExerciseProps.getDetailsProps.isLodgingNotAvailable))
);

const getErrorMessage = ({
  error,
  priceFreezeExerciseProps,
  hotelBookType,
}: {
  error: PurchaseError;
  priceFreezeExerciseProps?: IHotelPriceFreezeExerciseProps;
  hotelBookType: HotelBookType;
}): ErrorTitles => {
  const { code = "" } = error as ErrorCode;
  let agentSubtitle = getAgentErrorSubtitle(error.Error);
  let agentTitle = getAgentErrorTitle(error.Error);
  let titles = textConstants.GENERIC_ERROR_TITLES(hotelBookType);

  const handleNoAvailability = (
    code:
      | HotelBookErrorType.LackOfInventory
      | HotelBookErrorType.RateNotAvailable
  ) => {
    switch (hotelBookType) {
      case HotelBookType.PRICE_FREEZE_EXERCISE: {
        const { refundAmount } = priceFreezeExerciseProps ?? {};
        titles = textConstants.REFUND_HOTEL_PRICE_FREEZE_TITLES(refundAmount);
        break;
      }
      case HotelBookType.DEFAULT:
      default: {
        switch (code) {
          case HotelBookErrorType.LackOfInventory:
            titles = textConstants.LACK_OF_INVENTORY_TITLES;
            break;
          case HotelBookErrorType.RateNotAvailable:
            titles = textConstants.RATE_NOT_AVAILABLE_TITLES;
            break;
        }
        break;
      }
    }

    trackEvent({
      eventName: NO_AVAILABILITY_HOTELS,
      properties: {},
    });
  };

  const getPaymentErrorTitles = () => {
    switch (hotelBookType) {
      case HotelBookType.PRICE_FREEZE_EXERCISE:
        return textConstants.PAYMENT_ERROR_TITLES_VARIANT_2;
      case HotelBookType.DEFAULT:
      default:
        return textConstants.PAYMENT_ERROR_TITLES;
    }
  };

  switch (error.Error) {
    case PurchaseErrorEnum.PaymentError:
      const paymentError = error as PaymentError;
      if (paymentError.value.type === Payment.Rewards)
        titles = textConstants.REDEMPTION_FAILED_TITLES;
      break;
    case PaymentErrorEnum.ErrorCode:
      agentSubtitle = getAgentErrorSubtitle(code);
      agentTitle = getAgentErrorTitle(code);

      switch (code) {
        case PaymentErrorEnum.UserCardNotFound:
          titles = textConstants.USER_CARD_ERROR_TITLES;
          break;
        case PaymentErrorEnum.FraudAutoReject:
        case PaymentErrorEnum.LikelyFraud:
          titles = textConstants.FRAUD_TITLES(hotelBookType);
          break;
        case HotelBookErrorType.CheckInMinimumAgeNotMet:
          titles = textConstants.CHECK_IN_AGE_NOT_MET(hotelBookType);
          break;
        case HotelBookErrorType.PaymentError:
          titles = getPaymentErrorTitles();
          break;
        case HotelBookErrorType.LackOfInventory:
        case HotelBookErrorType.RateNotAvailable: {
          handleNoAvailability(code);
          break;
        }
        case PaymentErrorEnum.RedemptionFailure:
          titles = textConstants.REDEMPTION_FAILED_TITLES;
          break;
      }
      break;
    case PurchaseErrorEnum.ProductError:
      const errorEnum = ((error as ProductError).value.value?.LodgingError ||
        (error as ProductError).value.value) as string;
      agentSubtitle = getAgentErrorSubtitle(errorEnum);
      agentTitle = getAgentErrorTitle(errorEnum);
      switch (errorEnum) {
        case PaymentErrorEnum.UserCardNotFound:
          titles = textConstants.USER_CARD_ERROR_TITLES;
          break;
        case PaymentErrorEnum.FraudAutoReject:
        case PaymentErrorEnum.LikelyFraud:
          titles = textConstants.FRAUD_TITLES(hotelBookType);
          break;
        case HotelBookErrorType.CheckInMinimumAgeNotMet:
          titles = textConstants.CHECK_IN_AGE_NOT_MET(hotelBookType);
          break;
        case HotelBookErrorType.PaymentError:
          titles = getPaymentErrorTitles();
          break;
        case HotelBookErrorType.LackOfInventory:
        case HotelBookErrorType.RateNotAvailable: {
          handleNoAvailability(errorEnum);
          break;
        }
        case PaymentErrorEnum.RedemptionFailure:
          titles = textConstants.REDEMPTION_FAILED_TITLES;
          break;
      }
      break;
    default:
  }

  return { ...titles, agentSubtitle, agentTitle };
};

export const getScheduleBookCallState = (state: IStoreState) =>
  state.hotelBook.scheduleBookCallState;

export const priceQuoteParamsSelector = createSelector(
  getFromDateInBook,
  getUntilDateInBook,
  getHotelBookChosenRoomInfo,
  getHotelBookChosenProduct,
  getUserSelectedTravelerId,
  getConfirmationEmail,
  getConfirmationPhoneNumber,
  getHotelBookSelectedAvailability,
  getAgentEmail,
  selectedCfarIdSelector,
  getUserSelectedTravelerIds,
  (
    fromDate,
    untilDate,
    roomInfo,
    product,
    personId,
    emailAddress,
    phoneNumber,
    lodging,
    agentEmail,
    selectedCfarId,
    userSelectedTravelerIds
  ): HotelPriceQuoteScheduleRequestV2 | null => {
    const primaryTravelerId = personId ?? userSelectedTravelerIds[0];
    const additionalGuests = userSelectedTravelerIds.slice(1);

    if (!product || !primaryTravelerId || !lodging) {
      return null;
    } else {
      const hotelQuoteRequest: HotelQuoteScheduleRequest = {
        token: "00000000-0000-0000-0000-000000000000",
        quoteRequest: product?.opaqueQuoteRequest,
        personId: primaryTravelerId,
        emailAddress: emailAddress || "",
        phoneNumber: phoneNumber || "",
        dates:
          fromDate && untilDate
            ? {
                from: dayjs(fromDate).format("YYYY-MM-DD"),
                until: dayjs(untilDate).format("YYYY-MM-DD"),
              }
            : undefined,
        id: lodging.lodging.id,
        name: lodging.lodging.name,
        city: lodging.lodging.city,
        country: lodging.lodging.country,
        starRating: lodging.lodging.starRating,
        providerName: lodging.price?.providerName,
        bedTypes: roomInfo?.roomInfo.beds,
        lodgingCollection: LodgingCollectionEnum.NoCollection,
        additionalGuests,
      };
      if (agentEmail) {
        hotelQuoteRequest.delegatedTo = agentEmail;
      }

      return {
        hotelQuoteRequest,
        hotelAncillaryIds: {
          cfarId:
            selectedCfarId && selectedCfarId?.value !== DO_NOT_APPLY_OPTION_KEY
              ? selectedCfarId
              : undefined,
        },
      };
    }
  }
);

export const priceFreezePriceQuoteParamsSelector = createSelector(
  getHotelBookChosenRoomInfo,
  getHotelBookChosenProduct,
  getHotelShopChosenPriceFreezeOffer,
  getConfirmationEmail,
  getAdultsCount,
  getChildrenCount,
  getHotelBookSelectedAvailability,
  (
    chosenRoomInfo,
    chosenProduct,
    chosenPriceFreezeOffer,
    contactEmail,
    adultsCount,
    childrenCount,
    lodging
  ): HotelPriceFreezePriceQuoteScheduleRequest | null => {
    if (!chosenPriceFreezeOffer || !lodging || !chosenProduct) {
      return null;
    } else {
      const hotelPriceFreezePriceQuoteScheduleRequest: HotelPriceFreezePriceQuoteScheduleRequest =
        {
          lodgingId: chosenPriceFreezeOffer.hotelDetails.lodgingId,
          lodgingName: lodging.lodging.name,
          emailAddress: contactEmail ?? "",
          checkIn: dayjs(chosenPriceFreezeOffer.hotelDetails.checkIn).format(
            "YYYY-MM-DD"
          ),
          checkOut: dayjs(chosenPriceFreezeOffer.hotelDetails.checkOut).format(
            "YYYY-MM-DD"
          ),
          occupancy: adultsCount + childrenCount,
          offerData: {
            id: chosenPriceFreezeOffer.id,
            currency:
              chosenPriceFreezeOffer.pricing.frozenPrice.fiat.currencyCode,
            frozenPrice: chosenPriceFreezeOffer.pricing.frozenPrice.fiat.value,
            deposit: chosenPriceFreezeOffer.pricing.deposit.fiat.value,
            cap: chosenPriceFreezeOffer.pricing.cap.fiat.value,
            durationDays: dayjs
              .duration({ hours: chosenPriceFreezeOffer.durationHours })
              .asDays(),
          },
          roomData: [
            {
              isRefundable:
                chosenProduct?.cancellationPolicyV2?.CancellationPolicyV2 ===
                  HotelCancellationPolicyV2Enum.FullyRefundable ||
                chosenProduct?.cancellationPolicyV2?.CancellationPolicyV2 ===
                  HotelCancellationPolicyV2Enum.PartiallyRefundable,
              roomId: chosenRoomInfo?.roomInfo.roomId,
              roomDescription: chosenRoomInfo?.roomInfo.description,
              roomName: chosenRoomInfo?.roomInfo.name,
              providerName: chosenProduct.providerName,
              bedConfiguration:
                chosenRoomInfo?.roomInfo.beds.bedTypes.map((bedType) => ({
                  bedType: bedType.bedType,
                  // note: we can only freeze one room at a time, see https://hopchat.slack.com/archives/C04CXK7MFMW/p1682957532258409?thread_ts=1682955063.029849&cid=C04CXK7MFMW
                  quantity: 1,
                })) ?? [],
            },
          ],
        };

      return hotelPriceFreezePriceQuoteScheduleRequest;
    }
  }
);

export const bookingInProgressSelector = createSelector(
  getScheduleBookCallState,
  getConfirmationDetailsCallState,
  (scheduleBookCallState, confirmationCallState) => {
    return (
      scheduleBookCallState === CallState.InProcess ||
      confirmationCallState === CallState.InProcess
    );
  }
);

export const hasNoUserPassengersSelector = createSelector(
  getUserPassengers,
  getUserPassengerCallState,
  (userPassengers, userPassengerCallState) => {
    return (
      userPassengers.length === 0 &&
      userPassengerCallState === CallState.Success
    );
  }
);

// PAYMENT

export const getRewardsPaymentAccountReferenceId = (state: IStoreState) =>
  state.hotelBook.rewardsAccountReferenceId;

export const getRewardsPaymentAccount = createSelector(
  getRewardsPaymentAccountReferenceId,
  getRewardsAccounts,
  (accountId, rewardsAccounts) =>
    rewardsAccounts.find((account) => account.accountReferenceId === accountId)
);

export const getRewardsPaymentInFiatCurrency = (state: IStoreState) =>
  state.hotelBook.rewardsPaymentInFiatCurrency;

export const getRewardsPaymentInRewardsCurrency = (state: IStoreState) =>
  state.hotelBook.rewardsPaymentTotal;

export const getRewardsRemainingAfterPurchase = createSelector(
  getRewardsPaymentAccount,
  getRewardsPaymentInFiatCurrency,
  (paymentAccount, rewardsPaymentAmount) => {
    if (!paymentAccount) return "";

    const {
      rewardsCashEquivalent: { value, currencyCode, currencySymbol },
    } = paymentAccount;

    const remainder = value - (rewardsPaymentAmount?.value || 0);

    return getTotalPriceText({
      price: {
        currencyCode,
        currencySymbol,
        value: remainder,
      },
      priceFormatter: twoDecimalFormatter,
    });
  }
);

export const getTravelWalletOfferToApplyAmount = createSelector(
  getTravelWalletItemsToApply,
  (travelWalletItemsToApply) => {
    if (travelWalletItemsToApply.offerToApply) {
      return travelWalletItemsToApply.offerToApply.amount.amount * -1;
    } else {
      return 0;
    }
  }
);

export const getTravelWalletCreditToApplyAmount = createSelector(
  getTravelWalletItemsToApply,
  getPricingEstimateTotal,
  getPurchasePriceQuotePricing,
  getTravelWalletOfferToApplyAmount,
  (
    travelWalletItemsToApply,
    estimate,
    purchasePriceQuotePricing,
    offerAppliedAmount
  ) => {
    const totalFiatValue: number =
      purchasePriceQuotePricing?.payNowTotal.fiat.value ||
      estimate?.fiat.value ||
      0;
    const totalFiatValueWithOfferApplied: number =
      totalFiatValue - offerAppliedAmount;
    if (
      travelWalletItemsToApply.creditToApply &&
      totalFiatValueWithOfferApplied > 0
    ) {
      const maxApplicableCredit =
        travelWalletItemsToApply.offerToApply?.maxApplicableCredit?.amount;
      let amount =
        maxApplicableCredit &&
        maxApplicableCredit <
          travelWalletItemsToApply.creditToApply.amount.amount
          ? maxApplicableCredit
          : travelWalletItemsToApply.creditToApply.amount.amount;
      if (totalFiatValueWithOfferApplied < amount * -1) {
        if (
          maxApplicableCredit &&
          maxApplicableCredit <
            travelWalletItemsToApply.creditToApply.amount.amount &&
          travelWalletItemsToApply.creditToApply.amount.amount * -1 <
            totalFiatValueWithOfferApplied
        ) {
          return travelWalletItemsToApply.creditToApply.amount.amount * -1;
        }
        return totalFiatValueWithOfferApplied;
      }
      if (
        maxApplicableCredit &&
        maxApplicableCredit <
          travelWalletItemsToApply.creditToApply.amount.amount
      ) {
        return travelWalletItemsToApply.creditToApply.amount.amount * -1;
      }
      return amount * -1;
    } else {
      return 0;
    }
  }
);

export const getMaxApplicableTravelWalletCreditOnlyAmount = createSelector(
  getCredit,
  getPricingEstimateTotal,
  getPurchasePriceQuotePricing,
  (credit, estimate, purchasePriceQuotePricing): number => {
    if (purchasePriceQuotePricing && credit) {
      if (
        purchasePriceQuotePricing.payNowTotal.fiat.value <
        credit.amount.amount * -1
      ) {
        return purchasePriceQuotePricing.payNowTotal.fiat.value * -1;
      }
      return credit.amount.amount;
    } else if (estimate && credit) {
      let totalFiatValue = estimate?.fiat.value + credit?.amount.amount;
      if (totalFiatValue < 0) {
        return estimate.fiat.value * -1;
      }
      return credit.amount.amount;
    } else {
      return 0;
    }
  }
);

export const getMaxApplicableTravelWalletCreditAmount = createSelector(
  getCredit,
  getPricingEstimateTotal,
  getPurchasePriceQuotePricing,
  getTravelWalletOfferToApplyAmount,
  (credit, estimate, purchasePriceQuotePricing, offerAppliedAmount): number => {
    if (purchasePriceQuotePricing && credit) {
      if (
        purchasePriceQuotePricing.payNowTotal.fiat.value - offerAppliedAmount <
        credit.amount.amount * -1
      ) {
        return (
          (purchasePriceQuotePricing.payNowTotal.fiat.value -
            offerAppliedAmount) *
          -1
        );
      }
      return credit.amount.amount;
    } else if (estimate && credit) {
      let totalFiatValue =
        estimate?.fiat.value - offerAppliedAmount + credit?.amount.amount;
      if (totalFiatValue < 0) {
        return (estimate.fiat.value - offerAppliedAmount) * -1;
      }
      return credit.amount.amount;
    } else {
      return 0;
    }
  }
);

export const getTotalToPayWithoutTravelWalletItems = createSelector(
  getPricingEstimateTotal,
  getPurchasePriceQuotePricing,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
  (
    estimate,

    purchasePriceQuotePricing,
    selectedAccountReferenceId
  ):
    | {
        fiat: FiatPrice;
        reward: RewardsPrice | undefined;
      }
    | undefined => {
    const payNowTotal = purchasePriceQuotePricing?.payNowTotal ?? estimate;

    if (!payNowTotal) {
      return undefined;
    }

    return {
      fiat: payNowTotal.fiat,
      reward: selectedAccountReferenceId
        ? payNowTotal.rewards[selectedAccountReferenceId]
        : undefined,
    };
  }
);

export const getTotalToPay = createSelector(
  getPricingEstimateTotal,
  getTravelWalletItemsToApply,
  getPurchasePriceQuotePricing,
  (
    estimate,
    travelWalletItemsToApply,
    purchasePriceQuotePricing
  ): Prices | null => {
    let total = purchasePriceQuotePricing
      ? purchasePriceQuotePricing.payNowTotal
      : estimate;

    let rewardsTotal = {};
    if (
      total &&
      (travelWalletItemsToApply.offerToApply ||
        travelWalletItemsToApply.creditToApply)
    ) {
      let walletItemsTotal: number =
        travelWalletItemsToApply.offerToApply?.amount.amount || 0;

      if (travelWalletItemsToApply.creditToApply) {
        const maxApplicableCredit =
          travelWalletItemsToApply.offerToApply?.maxApplicableCredit?.amount;
        if (
          maxApplicableCredit &&
          maxApplicableCredit >
            travelWalletItemsToApply.creditToApply.amount.amount
        ) {
          walletItemsTotal += maxApplicableCredit;
        } else {
          walletItemsTotal +=
            travelWalletItemsToApply.creditToApply.amount.amount;
        }
      }
      Object.keys(total.rewards).forEach((accountId) => {
        if (total) {
          let rewardTotalValue = total.rewards[accountId].value;
          rewardTotalValue =
            total.rewards[accountId].currency === "Cash"
              ? rewardTotalValue + walletItemsTotal
              : rewardTotalValue + walletItemsTotal * 100;
          rewardsTotal[accountId] = {
            currency: total.rewards[accountId].currency,
            currencyDescription: total.rewards[accountId].currencyDescription,
            value: rewardTotalValue > 0 ? rewardTotalValue : 0,
          };
        }
      });
      return {
        fiat: {
          currencyCode: total.fiat.currencyCode,
          currencySymbol: total.fiat.currencySymbol,
          value:
            total.fiat.value + walletItemsTotal > 0
              ? total.fiat.value + walletItemsTotal
              : 0,
        },
        rewards: rewardsTotal,
      };
    }
    return purchasePriceQuotePricing
      ? purchasePriceQuotePricing.payNowTotal
      : estimate;
  }
);

export const getIsTravelWalletCreditPaymentOnly = createSelector(
  getTotalToPay,
  getTravelWalletItemsToApply,
  (total, travelWalletItemsToApply) => {
    if (
      !travelWalletItemsToApply.offerToApply &&
      travelWalletItemsToApply.creditToApply &&
      total?.fiat.value === 0
    ) {
      return true;
    }
    return false;
  }
);

export const getIsTravelWalletOfferPaymentOnly = createSelector(
  getTotalToPay,
  getTravelWalletItemsToApply,
  (total, travelWalletItemsToApply) => {
    if (
      travelWalletItemsToApply.offerToApply &&
      !travelWalletItemsToApply.creditToApply &&
      total?.fiat.value === 0
    ) {
      return true;
    }
    return false;
  }
);

export const getIsStackedTravelWalletPaymentOnly = createSelector(
  getTotalToPay,
  getTravelWalletItemsToApply,
  (total, travelWalletItemsToApply) => {
    if (
      travelWalletItemsToApply.offerToApply &&
      travelWalletItemsToApply.creditToApply &&
      total?.fiat.value === 0
    ) {
      return true;
    }
    return false;
  }
);

export const getIsTravelWalletPaymentOnly = createSelector(
  getIsTravelWalletCreditPaymentOnly,
  getIsTravelWalletOfferPaymentOnly,
  getIsStackedTravelWalletPaymentOnly,
  (
    isTravelWalletCreditPaymentOnly,
    isTravelWalletOfferPaymentOnly,
    isStackedTravelWalletPaymentOnly
  ) => {
    if (
      isTravelWalletCreditPaymentOnly ||
      isTravelWalletOfferPaymentOnly ||
      isStackedTravelWalletPaymentOnly
    ) {
      return true;
    }
    return false;
  }
);

export const getIsCreditCardPaymentRequired = createSelector(
  getTotalToPay,
  getRewardsPaymentInFiatCurrency,
  getIsTravelWalletPaymentOnly,
  getIsStackedTravelWalletPaymentOnly,
  (
    total,
    rewardsPaymentAmount,
    isTravelWalletPaymentOnly,
    isStackedTravelWalletPaymentOnly
  ) => {
    if (isTravelWalletPaymentOnly || isStackedTravelWalletPaymentOnly)
      return false;

    if (!total) return true;
    if (!rewardsPaymentAmount) return true;

    return total.fiat.value > rewardsPaymentAmount.value;
  }
);

export const getTotalCreditCardPaymentRequired = createSelector(
  getTotalToPay,
  getRewardsPaymentInFiatCurrency,
  (estimate, rewardsPaymentAmount) => {
    if (!estimate) return "";

    if (!rewardsPaymentAmount) {
      return getTotalPriceText({
        price: estimate.fiat,
        priceFormatter: twoDecimalFormatter,
      });
    }

    const remainder = estimate.fiat.value - rewardsPaymentAmount.value;
    const total = remainder <= 0 ? 0 : remainder;

    return getTotalPriceText({
      price: {
        currencyCode: estimate.fiat.currencyCode,
        currencySymbol: estimate.fiat.currencySymbol,
        value: total,
      },
      priceFormatter: twoDecimalFormatter,
    });
  }
);

export const getTotalCreditCardPaymentRequiredNumber = createSelector(
  getTotalToPay,
  getRewardsPaymentInFiatCurrency,
  (estimate, rewardsPaymentAmount) => {
    if (!estimate) return null;

    if (!rewardsPaymentAmount) {
      return estimate.fiat.value;
    }

    const remainder = estimate.fiat.value - rewardsPaymentAmount.value;
    const total =
      remainder <= 0 ? 0 : Math.round((remainder + Number.EPSILON) * 100) / 100;
    return total;
  }
);

export const getTotalCreditCardPaymentRequiredInFiatPrice = createSelector(
  getTotalToPay,
  getRewardsPaymentInFiatCurrency,
  (totalToPay, rewardsPaymentAmount): FiatPrice | undefined => {
    if (!totalToPay) return undefined;

    if (!rewardsPaymentAmount) return totalToPay.fiat;

    const remainder = totalToPay.fiat.value - rewardsPaymentAmount.value;
    const total = remainder <= 0 ? 0 : remainder;

    return {
      currencyCode: totalToPay.fiat.currencyCode,
      currencySymbol: totalToPay.fiat.currencySymbol,
      value: total,
    };
  }
);

export const getAddedAncillaryLineItems = createSelector(
  getAddedAncillariesPricingInCheckout,
  (addedAncillariesPricing): IHotelPriceLineItem[] => {
    // CFAR - The Prices should be taken from priceQuote once BE work is done
    return addedAncillariesPricing
      .map((pricing) => {
        switch (pricing.kind) {
          case AncillaryKindEnum.Cfar:
            return {
              icon: "check-shield-blue",
              title: textConstants.CANCEL_FOR_ANY_REASON,
              value: getTotalPriceText({
                price: pricing.premium.fiat,
                priceFormatter: twoDecimalFormatter,
              }),
            };
          default:
            return [];
        }
      })
      .flat();
  }
);

export const hasAddOnBeenAttachedSelector = createSelector(
  getAddedAncillaryLineItems,
  (addedAncillaryLineItems): boolean => {
    return addedAncillaryLineItems.length > 0;
  }
);

export const getPaymentMethodRewardsAccountId = (state: IStoreState) =>
  state.hotelBook.paymentMethodRewardsAccountId;

export const getHotelPricingLineItems = createSelector(
  getSearchedNightCountInBook,
  getRoomsCountInBook,
  getHotelBookChosenProduct,
  getPriceQuote,
  getRewardsPaymentAccount,
  getPaymentMethodRewardsAccountId,
  getRewardsAccounts,
  getAddedAncillaryLineItems,
  getAddedAncillariesPricingInCheckout,
  getPurchasePriceQuotePricing,
  hotelPriceFreezeExercisePropsSelector,
  hotelBookTypeSelector,
  (
    nights,
    roomsCount,
    product,
    priceQuote,
    rewardsPaymentAccount,
    paymentMethodRewardsAccountId,
    rewardsAccounts,
    addedAncillaryLineItems,
    addedAncillaryPricing,
    purchasePriceQuotePricing,
    priceFreezeExerciseProps,
    hotelBookType
  ): IHotelPriceLineItem[][] => {
    const frozenPriceIcon =
      hotelBookType === HotelBookType.PRICE_FREEZE_EXERCISE
        ? IconName.FrozenPrice
        : undefined;
    const { payNowTotal, hotelPricing } = purchasePriceQuotePricing ?? {};

    const getTotalBreakdown = ({
      subtotalFiat,
      taxesFiat,
    }: {
      subtotalFiat: FiatPrice;
      taxesFiat: FiatPrice;
    }): IHotelPriceLineItem[] => {
      const items = [
        {
          title: textConstants.ROOM_TOTAL({
            nights,
            roomsCount,
            hotelBookType,
          }),
          value: getTotalPriceText({
            price: subtotalFiat,
            priceFormatter: twoDecimalFormatter,
          }),
          icon: frozenPriceIcon,
        } as IHotelPriceLineItem,
        {
          title: textConstants.TAXES_AND_FEES,
          value: getTotalPriceText({
            price: taxesFiat,
            priceFormatter: twoDecimalFormatter,
          }),
          tooltip: textConstants.TAXES_AND_FEES_TOOLTIP,
        } as IHotelPriceLineItem,
      ];

      if (
        hotelBookType === HotelBookType.PRICE_FREEZE_EXERCISE &&
        !!priceFreezeExerciseProps?.depositFiat
      ) {
        items.push({
          title: textConstants.PRICE_FREEZE_DEPOSIT_APPLIED,
          value: getTotalPriceText({
            price: {
              ...priceFreezeExerciseProps.depositFiat,
              // note: multiply by -1 to show a negative number, similar to how offer/creditAmountToApply is handled
              value: priceFreezeExerciseProps.depositFiat.value * -1,
            },
            priceFormatter: twoDecimalFormatter,
          }),
          icon: frozenPriceIcon,
          isPriceFreezeDeposit: true,
          boldValue: true,
        } as IHotelPriceLineItem);
      }

      return items.concat(addedAncillaryLineItems);
    };

    if (priceQuote && payNowTotal && hotelPricing && product) {
      const rewardsAccountId = rewardsPaymentAccount
        ? rewardsPaymentAccount.accountReferenceId
        : paymentMethodRewardsAccountId
        ? paymentMethodRewardsAccountId
        : Object.keys(product.total.rewards)[0];
      const includeRewards =
        rewardsAccounts.find(
          (account) => account.accountReferenceId === rewardsAccountId
        )?.allowRewardsRedemption ?? true;

      return [
        getTotalBreakdown({
          subtotalFiat: hotelPricing?.subtotal.fiat,
          taxesFiat: hotelPricing?.taxes.fiat,
        }),
        [
          {
            title: textConstants.DUE_TODAY,

            value: getTotalPriceText({
              price: {
                currencyCode: payNowTotal.fiat.currencyCode,
                currencySymbol: payNowTotal.fiat.currencySymbol,
                value: payNowTotal.fiat.value,
              },
              priceFormatter: twoDecimalFormatter,
            }),
            rewardsValue: includeRewards
              ? getRewardsString(payNowTotal.rewards[rewardsAccountId])
              : undefined,
            boldLabel: true,
            type: "due-today",
          } as IHotelPriceLineItem,
          ...(hotelPricing.feeBreakdown.total
            ? [
                {
                  title: textConstants.ADDITIONAL_FEES,
                  value: getTotalPriceText({
                    price: hotelPricing.feeBreakdown.total,
                    priceFormatter: twoDecimalFormatter,
                  }),
                  type: "additional-fees",
                  tooltip: textConstants.ADDITIONAL_FEES_TOOLTIP,
                } as IHotelPriceLineItem,
              ]
            : []),
          ...(hotelPricing.feeBreakdown.total
            ? [
                {
                  title: textConstants.TOTAL,
                  value: getTotalPriceText({
                    price: hotelPricing.tripTotal.fiat,
                    priceFormatter: twoDecimalFormatter,
                  }),
                  boldLabel: true,
                  type: "total",
                } as IHotelPriceLineItem,
              ]
            : []),
        ],
      ];
    } else if (product) {
      const rewardsAccount = rewardsPaymentAccount
        ? rewardsPaymentAccount.accountReferenceId
        : Object.keys(product.total.rewards)[0];
      const includeRewards =
        rewardsAccounts.find(
          (account) => account.accountReferenceId === rewardsAccount
        )?.allowRewardsRedemption ?? true;
      const dueTodayTotal = getSubtotalWithAncillariesLegacy(
        product.total,
        addedAncillaryPricing
      );
      const total = getSubtotalWithAncillariesLegacy(
        product.tripTotal,
        addedAncillaryPricing
      );

      return [
        getTotalBreakdown({
          subtotalFiat: product.sellRate.fiat,
          taxesFiat: {
            value: Object.keys(product.taxBreakdown).reduce(
              (sum, key) => product.taxBreakdown[key].fiat.value + sum,
              0
            ),
            currencyCode: product.sellRate.fiat.currencyCode,
            currencySymbol: product.sellRate.fiat.currencySymbol,
          },
        }),
        [
          {
            title: textConstants.DUE_TODAY,
            value: getTotalPriceText({
              price: dueTodayTotal.fiat,
              priceFormatter: twoDecimalFormatter,
            }),
            rewardsValue: includeRewards
              ? getRewardsString(dueTodayTotal.rewards[rewardsAccount])
              : undefined,
            boldLabel: true,
            type: "due-today",
          } as IHotelPriceLineItem,
          ...(product.feeBreakdown.total
            ? [
                {
                  title: textConstants.ADDITIONAL_FEES,
                  value: getTotalPriceText({
                    price: product.feeBreakdown.total,
                    priceFormatter: twoDecimalFormatter,
                  }),
                  type: "additional-fees",
                  tooltip: textConstants.ADDITIONAL_FEES_TOOLTIP,
                } as IHotelPriceLineItem,
              ]
            : []),
          ...(product.feeBreakdown.total
            ? [
                {
                  title: textConstants.TOTAL,
                  value: getTotalPriceText({
                    price: total.fiat,
                    priceFormatter: twoDecimalFormatter,
                  }),
                  boldLabel: true,
                  type: "total",
                } as IHotelPriceLineItem,
              ]
            : []),
        ],
      ];
    } else {
      return [];
    }
  }
);

export const getHotelTripTotalInPrices = createSelector(
  getPriceQuote,
  getHotelBookChosenProduct,
  getRewardsPaymentAccount,
  getRewardsAccountWithLargestValue,
  getPurchasePriceQuotePricing,
  (
    priceQuote,
    chosenProduct,
    rewardsPaymentAccount,
    rewardsAccountWithLargestValue,
    purchasePriceQuotePricing
  ): { fiat: FiatPrice; rewards?: RewardsPrice } | null => {
    const { hotelPricing } = purchasePriceQuotePricing ?? {};
    const activeRewardsAccount =
      rewardsPaymentAccount ?? rewardsAccountWithLargestValue;
    const includeRewards = activeRewardsAccount?.allowRewardsRedemption ?? true;

    if (priceQuote && hotelPricing && activeRewardsAccount) {
      return {
        fiat: hotelPricing.tripTotal.fiat,
        rewards: includeRewards
          ? hotelPricing.tripTotal.rewards[
              activeRewardsAccount.accountReferenceId
            ]
          : undefined,
      };
    } else if (chosenProduct && activeRewardsAccount) {
      return {
        fiat: chosenProduct.tripTotal.fiat,
        rewards: includeRewards
          ? chosenProduct.tripTotal.rewards[
              activeRewardsAccount.accountReferenceId
            ]
          : undefined,
      };
    }
    return null;
  }
);

export const getHotelPriceFreezePricingLineItems = createSelector(
  getSearchedNightCountInBook,
  getRoomsCountInBook,
  getRewardsPaymentAccount,
  getRewardsAccounts,
  getPurchasePriceQuotePricing,
  getHotelShopChosenPriceFreezeOffer,
  hotelBookTypeSelector,
  (
    nights,
    roomsCount,
    rewardsPaymentAccount,
    rewardsAccounts,
    purchasePriceQuotePricing,
    priceFreezeOffer,
    hotelBookType
  ): IHotelPriceLineItem[][] => {
    const { totalAmount } = purchasePriceQuotePricing?.priceFreezeDeposit ?? {};
    const { deposit } = priceFreezeOffer?.pricing ?? {};
    const totalPrices = totalAmount ?? deposit;

    if (!totalPrices) {
      return [];
    }

    const rewardsAccount = rewardsPaymentAccount
      ? rewardsPaymentAccount.accountReferenceId
      : Object.keys(totalPrices.rewards)[0];
    const includeRewards =
      rewardsAccounts.find(
        (account) => account.accountReferenceId === rewardsAccount
      )?.allowRewardsRedemption ?? true;

    return [
      [
        {
          title: textConstants.ROOM_TOTAL({
            nights,
            roomsCount,
            hotelBookType,
          }),
          value: textConstants.TOTAL_WITH_PER_NIGHT_FIAT({
            total: totalPrices.fiat,
            // note: disabled due to BE limitation, see https://hopper-jira.atlassian.net/browse/PF-3133
            nights: null,
            perNight: undefined,
          }),
        } as IHotelPriceLineItem,
      ],
      [
        {
          title: textConstants.TOTAL,
          value: getTotalPriceText({
            price: totalPrices.fiat,
            priceFormatter: twoDecimalFormatter,
          }),
          rewardsValue: includeRewards
            ? getRewardsString(totalPrices.rewards[rewardsAccount])
            : undefined,
          boldLabel: true,
          type: "due-today",
        } as IHotelPriceLineItem,
      ],
    ];
  }
);

export const getHotelRewardsAndTotalLineItems = createSelector(
  getRewardsPaymentAccount,
  getRewardsPaymentInRewardsCurrency,
  getRewardsPaymentInFiatCurrency,
  getTotalCreditCardPaymentRequired,
  getSelectedPaymentMethodId,
  getPaymentMethods,
  getTravelWalletItemsToApply,
  getTravelWalletOfferToApplyAmount,
  getTravelWalletCreditToApplyAmount,
  (
    rewardsPaymentAccount,
    rewardsPayment,
    rewardsInFiat,
    totalAmountDueOnCredit,
    paymentMethodId,
    paymentMethods,
    travelWalletItemsToApply,
    offerAmountToApply,
    creditAmountToApply
  ) => {
    const rewardsLineItems = [];

    if (
      rewardsPaymentAccount &&
      (rewardsPaymentAccount.allowRewardsRedemption ?? true)
    ) {
      const fiatPrice = rewardsInFiat ?? {
        currencyCode: rewardsPaymentAccount?.rewardsCashEquivalent.currencyCode,
        currencySymbol:
          rewardsPaymentAccount?.rewardsCashEquivalent.currencySymbol,
        value: 0,
      };

      const priceText = getTotalPriceText({
        price: {
          ...fiatPrice,
          value: fiatPrice.value,
        },
        priceFormatter: twoDecimalFormatter,
      });

      const rewardsText = rewardsPayment
        ? `- ${getRewardsString(rewardsPayment)}`
        : "";
      rewardsLineItems.push({
        title: `${rewardsPaymentAccount?.productDisplayName} ${textConstants.REWARDS}`,
        value: `- ${priceText}`,
        rewardsValue: rewardsText,
      });
    }

    const paymentMethod = paymentMethods.find((p) => p.id === paymentMethodId);

    if (
      travelWalletItemsToApply.offerToApply ||
      travelWalletItemsToApply.creditToApply
    ) {
      const travelWalletItems = [];

      if (travelWalletItemsToApply.offerToApply?.amount && offerAmountToApply) {
        travelWalletItems.push({
          title: textConstants.TRAVEL_OFFER_APPLIED,
          value: getTotalPriceText({
            price: {
              value: offerAmountToApply * -1,
              currencyCode:
                travelWalletItemsToApply.offerToApply?.amount.currency,
              currencySymbol: CurrencyFormatters.getSymbol(
                travelWalletItemsToApply.offerToApply?.amount.currency
              ),
            },
            priceFormatter: twoDecimalFormatter,
          }),
          isTravelOffer: !!travelWalletItemsToApply.offerToApply,
          icon: "offer-tag",
        });
      }
      if (travelWalletItemsToApply.creditToApply && creditAmountToApply) {
        const breakdownHasStatementCredit =
          !!travelWalletItemsToApply.creditToApply.breakdown?.some(
            (detail) =>
              detail.CreditDetail === "Statement" &&
              Math.abs(detail.usableAmount.amount) > 0
          );
        travelWalletItems.push({
          title: breakdownHasStatementCredit
            ? textConstants.TOTAL_TRAVEL_CREDITS_APPLIED
            : textConstants.TRAVEL_CREDITS_APPLIED,
          value: getTotalPriceText({
            price: {
              value: creditAmountToApply * -1,
              currencyCode:
                travelWalletItemsToApply.creditToApply?.amount.currency,
              currencySymbol: CurrencyFormatters.getSymbol(
                travelWalletItemsToApply.creditToApply?.amount.currency
              ),
            },
            priceFormatter: twoDecimalFormatter,
          }),
          isTravelCredit: !!travelWalletItemsToApply.creditToApply,
          icon: "piggy-bank-icon",
          travelCreditBreakdown: breakdownHasStatementCredit
            ? getCheckoutCreditBreakdown(
                travelWalletItemsToApply.creditToApply.breakdown || [],
                creditAmountToApply,
                travelWalletItemsToApply.creditToApply.amount.currency
              )
            : [],
        });
      }

      return [
        ...travelWalletItems,
        ...rewardsLineItems,
        {
          title: paymentMethod
            ? `Ending in ${paymentMethod.last4}:`
            : textConstants.AMOUNT_DUE,
          value: totalAmountDueOnCredit,
          icon: IconName.Payment,
        },
      ];
    }

    return [
      ...rewardsLineItems,
      {
        title: paymentMethod
          ? `Ending in ${paymentMethod.last4}:`
          : textConstants.AMOUNT_DUE,
        value: totalAmountDueOnCredit,
        icon: IconName.Payment,
      },
    ];
  }
);

export enum Progress {
  NOT_STARTED = 0,
  IN_PROGRESS = 1,
  COMPLETED = 2,
}

export const getCombinedBookingSteps = createSelector(
  getConfirmationEmail,
  getConfirmationPhoneNumber,
  getUserSelectedTravelerId,
  getSelectedPaymentMethodId,
  getRewardsPaymentAccountReferenceId,
  getIsCreditCardPaymentRequired,
  getAllowRewardsWithPolicy,
  getUserSelectedTravelerIds,
  (
    email,
    phoneNumber,
    traveler,
    selectedPayment,
    selectedRewardsAccount,
    creditCardRequired,
    canRedeemRewards,
    userSelectedTravelerIds
  ) => {
    const paymentCompleted = () => {
      const unsettledPayment = !selectedPayment && creditCardRequired;

      if (selectedRewardsAccount && unsettledPayment) {
        return Progress.IN_PROGRESS;
      } else if (!selectedRewardsAccount && !selectedPayment) {
        return Progress.NOT_STARTED;
      } else {
        return Progress.COMPLETED;
      }
    };
    return [
      {
        name: textConstants.TRAVELER_INFORMATION,
        status:
          (!!traveler || userSelectedTravelerIds.length > 0) &&
          email &&
          phoneNumber &&
          emailRegex.test(email) &&
          phoneRegex.test(phoneNumber)
            ? Progress.COMPLETED
            : Progress.IN_PROGRESS,
      },
      {
        name: canRedeemRewards
          ? textConstants.REWARDS_AND_PAYMENT
          : textConstants.REWARDS_AND_PAYMENT_SHORTENED,
        status: paymentCompleted(),
      },
    ];
  }
);

export const getBookingProgress = createSelector(
  getConfirmationEmail,
  getConfirmationPhoneNumber,
  getUserSelectedTravelerId,
  getSelectedPaymentMethodId,
  getRewardsPaymentAccountReferenceId,
  getIsCreditCardPaymentRequired,
  getAllowRewardsWithPolicy,
  getUserSelectedTravelerIds,
  (
    email,
    phoneNumber,
    traveler,
    selectedPayment,
    selectedRewardsAccount,
    creditCardRequired,
    canRedeemRewards,
    userSelectedTravelerIds
  ) => {
    const paymentCompleted = () => {
      const unsettledPayment = !selectedPayment && creditCardRequired;

      if (selectedRewardsAccount && unsettledPayment) {
        return Progress.IN_PROGRESS;
      } else if (!selectedRewardsAccount && !selectedPayment) {
        return Progress.NOT_STARTED;
      } else {
        return Progress.COMPLETED;
      }
    };
    return [
      {
        name: textConstants.ADD_TRAVELERS,
        status:
          !!traveler || userSelectedTravelerIds.length > 0
            ? Progress.COMPLETED
            : Progress.IN_PROGRESS,
      },
      {
        name: textConstants.CONTACT,
        status:
          email &&
          phoneNumber &&
          emailRegex.test(email) &&
          phoneRegex.test(phoneNumber)
            ? Progress.COMPLETED
            : Progress.NOT_STARTED,
      },
      {
        name: canRedeemRewards
          ? textConstants.REWARDS_AND_PAYMENT
          : textConstants.REWARDS_AND_PAYMENT_SHORTENED,
        status: paymentCompleted(),
      },
    ];
  }
);

export const getFreezeBookingProgress = createSelector(
  getConfirmationEmail,
  // TODO BF-1981 Modify the payment step progress indicators here if necessary
  //getSelectedPaymentMethodId,
  //getRewardsPaymentAccountReferenceId,
  //getIsCreditCardPaymentRequired,
  (
    email
    //selectedPayment, selectedRewardsAccount, creditCardRequired
  ) => {
    const paymentCompleted = () => {
      //const unsettledPayment = !selectedPayment && creditCardRequired;

      //if (selectedRewardsAccount && unsettledPayment) {
      //  return Progress.IN_PROGRESS;
      //} else if (!selectedRewardsAccount && !selectedPayment) {
      //  return Progress.NOT_STARTED;
      //} else {
      //  return Progress.COMPLETED;
      //}

      return Progress.NOT_STARTED;
    };

    return [
      {
        name: textConstants.FreezeCheckoutFlowSteps.REVIEW_HOTEL_INFORMATION,
        status: Progress.COMPLETED,
      },
      {
        name: textConstants.FreezeCheckoutFlowSteps.CONTACT_INFORMATION,
        status:
          email && emailRegex.test(email)
            ? Progress.COMPLETED
            : Progress.NOT_STARTED,
      },
      {
        name: textConstants.FreezeCheckoutFlowSteps.REWARDS_AND_PAYMENT,
        status: paymentCompleted(),
      },
    ];
  }
);

export const isPriceFreezePurchaseTravelerStepCompleteSelector = createSelector(
  getFreezeBookingProgress,
  (freezeBookingProgress): boolean => {
    return !!freezeBookingProgress.find(
      (progress) =>
        progress.name ===
          textConstants.FreezeCheckoutFlowSteps.CONTACT_INFORMATION &&
        progress.status === Progress.COMPLETED
    );
  }
);

export const getBookingInProgress = createSelector(
  getSchedulePriceQuoteCallState,
  getPollPriceQuoteCallState,
  getScheduleBookCallState,
  getConfirmationDetailsCallState,
  (scheduleQuote, pollPriceQuote, scheduleBook, confirmDetails) => {
    return (
      scheduleQuote === CallState.InProcess ||
      pollPriceQuote === CallState.InProcess ||
      scheduleBook === CallState.InProcess ||
      confirmDetails === CallState.InProcess
    );
  }
);

export const getSelectedRoomPolicyCompliance = createSelector(
  getHotelShopChosenRoomInfo,
  (roomInfoProduct) => {
    return roomInfoProduct && "corporateTravel" in roomInfoProduct.roomInfo
      ? roomInfoProduct.roomInfo?.corporateTravel.policyCompliance
      : undefined;
  }
);

export const getIsBookingValid = createSelector(
  getPriceDifference,
  getPriceDifferenceAcknowledged,
  getIsCreditCardPaymentRequired,
  getSelectedPaymentMethodId,
  getUserSelectedTravelerId,
  getBookingInProgress,
  getConfirmationEmail,
  getConfirmationPhoneNumber,
  getIsTravelWalletPaymentOnly,
  getPollPriceQuoteCallState,
  getPriceQuoteErrors,
  hotelBookTypeSelector,
  getUserSelectedTravelerIds,
  (
    priceDifference,
    priceDifferenceAcknowledged,
    creditCardRequired,
    selectedPaymentMethodId,
    userSelectedTravelerId,
    bookingInProgress,
    email,
    phone,
    isTravelWalletPaymentOnly,
    priceQuoteCallState,
    priceQuoteErrors,
    hotelBookType,
    userSelectedTravelerIds
  ) => {
    const paymentUnsettled =
      creditCardRequired &&
      !selectedPaymentMethodId &&
      !isTravelWalletPaymentOnly;

    const isContactInfoReady = (): boolean => {
      switch (hotelBookType) {
        // note: in the PF purchase flow, the user doesn't get to select any traveler or fill in the phone number
        case HotelBookType.PRICE_FREEZE_PURCHASE:
          return !!email;
        case HotelBookType.PRICE_FREEZE_EXERCISE:
        case HotelBookType.DEFAULT:
        default:
          return (
            (!!userSelectedTravelerId || userSelectedTravelerIds.length > 0) &&
            !!email &&
            !!phone
          );
      }
    };

    if (
      (priceDifference.hasDifference && !priceDifferenceAcknowledged) ||
      priceQuoteErrors.length > 0 ||
      priceQuoteCallState !== CallState.Success ||
      paymentUnsettled ||
      bookingInProgress ||
      !isContactInfoReady()
    ) {
      return false;
    } else {
      return true;
    }
  }
);

export const getEarn = (state: IStoreState) => state.hotelBook.productEarnValue;

const getPaymentRequestType = createSelector(
  getTotalCreditCardPaymentRequiredInFiatPrice,
  getRewardsPaymentInRewardsCurrency,
  getRewardsPaymentInFiatCurrency,
  getRewardsPaymentAccountReferenceId,
  getSelectedPaymentMethodId,
  (
    creditCardPayment,
    rewardsPayment,
    rewardsFiatPayment,
    rewardsAccountId,
    selectedPaymentMethodId
  ) => {
    if (selectedPaymentMethodId && !rewardsPayment) {
      return PaymentSplitRequestEnum.PaymentCardRequest;
    } else if (
      rewardsAccountId &&
      rewardsPayment &&
      rewardsFiatPayment &&
      creditCardPayment?.value === 0
    ) {
      return PaymentSplitRequestEnum.PaymentRewardsRequest;
    } else if (rewardsPayment && rewardsAccountId && rewardsFiatPayment) {
      return PaymentSplitRequestEnum.PaymentCardRewardsRequest;
    } else {
      return null;
    }
  }
);

export const getCardPaymentRewardsAccount = createSelector(
  getPaymentMethodRewardsAccountId,
  getRewardsAccounts,
  (accountId, rewardsAccounts) =>
    rewardsAccounts.find((account) => account.accountReferenceId === accountId)
);

export const getEarnedString = createSelector(
  getEarn,
  getCardPaymentRewardsAccount,
  getAllowRewardsWithPolicy,
  (earn, account, canRedeemRewards) => {
    if (!earn || !account || !canRedeemRewards) return "";
    return textConstants.EARNED_STRING({
      ...account.rewardsBalance,
      value: earn,
    });
  }
);

export const getPaymentRequest = createSelector(
  getPaymentMethodRewardsAccountId,
  getTotalCreditCardPaymentRequiredInFiatPrice,
  getRewardsPaymentInRewardsCurrency,
  getRewardsPaymentInFiatCurrency,
  getRewardsPaymentAccountReferenceId,
  getSelectedPaymentMethodId,
  getPaymentRequestType,
  getSession,
  (
    accountReferenceId,
    creditCardPayment,
    rewardsPayment,
    rewardsFiatPayment,
    rewardsAccountId,
    paymentId,
    paymentRequestType,
    session
  ): PaymentSplitRequest | null => {
    let amount: PaymentType | null = null;
    switch (paymentRequestType) {
      case PaymentSplitRequestEnum.PaymentCardRequest:
        const userCardPayment: UserCardPaymentType = {
          paymentId: paymentId || "",
          accountReferenceId,
          paymentAmount: {
            currency: creditCardPayment!.currencyCode,
            amount: roundToTwoDecimals(creditCardPayment!.value),
            PaymentAmount: PaymentAmountEnum.FiatAmount,
          },
          Payment: TypeOfPaymentEnum.UserCard,
        };
        amount = userCardPayment;
        break;
      case PaymentSplitRequestEnum.PaymentCardRewardsRequest:
        const splitPayment: SplitPaymentType = {
          paymentId: paymentId || "",
          accountReferenceId,
          paymentAmount: {
            fiatAmount: {
              currency: creditCardPayment!.currencyCode,
              amount: roundToTwoDecimals(creditCardPayment!.value),
              PaymentAmount: PaymentAmountEnum.FiatAmount,
            },
            rewardsAmount: {
              rewardsAccountId: rewardsAccountId!,
              fiatValue: {
                amount: roundToTwoDecimals(rewardsFiatPayment!.value),
                currency: rewardsFiatPayment!.currencyCode,
              },
              rewardsPrice: {
                value: roundToTwoDecimals(rewardsPayment!.value),
                currency: rewardsPayment!.currency,
              },
              PaymentAmount: PaymentAmountEnum.RewardsAmount,
            },
            PaymentAmount: PaymentAmountEnum.SplitAmount,
          },
          Payment: TypeOfPaymentEnum.Split,
        };
        amount = splitPayment;
        break;
      case PaymentSplitRequestEnum.PaymentRewardsRequest:
        const rewardsPaymentType: RewardsPaymentType = {
          paymentAmount: {
            rewardsAccountId: rewardsAccountId!,
            fiatValue: {
              amount: roundToTwoDecimals(rewardsFiatPayment!.value),
              currency: rewardsFiatPayment!.currencyCode,
            },
            rewardsPrice: {
              value: roundToTwoDecimals(rewardsPayment!.value),
              currency: rewardsPayment!.currency,
            },
            PaymentAmount: PaymentAmountEnum.RewardsAmount,
          },
          Payment: TypeOfPaymentEnum.Rewards,
        };
        amount = rewardsPaymentType;
        break;
      default:
        return null;
    }

    if (amount && session) {
      const request = {
        token: session,
        payment: amount,
        ancillaries: [],
      };
      return request;
    } else {
      return null;
    }
  }
);

export const getOpaquePayments = createSelector(
  getPaymentMethodRewardsAccountId,
  getTotalCreditCardPaymentRequiredInFiatPrice,
  getRewardsPaymentInRewardsCurrency,
  getRewardsPaymentInFiatCurrency,
  getRewardsPaymentAccountReferenceId,
  getSelectedPaymentMethodId,
  getPaymentRequestType,
  getTravelWalletItemsToApply,
  getTravelWalletOfferToApplyAmount,
  getTravelWalletCreditToApplyAmount,
  hotelPriceFreezeExercisePropsSelector,
  hotelBookTypeSelector,
  (
    accountReferenceId,
    creditCardPayment,
    rewardsPayment,
    rewardsFiatPayment,
    rewardsAccountId,
    paymentId,
    paymentRequestType,
    travelWalletItemsToApply,
    offerToApplyAmount,
    creditToApplyAmount,
    hotelPriceFreezeExerciseProps,
    hotelBookType
  ): PaymentOpaqueValue[] | null => {
    let amount: PaymentOpaqueValue[] = [];
    if (
      travelWalletItemsToApply.offerToApply ||
      travelWalletItemsToApply.creditToApply
    ) {
      if (travelWalletItemsToApply.offerToApply) {
        const offer: PaymentOpaqueValue = {
          type: Payment.TravelWalletOffer,
          value: {
            offerId: travelWalletItemsToApply.offerToApply.id,
            description:
              travelWalletItemsToApply.offerToApply?.descriptions[0] || "",
            paymentAmount: {
              fiatValue: {
                amount: offerToApplyAmount,
                currency: travelWalletItemsToApply.offerToApply.amount.currency,
              },
            },
            PaymentV2: PaymentV2Enum.TravelWalletOffer,
          },
        };
        amount.push(offer);
      }
      if (travelWalletItemsToApply.creditToApply && creditToApplyAmount > 0) {
        const credit: PaymentOpaqueValue = {
          type: Payment.TravelWalletCredit,
          value: {
            offerId: travelWalletItemsToApply.creditToApply.id,
            description: "TravelWalletCredit", // creditToApply doesn't have a description
            paymentAmount: {
              fiatValue: {
                amount: roundToTwoDecimals(creditToApplyAmount),
                currency:
                  travelWalletItemsToApply.creditToApply.amount.currency,
              },
            },
            PaymentV2: PaymentV2Enum.TravelWalletCredit,
          },
        };
        amount.push(credit);
      }
    }
    switch (paymentRequestType) {
      case PaymentSplitRequestEnum.PaymentCardRequest:
        const userCardPayment: PaymentOpaqueValue = {
          type: Payment.Card,
          value: {
            paymentId: paymentId || "",
            accountReferenceId,
            paymentAmount: {
              currency: creditCardPayment!.currencyCode,
              amount: roundToTwoDecimals(creditCardPayment!.value),
            },
            PaymentV2: PaymentV2Enum.UserCard,
          },
        };
        amount.push(userCardPayment);
        break;
      case PaymentSplitRequestEnum.PaymentCardRewardsRequest:
        const splitUserCardPayment: PaymentOpaqueValue = {
          type: Payment.Card,
          value: {
            paymentId: paymentId || "",
            accountReferenceId,
            paymentAmount: {
              currency: creditCardPayment!.currencyCode,
              amount: roundToTwoDecimals(creditCardPayment!.value),
            },
            PaymentV2: PaymentV2Enum.UserCard,
          },
        };

        const splitRewardsPayment: PaymentOpaqueValue = {
          type: Payment.Rewards,
          value: {
            paymentAmount: {
              rewardsAccountId: rewardsAccountId!,
              fiatValue: {
                amount: roundToTwoDecimals(rewardsFiatPayment!.value),
                currency: rewardsFiatPayment!.currencyCode,
              },
              rewardsPrice: {
                value: roundToTwoDecimals(rewardsPayment!.value),
                currency: rewardsPayment!.currency,
              },
            },
            PaymentV2: PaymentV2Enum.Rewards,
          },
        };

        amount.push(splitUserCardPayment, splitRewardsPayment);
        break;
      case PaymentSplitRequestEnum.PaymentRewardsRequest:
        const rewardsPaymentType: PaymentOpaqueValue = {
          type: Payment.Rewards,
          value: {
            paymentAmount: {
              rewardsAccountId: rewardsAccountId!,
              fiatValue: {
                amount: roundToTwoDecimals(rewardsFiatPayment!.value),
                currency: rewardsFiatPayment!.currencyCode,
              },
              rewardsPrice: {
                value: roundToTwoDecimals(rewardsPayment!.value),
                currency: rewardsPayment!.currency,
              },
            },
            PaymentV2: PaymentV2Enum.Rewards,
          },
        };
        amount.push(rewardsPaymentType);
        break;
      default:
        break;
    }
    if (
      hotelBookType === HotelBookType.PRICE_FREEZE_EXERCISE &&
      hotelPriceFreezeExerciseProps
    ) {
      const { depositOpaqueValue, discountOpaqueValue } =
        hotelPriceFreezeExerciseProps;

      if (depositOpaqueValue) {
        const deposit: PaymentOpaqueValue = {
          type: Payment.HotelPriceFreezeDeposit,
          value: {
            ...depositOpaqueValue,
            PaymentV2: PaymentV2Enum.HotelPriceFreezeDepositCredit,
          },
        };
        amount.push(deposit);
      }

      if (discountOpaqueValue) {
        const discount: PaymentOpaqueValue = {
          type: Payment.HotelPriceFreezeExerciseDiscount,
          value: {
            ...discountOpaqueValue,
            PaymentV2: PaymentV2Enum.HotelPriceFreezeDiscountCredit,
          },
        };
        amount.push(discount);
      }
    }

    if (amount) {
      return amount;
    } else {
      return null;
    }
  }
);

export const getCardPaymentAccount = createSelector(
  getRewardsAccounts,
  getPaymentRequest,
  (rewardsAccounts, paymentRequest: PaymentSplitRequest | null) => {
    switch (paymentRequest?.payment.Payment) {
      case TypeOfPaymentEnum.UserCard:
        return rewardsAccounts.find(
          (acc) =>
            acc.accountReferenceId ===
            (paymentRequest?.payment as UserCardPaymentType).accountReferenceId
        );
      case TypeOfPaymentEnum.Split:
        return rewardsAccounts.find(
          (acc) =>
            acc.accountReferenceId ===
            (paymentRequest?.payment as SplitPaymentType).accountReferenceId
        );
      default:
        return null;
    }
  }
);

export enum CheckoutSteps {
  ADD_TRAVELERS = 0,
  CONTACT = 1,
  REWARDS_AND_PAYMENT = 2,
}

export const isTravelerStepComplete = createSelector(
  getBookingProgress,
  (checkoutProgress) => {
    return (
      checkoutProgress[CheckoutSteps.ADD_TRAVELERS].status ===
      Progress.COMPLETED
    );
  }
);

export const isContactStepComplete = createSelector(
  getBookingProgress,
  (checkoutProgress) => {
    return (
      checkoutProgress[CheckoutSteps.CONTACT].status === Progress.COMPLETED
    );
  }
);

export const areAllStepsCompletedInCheckout = createSelector(
  getBookingProgress,
  getIsTravelWalletPaymentOnly,
  (checkoutProgress, isTravelWalletPaymentOnly) => {
    return (
      checkoutProgress[CheckoutSteps.ADD_TRAVELERS].status ===
        Progress.COMPLETED &&
      checkoutProgress[CheckoutSteps.CONTACT].status === Progress.COMPLETED &&
      (!isTravelWalletPaymentOnly
        ? checkoutProgress[CheckoutSteps.REWARDS_AND_PAYMENT].status ===
          Progress.COMPLETED
        : true)
    );
  }
);

export const verifyPaymentMethodResultSelector = (state: IStoreState) =>
  state.hotelBook.verifyPaymentMethodResult;

export const getModalType = createSelector(
  getPriceQuoteErrors,
  getScheduleBookError,
  getSchedulePriceQuoteError,
  getConfirmationDetailsError,
  getPriceDifference,
  verifyPaymentMethodResultSelector,
  hotelPriceFreezeExercisePropsSelector,
  hotelBookTypeSelector,
  hasAddOnBeenAttachedSelector,
  (
    priceQuoteErrors,
    scheduleBookError,
    schedulePriceQuoteError,
    confirmationDetailsErrors,
    priceDifference,
    verifyPaymentMethodResult,
    hotelPriceFreezeExerciseProps,
    hotelBookType,
    hasAddOnBeenAttached
  ): string => {
    if (priceDifference.hasDifference && priceDifference.amount) {
      if (priceDifference.isIncrease && hasAddOnBeenAttached) {
        return "hotel_with_add_on_price_difference";
      }
      return "hotel_price_difference";
    }

    if (
      verifyPaymentMethodResult &&
      verifyPaymentMethodResult !== PaymentVerifyResultEnum.Success
    ) {
      return "payment_verification_failed";
    }

    if (confirmationDetailsErrors.length > 0) {
      return "confirmation_details_failed";
    }

    if (scheduleBookError) {
      return "schedule_book_failed";
    }

    if (schedulePriceQuoteError) {
      return "schedule_price_quote_failed";
    }

    if (hotelPriceFreezeExerciseProps?.getDetailsProps.isLodgingNotAvailable) {
      return "hotel_price_freeze_unavailable";
    }

    if (priceQuoteErrors.length > 0) {
      const error = priceQuoteErrors[0];
      const errorType = error?.Error;
      const errorCode =
        errorType === PaymentErrorEnum.ErrorCode
          ? (error as ErrorCode)?.code
          : errorType === PurchaseErrorEnum.ProductError
          ? ((error as ProductError)?.value?.value as string)
          : "";

      if (
        (errorCode === HotelBookErrorType.LackOfInventory ||
          errorCode === HotelBookErrorType.RateNotAvailable) &&
        hotelBookType === HotelBookType.PRICE_FREEZE_EXERCISE
      ) {
        return "hotel_price_freeze_unavailable";
      }

      return "poll_price_quote_failed";
    }

    return "";
  }
);

export const getErrorTitles = createSelector(
  getHasError,
  getPriceQuoteErrors,
  getScheduleBookError,
  getSchedulePriceQuoteError,
  getConfirmationDetailsError,
  getPriceDifference,
  verifyPaymentMethodResultSelector,
  getTravelWalletItemsToApply,
  getModalType,
  hotelPriceFreezeExercisePropsSelector,
  hotelBookTypeSelector,
  hasAddOnBeenAttachedSelector,
  (
    hasError,
    priceQuoteErrors,
    scheduleBookError,
    schedulePriceQuoteError,
    confirmationDetailsErrors,
    priceDifference,
    verifyPaymentMethodResult,
    travelWalletItemsToApply,
    type,
    priceFreezeExerciseProps,
    hotelBookType,
    hasAddOnBeenAttached
  ): ErrorTitles => {
    if (!hasError) return { title: "", subtitle: "", primaryButtonText: "" };

    let properties: ModalAlertProperties = {
      lob: "hotel",
      type,
      category: ModalCategoryType.TROUBLE,
      screen:
        hotelBookType === HotelBookType.PRICE_FREEZE_PURCHASE
          ? ModalScreens.HOTEL_PRICE_FREEZE_PURCHASE
          : ModalScreens.HOTELS_CHECKOUT,
      primary_button: "",
      secondary_button: "",
      modal_subtitle: "",
      modal_title: "",
      agent_title: "",
      agent_subtitle: "",
      step: "save_travelers",
      funnel: "hotels",
      price_freeze_flow: hotelBookType === HotelBookType.PRICE_FREEZE_EXERCISE,
    };

    const callTrackEvent = (props: ModalAlertProperties) => {
      trackEvent({
        eventName: MODAL_ALERT,
        properties: props,
      });
    };

    if (priceDifference.hasDifference && priceDifference.amount) {
      const primaryButtonText = textConstants.CONTINUE;

      const offerAmount =
        travelWalletItemsToApply.offerToApply?.amount.amount || 0;
      const creditsAmount =
        travelWalletItemsToApply.creditToApply?.amount.amount || 0;

      const walletItemCoverageChanged =
        (travelWalletItemsToApply.offerToApply ||
          travelWalletItemsToApply.creditToApply) &&
        Math.abs(offerAmount + creditsAmount) >=
          priceDifference.predictedTotal &&
        Math.abs(offerAmount + creditsAmount) < priceDifference.priceQuoteTotal;

      if (walletItemCoverageChanged) {
        properties.type = "hotel_price_difference";
        properties.primary_button = primaryButtonText;
        properties.secondary_button =
          hotelBookType === HotelBookType.DEFAULT
            ? textConstants.CHOOSE_ANOTHER_HOTEL
            : "";
        properties.modal_subtitle =
          textConstants.PRICE_INCREASE_WALLET_COVERAGE_SUBTITLE;
        properties.modal_title = travelWalletItemsToApply.creditToApply
          ? textConstants.PRICE_INCREASE_CREDITS_COVERAGE_TITLE
          : textConstants.PRICE_INCREASE_OFFER_COVERAGE_TITLE;
        properties.agent_subtitle = properties.modal_subtitle;
        properties.agent_title = properties.modal_title;
        properties.step = "save_travelers";
        callTrackEvent(properties);
        return {
          title: travelWalletItemsToApply.creditToApply
            ? textConstants.PRICE_INCREASE_CREDITS_COVERAGE_TITLE
            : textConstants.PRICE_INCREASE_OFFER_COVERAGE_TITLE,
          subtitle: textConstants.PRICE_INCREASE_WALLET_COVERAGE_SUBTITLE,
          primaryButtonText: primaryButtonText,
          secondaryButtonText:
            hotelBookType === HotelBookType.DEFAULT
              ? textConstants.CHOOSE_ANOTHER_HOTEL
              : undefined,
          icon: priceDifference.isIncrease
            ? textConstants.PRICE_INCREASE_ICON
            : textConstants.PRICE_DECREASE_ICON,
        };
      }

      if (priceDifference.isIncrease && hasAddOnBeenAttached) {
        properties.type = "hotel_with_add_on_price_difference";
        properties.primary_button = primaryButtonText;
        properties.secondary_button =
          hotelBookType === HotelBookType.DEFAULT
            ? textConstants.CHOOSE_ANOTHER_HOTEL
            : "";
        properties.modal_subtitle =
          textConstants.PRICE_INCREASE_WITH_ADD_ON_SUBTITLE;
        properties.modal_title = textConstants.getPriceDifferenceTitle(
          priceDifference.isIncrease,
          priceDifference.amount
        );
        properties.agent_subtitle = properties.modal_subtitle;
        properties.agent_title = properties.modal_title;
        properties.step = "save_travelers";
        callTrackEvent(properties);
        return {
          type: ErrorModalType.HOTEL_WITH_ADD_ONE_PRICE_DIFFERENCE,
          title: textConstants.getPriceDifferenceTitle(
            priceDifference.isIncrease,
            priceDifference.amount
          ),
          subtitle: textConstants.PRICE_INCREASE_WITH_ADD_ON_SUBTITLE,
          primaryButtonText: primaryButtonText,
          secondaryButtonText: textConstants.CHANGE_HOTEL,
          icon: textConstants.PRICE_INCREASE_ICON,
          useHtml: true,
        };
      }

      properties.type = "hotel_price_difference";
      properties.primary_button = primaryButtonText;
      properties.secondary_button =
        hotelBookType === HotelBookType.DEFAULT
          ? textConstants.CHOOSE_ANOTHER_HOTEL
          : "";
      properties.modal_subtitle = priceDifference.isIncrease
        ? textConstants.PRICE_INCREASE_SUBTITLE
        : textConstants.PRICE_DECREASE_SUBTITLE;
      properties.modal_title = textConstants.getPriceDifferenceTitle(
        priceDifference.isIncrease,
        priceDifference.amount
      );
      properties.agent_subtitle = properties.modal_subtitle;
      properties.agent_title = properties.modal_title;
      properties.step = "save_travelers";
      callTrackEvent(properties);
      return {
        type: ErrorModalType.HOTEL_PRICE_DIFFERENCE,
        title: textConstants.getPriceDifferenceTitle(
          priceDifference.isIncrease,
          priceDifference.amount
        ),
        subtitle: priceDifference.isIncrease
          ? textConstants.PRICE_INCREASE_SUBTITLE
          : textConstants.PRICE_DECREASE_SUBTITLE,
        primaryButtonText: primaryButtonText,
        icon: priceDifference.isIncrease
          ? textConstants.PRICE_INCREASE_ICON
          : textConstants.PRICE_DECREASE_ICON,
      };
    }

    if (
      verifyPaymentMethodResult &&
      verifyPaymentMethodResult !== PaymentVerifyResultEnum.Success
    ) {
      const returnProps = textConstants.PAYMENT_METHOD_ERROR_TITLES(
        verifyPaymentMethodResult
      );
      properties.primary_button = returnProps.primaryButtonText || "";
      properties.secondary_button = returnProps.secondaryButtonText || "";
      properties.modal_subtitle = returnProps.subtitle || "";
      properties.modal_title = returnProps.title || "";
      properties.agent_subtitle =
        returnProps.agentSubtitle || properties.modal_subtitle;
      properties.agent_title = returnProps.agentTitle || properties.modal_title;
      properties.step = "select_payment";
      callTrackEvent(properties);
      return returnProps;
    }

    if (confirmationDetailsErrors.length > 0) {
      const returnProps = getErrorMessage({
        error: confirmationDetailsErrors[0],
        priceFreezeExerciseProps,
        hotelBookType,
      });
      properties.primary_button = returnProps.primaryButtonText!;
      properties.modal_subtitle = returnProps.subtitle!;
      properties.modal_title = returnProps.title!;
      properties.agent_subtitle =
        returnProps.agentSubtitle || properties.modal_subtitle;
      properties.agent_title = returnProps.agentTitle || properties.modal_title;
      properties.step = "confirm_and_book";
      callTrackEvent(properties);
      return returnProps;
    }

    if (scheduleBookError) {
      const returnProps = textConstants.GENERIC_ERROR_TITLES(hotelBookType);
      properties.primary_button = returnProps.primaryButtonText!;
      properties.modal_subtitle = returnProps.subtitle!;
      properties.modal_title = returnProps.title!;
      properties.agent_subtitle =
        returnProps.agentSubtitle || properties.modal_subtitle;
      properties.agent_title = returnProps.agentTitle || properties.modal_title;
      properties.step = "confirm_and_book";
      callTrackEvent(properties);
      return returnProps;
    }

    if (schedulePriceQuoteError) {
      const returnProps = textConstants.GENERIC_ERROR_TITLES(hotelBookType);
      properties.primary_button = returnProps.primaryButtonText!;
      properties.modal_subtitle = returnProps.subtitle!;
      properties.modal_title = returnProps.title!;
      properties.agent_subtitle =
        returnProps.agentSubtitle || properties.modal_subtitle;
      properties.agent_title = returnProps.agentTitle || properties.modal_title;
      properties.step = "save_travelers";
      callTrackEvent(properties);
      return returnProps;
    }

    if (priceQuoteErrors.length > 0) {
      const returnProps = getErrorMessage({
        error: priceQuoteErrors[0],
        priceFreezeExerciseProps,
        hotelBookType,
      });
      properties.primary_button = returnProps.primaryButtonText!;
      properties.modal_subtitle = returnProps.subtitle!;
      properties.modal_title = returnProps.title!;
      properties.agent_subtitle =
        returnProps.agentSubtitle || properties.modal_subtitle;
      properties.agent_title = returnProps.agentTitle || properties.modal_title;
      properties.step = "save_travelers";
      callTrackEvent(properties);
      return returnProps;
    }

    if (hotelBookType === HotelBookType.PRICE_FREEZE_EXERCISE) {
      const { getDetailsProps, refundAmount } = priceFreezeExerciseProps ?? {};
      const { isLodgingNotAvailable } = getDetailsProps ?? {};

      if (isLodgingNotAvailable && refundAmount) {
        const returnProps =
          textConstants.REFUND_HOTEL_PRICE_FREEZE_TITLES(refundAmount);
        properties.primary_button = returnProps.primaryButtonText!;
        properties.modal_subtitle = returnProps.subtitle!;
        properties.modal_title = returnProps.title!;
        properties.agent_subtitle =
          returnProps.agentSubtitle || properties.modal_subtitle;
        properties.agent_title =
          returnProps.agentTitle || properties.modal_title;
        properties.step = "save_travelers";
        callTrackEvent(properties);
        return returnProps;
      }
    }

    const returnProps = textConstants.GENERIC_ERROR_TITLES(hotelBookType);
    properties.primary_button = returnProps.primaryButtonText!;
    properties.modal_subtitle = returnProps.subtitle!;
    properties.modal_title = returnProps.title!;
    properties.agent_subtitle =
      returnProps.agentSubtitle || properties.modal_subtitle;
    properties.agent_title = returnProps.agentTitle || properties.modal_title;
    properties.step = "save_travelers";
    callTrackEvent(properties);
    return returnProps;
  }
);

export const getHotelBookViewedHotelDetailsProperties = createSelector(
  getViewedHotelDetailsProperties,
  hotelBookTypeSelector,
  hotelAvailabilityTrackingSelector,
  (
    properties,
    hotelBookType,
    availabilityTracking
  ): ITrackingProperties<ViewedHotelDetailsProperties> => {
    switch (hotelBookType) {
      case HotelBookType.PRICE_FREEZE_EXERCISE:
        return {
          properties: {
            ...properties.properties,
            ...availabilityTracking?.properties,
          },
          encryptedProperties: [
            ...properties.encryptedProperties,
            availabilityTracking?.encryptedProperties ?? "",
          ],
        };
      case HotelBookType.PRICE_FREEZE_PURCHASE:
      case HotelBookType.DEFAULT:
      default:
        return {
          properties: {
            ...properties.properties,
          },
          encryptedProperties: [...properties.encryptedProperties],
        };
    }
  }
);

export const getReviewHotelDetailsCheckoutProperties = createSelector(
  getHotelBookViewedHotelDetailsProperties,
  getHotelBookSelectedAvailability,
  getHotelBookChosenProduct,
  getFromDateInBook,
  getUntilDateInBook,
  getNightCountInBook,
  getPurchasePriceQuotePricing,
  getSelectedRoomPolicyCompliance,
  getPaymentMethods,
  (
    viewedHotelDetailsProperties,
    hotelAvail,
    product,
    fromDate,
    toDate,
    nights,
    purchasePriceQuotePricing,
    roomInfoPolicyCompliance,
    paymentMethods
  ): ITrackingProperties<ReviewDetailsHotelCheckoutProperties> => {
    const productTaxes = product
      ? Object.keys(product.taxBreakdown).reduce(
          (sum, key) => product.taxBreakdown[key].fiat.value + sum,
          0
        )
      : 0;
    const { hotelPricing } = purchasePriceQuotePricing ?? {};
    return {
      properties: {
        lodging_name: hotelAvail?.lodging.name || "",
        chain: "",
        brand: "",
        length_of_stay: nights || 0,
        sell_price_excluding_taxes_and_fees_usd:
          hotelPricing?.subtotal.fiat.value ||
          product?.tripTotal.fiat.value ||
          0,
        owed_now_excluding_taxes_and_fees:
          hotelPricing?.subtotal.fiat.value ||
          product?.tripTotal.fiat.value ||
          0,
        owed_now_taxes_and_fees:
          hotelPricing?.taxes.fiat.value || productTaxes || 0,
        owed_at_checkin_taxes_and_fees:
          hotelPricing?.taxes?.fiat.value || productTaxes || 0,
        pay_later_excluding_taxes_and_fees:
          hotelPricing?.taxes?.fiat.value || productTaxes || 0,
        pay_later_taxes_and_fees:
          hotelPricing?.taxes?.fiat.value || productTaxes || 0,
        pay_later_fees:
          hotelPricing?.feeBreakdown.total?.value ||
          product?.feeBreakdown.total?.value ||
          0,
        total_discount: 0,
        refundability:
          product?.cancellationPolicy.CancellationPolicy ===
          CancellationPolicyEnum.NonRefundable
            ? "non_refundable"
            : "free_cancellation",
        supply_revenue_usd:
          hotelPricing?.subtotal.fiat.value ||
          product?.tripTotal.fiat.value ||
          0,
        check_in_date: fromDate ? dayjs(fromDate).format("MM/DD/YYYY") : "",
        check_out_date: toDate ? dayjs(toDate).format("MM/DD/YYYY") : "",
        supplier: "",
        provider_type: "direct", // // TODO find out where this comes from -> "direct" | "retail" | "wholesale",
        adr: "",
        ...(isCorpTenant(config.TENANT) && {
          in_policy: roomInfoPolicyCompliance?.isInPolicy ?? true,
          hotel_loyalty_eligible: Boolean(
            (product as CorpRoomProduct)?.loyaltyProgramCode
          ),
        }),
        ...viewedHotelDetailsProperties.properties,
        ...hotelAvail?.trackingPropertiesV2?.properties,
        is_preferred_cot: !!hotelAvail?.isPreferred,
        card_on_file: paymentMethods.length > 0,
      },
      encryptedProperties: [
        ...viewedHotelDetailsProperties.encryptedProperties,
        hotelAvail?.trackingPropertiesV2?.encryptedProperties ?? "",
      ],
    };
  }
);

export const getSelectedPaymentCardType = createSelector(
  getRewardsAccounts,
  getPaymentMethods,
  getSelectedPaymentMethodId,
  (accounts, paymentMethods, id): string => {
    const paymentMethod = paymentMethods.find((p) => p.id === id);

    if (!paymentMethod) {
      return "";
    }

    const matchingRewardsAccount = accounts.find(
      (a) =>
        a.lastFour === paymentMethod?.last4 ||
        a.lastFourVirtualCardNumbers?.includes(paymentMethod?.last4 || "")
    );

    if (matchingRewardsAccount && matchingRewardsAccount.productDisplayName) {
      return matchingRewardsAccount.accountDisplayName;
    }

    // TODO: Remove this special logic to handle test credit cards once we have a proper test credit card
    // that shows the same last four digits on both rewards side and payment methods side.
    // The current logic is bad practice as we cannot properly test usage of credit card related info in staging.
    const isTestCard =
      window.__mclean_env__.ENV !== "production" &&
      paymentMethod.last4 &&
      TEST_CARD_LAST_FOURS.includes(paymentMethod.last4);

    if (isTestCard) {
      return accounts[0]?.productDisplayName ?? "";
    }

    return "";
  }
);

export const getHotelCfarDetailsAtPriceQuoteSelector = createSelector(
  getHotelCfarOfferChoicePropertiesSelector,
  hotelCfarQuoteFromChosenRoomProductSelector,
  additionalInfoFromChosenRoomProductSelector,
  (
    getHotelCfarOfferChoiceProperties,
    hotelCfarQuote,
    additionalInfo
  ): Cap1HotelCfarRRProperties => {
    const { cfar_choice, cfar_rr_hotel_choice } =
      getHotelCfarOfferChoiceProperties("book");
    let cfar_premium_usd: number | undefined = undefined;
    if (!!hotelCfarQuote && cfar_choice == 1) {
      cfar_premium_usd = hotelCfarQuote.premiumPrices.fiat.value;
    }
    const cfar_fee_is_refundable = additionalInfo?.shouldRefundCfarPremium;
    return {
      cfar_choice,
      cfar_rr_hotel_choice,
      cfar_premium_usd,
      cfar_fee_is_refundable,
    };
  }
);

export const getPriceQuoteHotelProperties = createSelector(
  getReviewHotelDetailsCheckoutProperties,
  getSession,
  getSelectedLodgingIndexInBook,
  getTravelWalletItemsToApply,
  hotelBookTypeSelector,
  getPriceQuoteHotelPriceFreezeExercisePropertiesSelector,
  getHotelCfarDetailsAtPriceQuoteSelector,
  (
    reviewHotelDetailsProperties,
    session,
    selectedLodgingIndex,
    travelWalletItemsToApply,
    hotelBookType,
    getPriceQuoteHotelPriceFreezeExerciseProperties,
    hotelCfarDetails
  ): ITrackingProperties<PriceQuoteHotelProperties> => {
    return {
      properties: {
        ...reviewHotelDetailsProperties.properties,
        ...getPriceQuoteHotelPriceFreezeExerciseProperties(
          hotelBookType === HotelBookType.PRICE_FREEZE_EXERCISE
        ),
        success: false,
        booking_session_id: session?.value || "",
        lodging_row_index:
          selectedLodgingIndex != null ? selectedLodgingIndex : undefined,
        ...travelWalletItemsToApply.offerToApply?.trackingPropertiesV2
          ?.properties,
        ...travelWalletItemsToApply.creditToApply?.trackingPropertiesV2
          ?.properties,
        ...hotelCfarDetails,
      },
      encryptedProperties: [
        ...reviewHotelDetailsProperties.encryptedProperties,
        travelWalletItemsToApply.offerToApply?.trackingPropertiesV2
          ?.encryptedProperties ?? "",
        travelWalletItemsToApply.creditToApply?.trackingPropertiesV2
          ?.encryptedProperties ?? "",
      ],
    };
  }
);

export const getIsPaymentMethodVCN = createSelector(
  getPaymentMethodRewardsAccountId,
  getPaymentMethod,
  getRewardsAccounts,
  (paymentRewardsAccountId, paymentMethod, rewardsAccounts) => {
    if (!paymentRewardsAccountId || !paymentMethod || !rewardsAccounts)
      return false;

    const account = rewardsAccounts.find(
      (account) => account.accountReferenceId === paymentRewardsAccountId
    );

    return !!(
      account?.lastFourVirtualCardNumbers &&
      paymentMethod?.last4 &&
      account?.lastFourVirtualCardNumbers?.includes(paymentMethod?.last4)
    );
  }
);

export const getIsLodgingARecentlyViewed = createSelector(
  getHotelBookSelectedAvailability,
  (selectedLodging) => {
    return !!selectedLodging?.lastViewed;
  }
);

export const getCompleteBuyHotelRewardsProperties = createSelector(
  getRewardsPaymentInFiatCurrency,
  getRewardsPaymentInRewardsCurrency,
  getRewardsPaymentAccount,
  getSelectedPaymentCardType,
  (
    rewardPayment,
    rewardCurrencyPayment,
    account,
    cardType
  ): Pick<
    CompleteBuyHotelProperties,
    | "total_rewards_amount_usd"
    | "card_product_used"
    | "rewards_product_used"
    | "rewards_currency"
  > => {
    return {
      total_rewards_amount_usd: rewardPayment?.value || 0,
      card_product_used: cardType,
      rewards_product_used: account?.productDisplayName || "",
      rewards_currency: rewardCurrencyPayment?.currency || "",
    };
  }
);

export const getCompleteBuyHotelCfarOfferProperties = createSelector(
  getHotelBookChosenProduct,
  getHotelCfarQuoteInCheckout,
  additionalInfoFromChosenRoomProductSelector,
  getHotelCfarOfferChoicePropertiesSelector,
  getHasAddedCfarFromModalSelector,
  (
    roomProduct,
    hotelCfarQuote,
    additionalInfo,
    getHotelCfarOfferChoiceProperties,
    hasAddedCfarFromModal
  ): PartialCompleteBuyHotelHotelCfarOfferFacts => {
    return getCompleteBuyHotelHotelCfarOfferFacts({
      roomProduct,
      hotelCfarQuote,
      additionalInfo,
      ...getHotelCfarOfferChoiceProperties("book"),
      hasAddedCfarFromModal,
    });
  }
);

export const getCompleteBuyHotelProperties = createSelector(
  getReviewHotelDetailsCheckoutProperties,
  getCompleteBuyHotelRewardsProperties,
  getCompleteBuyHotelCfarOfferProperties,
  getTotalCreditCardPaymentRequiredInFiatPrice,
  getConfirmationDetails,
  getSession,
  getSelectedLodgingIndexInBook,
  getOffers,
  getTravelWalletCreditToApplyAmount,
  getTravelWalletOfferToApplyAmount,
  getTravelWalletItemsToApply,
  getHotelShopEntryPoint,
  getIsPaymentMethodVCN,
  getIsLodgingARecentlyViewed,
  hotelBookTypeSelector,
  getCompleteBuyHotelPriceFreezeExercisePropertiesSelector,
  getSelectedRoomPolicyCompliance,
  getHotelShopRecommendedBasedOnPreferences,
  getHotelShopSelectedLodging,
  (
    reviewProperties,
    rewardsProperties,
    cfarOfferProperties,
    cardPayment,
    reservation,
    session,
    selectedLodgingIndex,
    offers,
    creditToApplyAmount,
    offerToApplyAmount,
    travelWalletItemsToApply,
    hotelShopEntryPoints,
    isPaymentMethodVCN,
    isRecentlyViewed,
    hotelBookType,
    getCompleteBuyHotelPriceFreezeExerciseProperties,
    roomInfoPolicyCompliance,
    hotelShopRecommendedBasedOnPreferences,
    selectedLodging
  ): ITrackingProperties<CompleteBuyHotelProperties> => {
    const isInPolicy = roomInfoPolicyCompliance?.isInPolicy ?? true;

    const { lodgingSelection }: { lodgingSelection?: string } =
      queryStringParser.parse(location.search);

    let parsedLodgingSelection: Place | undefined;

    try {
      if (lodgingSelection) {
        parsedLodgingSelection = JSON.parse(lodgingSelection);
      }
    } catch (error) {
      console.error(error);
    }
    return {
      properties: {
        ...reviewProperties.properties,
        ...rewardsProperties,
        ...cfarOfferProperties,
        ...getCompleteBuyHotelPriceFreezeExerciseProperties(
          hotelBookType === HotelBookType.PRICE_FREEZE_EXERCISE
        ),
        success: false,
        total_card_amount_usd: cardPayment?.value || 0,
        agent_booking_fee_amount_usd: 0,
        booking_session_id: session?.value || "",
        reservation_id: reservation?.reservationId || "",
        lodging_row_index:
          selectedLodgingIndex != null ? selectedLodgingIndex : undefined,
        ...travelWalletItemsToApply.offerToApply?.trackingPropertiesV2
          ?.properties,
        ...travelWalletItemsToApply.creditToApply?.trackingPropertiesV2
          ?.properties,
        rooms_booked: reviewProperties.properties.rooms_searched,
        offer_amount_redeemed_usd: !!travelWalletItemsToApply.offerToApply
          ? Math.abs(offerToApplyAmount)
          : 0,
        offer_count: offers?.length,
        offer_used: !!travelWalletItemsToApply.offerToApply,
        credit_redeemed:
          !!travelWalletItemsToApply.creditToApply?.amount.amount,
        credit_amt_redeemed: !!travelWalletItemsToApply.creditToApply
          ? Math.abs(creditToApplyAmount)
          : 0,
        entry_type: hotelShopEntryPoints ? hotelShopEntryPoints : undefined,
        is_vcn: isPaymentMethodVCN,
        amenity:
          reservation?.lodgingData.amenities &&
          getIsFreeBreakfast(reservation?.lodgingData.amenities)
            ? "free_breakfast"
            : undefined,
        is_recent: isRecentlyViewed,
        ...(isCorpTenant(config.TENANT) && {
          in_policy: isInPolicy,
          ...(!isInPolicy && {
            policy_reason: roomInfoPolicyCompliance?.reasons.join(", "),
          }),
        }),
        preferences_recommended: hotelShopRecommendedBasedOnPreferences,
        searched_market: parsedLodgingSelection?.searchTerm,
        google_place_id: parsedLodgingSelection?.placeId,
        total_guests: (reservation?.guests ?? []).length,
        city_name: selectedLodging?.lodging.city,
        state: selectedLodging?.lodging.state,
      },
      encryptedProperties: [
        ...reviewProperties.encryptedProperties,
        travelWalletItemsToApply.offerToApply?.trackingPropertiesV2
          ?.encryptedProperties ?? "",
        travelWalletItemsToApply.creditToApply?.trackingPropertiesV2
          ?.encryptedProperties ?? "",
      ],
    };
  }
);

export const getPriceFreezePurchaseProperties = createSelector(
  getOpaquePayments,
  getCompleteBuyHotelRewardsProperties,
  priceFreezePurchaseEntrySelector,
  hotelBookTypeSelector,
  (
    payments,
    rewardsProperties,
    priceFreezePurchaseEntry,
    hotelBookType
  ): HotelPriceFreezePurchaseProperties | undefined => {
    if (hotelBookType === HotelBookType.PRICE_FREEZE_PURCHASE) {
      return getHotelPriceFreezePurchaseProperties({
        payments: payments as any,
        cardProduct: rewardsProperties.card_product_used,
        rewardsProduct: rewardsProperties.rewards_product_used,
        priceFreezeEntry: priceFreezePurchaseEntry ?? undefined,
      });
    }

    return undefined;
  }
);

export const rewardsAccountMinimumRequirementStateSelector = createSelector(
  getRewardsAccounts,
  (rewardsAccounts): RewardsAccountMinimumRequirementState => {
    return getRewardsAccountMinimumRequirementState(rewardsAccounts);
  }
);

export const getOffersRequest = createSelector(
  getHotelBookSelectedAvailability,
  getSearchedNightCountInBook,
  getFromDateInBook,
  getUntilDateInBook,
  getHotelShopChosenProduct,
  getPriceQuote,
  (
    selectedLodging,
    nightCount,
    fromDate,
    untilDate,
    chosenProduct,
    priceQuote
  ) => {
    if (
      selectedLodging &&
      nightCount &&
      fromDate &&
      untilDate &&
      chosenProduct
    ) {
      return {
        products: [
          {
            AvailableProduct: AvailableProductEnum.Lodging,
            id: selectedLodging?.lodging.id,
            merchant: MerchantOfRecord.Hopper,
            lengthOfStay: nightCount,
            stayPeriod: {
              from: dayjs(fromDate).format("YYYY-MM-DD"),
              until: dayjs(untilDate).format("YYYY-MM-DD"),
            },
            isPreferred: selectedLodging.isPreferred,
            isFreeCancel: selectedLodging.isFreeCancel,
            productPricing: {
              productClass: ProductClassEnum.Economy,
              currency:
                selectedLodging.price?.totalPrice.fiat.currencyCode ?? "USD",
              basePrice:
                chosenProduct.totalDiscountAware.priceWithNoDiscounts.fiat
                  .value,
              grossBookValue:
                chosenProduct.totalDiscountAware.priceWithUnmanagedDiscounts
                  .fiat.value,
              ancillaryTotal: 0, // hardcoded bc Tysons does this https://github.com/hopper-org/Tysons/blob/1167df0bdaa8ae2a5255ea0203438deacff071ae/server/src/main/scala/com/hopper/tysons/service/hotels/availability/converter/ImpFastAvailabilityResponseConverter.scala#L552
              preDiscountTotal:
                chosenProduct.totalDiscountAware.priceWithUnmanagedDiscounts
                  .fiat.value,
              preDiscountGrandTotal:
                priceQuote?.pricing.tripTotal.fiat.value ??
                chosenProduct.tripTotal.fiat.value,
              sellPrice:
                priceQuote?.pricing.tripTotal.fiat.value ??
                chosenProduct.tripTotal.fiat.value,
            },
          },
        ],
      };
    } else {
      return null;
    }
  }
);

export const getRewardAccountsWithPaymentAccounts = createSelector(
  getRewardsAccounts,
  getPaymentMethods,
  (rewardsAccounts, paymentMethods) => {
    const accountsWithSavedPayment = paymentMethods.reduce(
      (savedPayments, currentAcc) => {
        const matchingRewardsAcct = rewardsAccounts.find(
          (rewards) =>
            rewards.lastFour === currentAcc.last4 ||
            rewards.lastFourVirtualCardNumbers?.includes(currentAcc.last4 || "")
        );

        if (matchingRewardsAcct) {
          savedPayments.push(matchingRewardsAcct);
        }
        const isTestCard =
          window.__mclean_env__.ENV !== "production" &&
          currentAcc.last4 &&
          TEST_CARD_LAST_FOURS.includes(currentAcc.last4);
        if (isTestCard) {
          savedPayments.push(rewardsAccounts[0]);
        }

        return savedPayments;
      },
      [] as RewardsAccount[]
    );
    return accountsWithSavedPayment;
  }
);

export const getEarnValuesByRewardAcctId = (state: IStoreState) =>
  state.hotelBook.earnValuesByRewardAcctId;

export const getPriceQuoteRequest = (state: IStoreState) =>
  state.hotelBook.priceQuoteRequest;

// Corporate Travel
export const getSubmitForApprovalCallState = (state: IStoreState) =>
  state.hotelBook.submitForApprovalCallState;

export const getSubmitForApprovalError = (state: IStoreState) =>
  state.hotelBook.submitForApprovalError;
