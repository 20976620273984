import { Box } from "@material-ui/core";
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core/styles";
import { AgentBanner, TravelerWorkflowDialog, useDeviceTypes } from "halifax";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import {
  CallState,
  CorpSessionInfo,
  CustomEvents,
  EditTravelerModalState,
  HotelsClientAssetProps,
  HotelsModuleProps,
  installColorConfig,
  IPerson,
  SavedTravelersProperties,
  SelfServeEvents,
  SessionInfo,
  VIEWED_TRAVELER_DETAILS,
  ViewedSavedTravelersProperties,
  ViewedTravelerDetailsProperties,
} from "redmond";
import { isCorpTenant, useIsAutoApprovalEnabled } from "@capone/common";
import {
  ExperimentsHookProvider,
  useExperimentsById,
} from "@capone/experiments";

import useWindowEventListener from "./hooks/useWindowEventListener";
import { eventsToListen } from "./utils/events";

import { trackEvent } from "./api/v0/analytics/trackEvent";
import fetchUserInfo from "./api/v0/user/fetchUserInfo";
import AxiosInterceptors from "./components/AxiosInterceptors";
import ExperimentsProvider, {
  FINTECH_HOTEL_UX_UPDATED,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  getExperimentVariantJSON,
  HOTEL_CFAR_MODEL_V1,
  HOTEL_CFAR_PASS_OPAQUE_PARAM,
  HOTEL_CFAR_REFUND_DISPLAY,
  HOTEL_CFAR_REFUND_DISPLAY_VARIANTS,
  HOTEL_CFAR_ROOM_ID_MATCH,
  HOTEL_CFAR_RR_MULTIPLE_CANCELLATION_POLICIES,
  HOTEL_DISPLAY_ROOM_PRICING_UPDATE,
  HOTEL_PD_BANNER_COPY,
  HOTEL_PD_BANNER_COPY_VARIANTS,
  HOTELS_CFAR,
  HOTELS_CFAR_ELIGIBLE_POLICY,
  HOTELS_CFAR_ELIGIBLE_POLICY_VARIANTS,
  HOTELS_LFAR,
  HOTELS_PRICE_FREEZE,
  HOTELS_PRICE_FREEZE_ON_REFUNDABLE_ROOMS,
  useExperiments,
  HOTELS_TAXES_AND_FEES_EXPERIMENT,
  HOTELS_TAXES_AND_FEES_VARIANTS,
} from "./context/experiments";
import UserSourceProvider from "./context/userSource";
import * as englishTranslations from "./lang/en.json";
import * as frenchTranslations from "./lang/fr.json";
import { HotelAvailability } from "./modules/availability";
import { HotelBook } from "./modules/book";
import { HotelFreeze } from "./modules/freeze";
import {
  deleteUserPassenger,
  fetchCorpUserPassengers,
  fetchUserPassengers,
  updateUserPassenger,
} from "./modules/book/actions/actions";
import { PriceMatchBanner } from "./modules/book/components/PriceMatchBanner";
import { getUserPassengers } from "./modules/book/reducer";
import {
  setAgentEmail,
  setIsAutoApprovalEnabled,
  setIsFirstLaunch,
  setSelectedAccountReferenceId,
} from "./modules/rewards/actions/actions";
import { RewardsBanner } from "./modules/rewards/components";
import { HotelsSearch } from "./modules/search";
import { HotelShop } from "./modules/shop";
import { HotelAncillary } from "./modules/ancillary";
import { store } from "./store";
import {
  PATH_ANCILLARY,
  PATH_AVAILABILITY,
  PATH_BOOK,
  PATH_FREEZE,
  PATH_HOME,
  PATH_HOME_SEARCH,
  PATH_SHOP,
} from "./utils/paths";
import { setAncillaryExperiments } from "./modules/ancillary/actions/actions";
import { setPriceFreezeExperiments } from "./modules/freeze/actions/actions";
import { OnboardingModal } from "./components/OnboardingModal";
import { config } from "./api/config";
import { useMedalliaFeedback } from "b2b-base/src/components/MedalliaFeedback";
import fetchUserPolicies from "./api/v0/policies/fetchPolicies";

function loadLocaleData(locale: string): any {
  switch (locale) {
    case "fr":
      return frenchTranslations;
    default:
      return englishTranslations;
  }
}
const generateClassName = createGenerateClassName({
  productionPrefix: "ptHotelsModule",
  seed: "ptHotelsModule",
});

interface HotelsClientContextType extends HotelsClientAssetProps {
  isAgentPortal: boolean;
  isAutoApprovalEnabled: boolean;
}

export const ClientContext = React.createContext<
  Partial<HotelsClientContextType>
>({});

const App = (props: HotelsModuleProps) => {
  const {
    baseHistory,
    clientAssets,
    colors,
    experiments,
    isAgentPortal,
    language,
    theme,
  } = props;

  const [activeTheme, setActiveTheme] = useState(theme);
  const [locale, setLocale] = useState(language);
  const [messages, setMessages] = useState(loadLocaleData(locale).default);
  const [sessionInfo, setSessionInfo] = useState(clientAssets.sessionInfo);
  const [policies, setPolicies] = useState(clientAssets.policies);

  useEffect(() => {
    handleFetchUserInfo();
  }, []);

  useEffect(() => {
    if (!policies && isCorpTenant(config.TENANT)) {
      handleFetchPolicies();
    }
  }, [policies]);

  installColorConfig(colors);

  const handleFetchUserInfo = async () => {
    try {
      const userInfoResponse = await fetchUserInfo();
      setSessionInfo(userInfoResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFetchPolicies = async () => {
    const policiesResponse = await fetchUserPolicies();
    setPolicies(policiesResponse.policyDetail);
  };

  const handleThemeChanged = (e: CustomEvent) => {
    setActiveTheme(e.detail);
    console.log("THEME RECEIVED:", e.detail.palette.type);
  };
  const handleLocaleChanged = (e: CustomEvent) => {
    setLocale(e.detail);
    const localMessages = loadLocaleData(e.detail);
    setMessages(localMessages.default);
  };
  useWindowEventListener(eventsToListen.HOST_THEME_CHANGED, handleThemeChanged);
  useWindowEventListener(
    eventsToListen.HOST_LOCALE_CHANGED,
    handleLocaleChanged
  );
  const { firstName, lastName } = sessionInfo?.userInfo || {
    firstName: "",
    lastName: "",
  };

  const isAutoApprovalEnabled = useIsAutoApprovalEnabled(clientAssets.policies);

  return (
    <Provider store={store}>
      <ExperimentsProvider
        initState={{
          experiments: experiments?.experiments || [],
          trackingProperties: experiments?.trackingProperties,
          callState: CallState.NotCalled,
        }}
      >
        <ExperimentsHookProvider isLoggedIn={Boolean(sessionInfo?.csrfToken)}>
          <UserSourceProvider>
            <Router history={baseHistory}>
              <AxiosInterceptors isAgentPortal={isAgentPortal} />
              <ClientContext.Provider
                value={{
                  ...clientAssets,
                  sessionInfo,
                  policies,
                  isAgentPortal,
                  isAutoApprovalEnabled,
                }}
              >
                <div className="App">
                  <StylesProvider
                    generateClassName={generateClassName}
                    injectFirst
                  >
                    <ThemeProvider theme={activeTheme}>
                      {messages != null ? (
                        <IntlProvider
                          locale={locale}
                          defaultLocale="en"
                          messages={messages}
                        >
                          <Switch>
                            {isAgentPortal ? (
                              <Route path="*">
                                <AgentBanner
                                  firstName={firstName}
                                  lastName={lastName}
                                />
                              </Route>
                            ) : (
                              <>
                                <Route path={PATH_BOOK}>
                                  <PriceMatchBanner />
                                </Route>
                                <Route path={PATH_FREEZE}>
                                  <PriceMatchBanner hideOnMobile />
                                </Route>
                                <Route path="*">
                                  <RewardsBanner />
                                </Route>
                              </>
                            )}
                          </Switch>
                          <Body
                            sessionInfo={sessionInfo}
                            clientAssets={{ ...clientAssets, policies }}
                          />
                        </IntlProvider>
                      ) : (
                        <div>Loading</div>
                      )}
                    </ThemeProvider>
                  </StylesProvider>
                </div>
              </ClientContext.Provider>
            </Router>
          </UserSourceProvider>
        </ExperimentsHookProvider>
      </ExperimentsProvider>
    </Provider>
  );
};

export const Body = (props: {
  sessionInfo?: SessionInfo | CorpSessionInfo;
  clientAssets: HotelsClientAssetProps;
}) => {
  const isCorp = isCorpTenant(config.TENANT);

  const streamlineTravelerProfileEnabled =
    useExperimentsById("corp-streamline-traveler-profile-fe")?.variant === "m2";
  const { sessionInfo, clientAssets } = props;
  const dispatch = useDispatch();
  const expState = useExperiments();
  const { matchesMobile } = useDeviceTypes();
  const travelers = useSelector(getUserPassengers);
  // const selectedAccountReferenceId = useSelector(getSelectedAccountReferenceId);
  const [editTravelersOpen, setEditTravelersOpen] = useState(
    EditTravelerModalState.closed
  );

  const isHotelLoyaltyEnabled =
    useExperimentsById("corp-hotel-loyalty")?.variant === "available";

  const closeEditTravelersModal = useCallback(() => {
    setEditTravelersOpen(EditTravelerModalState.closed);
  }, []);

  const onEditTravelersStateChange = useCallback((ev: any) => {
    const newEditModalState =
      (ev as CustomEvent).detail ?? EditTravelerModalState.closed;

    setEditTravelersOpen(newEditModalState);
  }, []);

  const userRoles =
    sessionInfo && "corporateInfo" in sessionInfo
      ? sessionInfo.corporateInfo.role
      : [];

  const defaultTravelerId =
    sessionInfo && "corporateInfo" in sessionInfo
      ? sessionInfo.corporateInfo.defaultTravelerId
      : "";

  useEffect(() => {
    if (editTravelersOpen === EditTravelerModalState.hotel) {
      if (isCorp) {
        trackEvent({
          eventName: VIEWED_TRAVELER_DETAILS,
          properties: {
            num_traveler_profiles: travelers.length,
          } satisfies ViewedTravelerDetailsProperties,
        });
      } else {
        trackEvent({
          eventName: SelfServeEvents.ViewedSavedTravelers,
          properties: {
            url: window.location.pathname,
            num_traveler_profiles: travelers.length,
          } satisfies ViewedSavedTravelersProperties,
        });
      }
    }
  }, [editTravelersOpen, travelers.length]);

  const handleAddPass = useCallback(() => {
    !isCorp &&
      trackEvent({
        eventName: SelfServeEvents.ClickSavedTravelersAddNew,
        properties: {
          url: window.location.pathname,
        },
      });
  }, []);

  useEffect(() => {
    dispatch(
      isCorp && streamlineTravelerProfileEnabled
        ? fetchCorpUserPassengers()
        : fetchUserPassengers()
    );
    document.addEventListener(
      CustomEvents.editTravelersStateChange,
      onEditTravelersStateChange
    );

    return () => {
      document.removeEventListener(
        CustomEvents.editTravelersStateChange,
        onEditTravelersStateChange
      );
    };
  }, [streamlineTravelerProfileEnabled]);

  const handleAccountSelected = (event: CustomEvent) => {
    dispatch(setSelectedAccountReferenceId(event.detail));
  };
  useWindowEventListener(
    "mclean_rewards_account_selected",
    handleAccountSelected
  );

  useEffect(() => {
    if (sessionInfo) {
      dispatch(setIsFirstLaunch(sessionInfo.isFirstSession));
      if (sessionInfo.isDelegatedSession) {
        dispatch(setAgentEmail(sessionInfo.isDelegatedSession));
      }
    }
  }, [sessionInfo, dispatch]);

  useEffect(() => {
    dispatch(
      setAncillaryExperiments({
        [HOTELS_CFAR]: getExperimentVariant(expState.experiments, HOTELS_CFAR),
        [HOTEL_CFAR_REFUND_DISPLAY]: getExperimentVariantCustomVariants(
          expState.experiments,
          HOTEL_CFAR_REFUND_DISPLAY,
          HOTEL_CFAR_REFUND_DISPLAY_VARIANTS
        ),
        [HOTELS_CFAR_ELIGIBLE_POLICY]: getExperimentVariantCustomVariants(
          expState.experiments,
          HOTELS_CFAR_ELIGIBLE_POLICY,
          HOTELS_CFAR_ELIGIBLE_POLICY_VARIANTS
        ),
        [HOTELS_LFAR]: getExperimentVariant(expState.experiments, HOTELS_LFAR),
        [FINTECH_HOTEL_UX_UPDATED]: getExperimentVariant(
          expState.experiments,
          FINTECH_HOTEL_UX_UPDATED
        ),
        [HOTEL_PD_BANNER_COPY]: getExperimentVariantCustomVariants(
          expState.experiments,
          HOTEL_PD_BANNER_COPY,
          HOTEL_PD_BANNER_COPY_VARIANTS
        ),
        [HOTEL_CFAR_RR_MULTIPLE_CANCELLATION_POLICIES]: getExperimentVariant(
          expState.experiments,
          HOTEL_CFAR_RR_MULTIPLE_CANCELLATION_POLICIES
        ),

        [HOTEL_DISPLAY_ROOM_PRICING_UPDATE]: getExperimentVariant(
          expState.experiments,
          HOTEL_DISPLAY_ROOM_PRICING_UPDATE
        ),
        [HOTEL_CFAR_MODEL_V1]: getExperimentVariantJSON(
          expState.experiments,
          HOTEL_CFAR_MODEL_V1
        ),
        [HOTEL_CFAR_ROOM_ID_MATCH]: getExperimentVariant(
          expState.experiments,
          HOTEL_CFAR_ROOM_ID_MATCH
        ),
        [HOTEL_CFAR_PASS_OPAQUE_PARAM]: getExperimentVariant(
          expState.experiments,
          HOTEL_CFAR_PASS_OPAQUE_PARAM
        ),
        [HOTELS_TAXES_AND_FEES_EXPERIMENT]: getExperimentVariantCustomVariants(
          expState.experiments,
          HOTELS_TAXES_AND_FEES_EXPERIMENT,
          HOTELS_TAXES_AND_FEES_VARIANTS
        ),
      })
    );
    dispatch(
      setPriceFreezeExperiments({
        [HOTELS_PRICE_FREEZE]: getExperimentVariant(
          expState.experiments,
          HOTELS_PRICE_FREEZE
        ),
        [HOTELS_PRICE_FREEZE_ON_REFUNDABLE_ROOMS]: getExperimentVariant(
          expState.experiments,
          HOTELS_PRICE_FREEZE_ON_REFUNDABLE_ROOMS
        ),
      })
    );
  }, [expState]);

  const { isAutoApprovalEnabled } = useContext(ClientContext);

  useEffect(() => {
    dispatch(setIsAutoApprovalEnabled(Boolean(isAutoApprovalEnabled)));
  }, [dispatch, isAutoApprovalEnabled]);

  useMedalliaFeedback();

  return (
    <>
      <Box className="main-section">
        <Route path={[PATH_HOME, PATH_HOME_SEARCH]} exact>
          <HotelsSearch />
        </Route>
        <Route path={PATH_AVAILABILITY}>
          <HotelAvailability />
        </Route>
        <Route path={PATH_SHOP}>
          <HotelShop />
        </Route>
        <Route path={PATH_ANCILLARY}>
          <HotelAncillary />
        </Route>
        <Route path={PATH_BOOK}>
          <HotelBook />
        </Route>
        <Route path={PATH_FREEZE}>
          <HotelFreeze />
        </Route>
      </Box>
      <TravelerWorkflowDialog
        tenant={config.TENANT}
        showHotelLoyaltySection={isHotelLoyaltyEnabled}
        disableDriversLicenseValidation
        disallowSelect
        displayModalSubtitle
        showAdditionalInfoSection
        showDriverLicenseSection
        showFrequentFlyerSection
        showGenderField
        showNationalityField
        buttonClassName="b2b"
        dialogProps={{
          className: "hotel-edit-traveler",
          onBackdropClick: closeEditTravelersModal,
          onClose: closeEditTravelersModal,
          open: editTravelersOpen === EditTravelerModalState.hotel,
        }}
        errorMessage="Add or choose one traveler to continue."
        fullScreenWithBanner={matchesMobile}
        handleDeletePassenger={(personId: string) => {
          dispatch(deleteUserPassenger({ personId }));
        }}
        handleUpdatePassenger={(
          person: IPerson,
          hideProfileAction,
          entryPoint
        ) => {
          dispatch(
            updateUserPassenger(
              { person, hideProfileAction },
              true,
              undefined,
              defaultTravelerId,
              streamlineTravelerProfileEnabled,
              entryPoint
            )
          );

          const frequentFlyerProgramsTotal = Reflect.ownKeys(
            person.frequentFlyer
          ).length;

          !isCorp &&
            trackEvent({
              eventName: SelfServeEvents.ClickSavedTravelersSaveTraveler,
              properties: {
                url: window.location.pathname,
                frequent_flyer_program_added: frequentFlyerProgramsTotal > 0,
                num_frequent_flyer_programs: frequentFlyerProgramsTotal,
              } satisfies SavedTravelersProperties,
            });
        }}
        onAddPass={handleAddPass}
        isMobile={matchesMobile}
        travelers={travelers}
        titles={{}}
        dialogContentProps={{}}
        userRoles={userRoles}
        trackEvent={trackEvent}
        defaultTravelerId={defaultTravelerId}
      />
      {clientAssets.policies && sessionInfo && (
        <OnboardingModal
          policies={clientAssets.policies}
          isBusinessLive={
            "corporateInfo" in sessionInfo &&
            sessionInfo?.corporateInfo.businessIsLive
          }
          trackEvent={trackEvent}
        />
      )}
    </>
  );
};

export default App;
