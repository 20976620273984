import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import {
  getHotelAvailabilityLodgingIdInFocus,
  getHotelAvailabilityLodgings,
  getHotelQueryParams,
  getHotelAvailabilitySearchLocationResult,
  getMapBound,
  getViewedHotelListProperties,
} from "../../reducer/selectors";
import { actions } from "../../actions";
import { MobileAvailabilityMapRollingGallery } from "./component";
import { getNightCount, getRoomsCount } from "../../../search/reducer";
import {
  getRewardsAccounts,
  getRewardsAccountWithLargestEarnForBanner,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
  getUserIsPrimaryCardHolder,
} from "../../../rewards/reducer";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state: IStoreState) => {
  // TODO: filtered lodging
  return {
    lodgings: getHotelAvailabilityLodgings(state),
    lodgingIdInFocus: getHotelAvailabilityLodgingIdInFocus(state),
    nightCount: getNightCount(state),
    selectedAccountId: getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    hotelQueryParams: getHotelQueryParams(state),
    searchLocation: getHotelAvailabilitySearchLocationResult(state),
    roomsCount: getRoomsCount(state),
    mapBounds: getMapBound(state),
    isVentureX: !!getRewardsAccounts(state).find((acc) =>
      acc.productDisplayName.includes("Venture X")
    ),
    viewedHotelListProperties: getViewedHotelListProperties(state),
    isUserPrimary: getUserIsPrimaryCardHolder(state),
    largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
  };
};

const mapDispatchToProps = {
  setLodgingIdInFocus: actions.setLodgingIdInFocus,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileAvailabilityMapRollingGalleryConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileAvailabilityMapRollingGallery = withRouter(
  connector(MobileAvailabilityMapRollingGallery)
);
