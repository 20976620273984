import { createSelector } from "reselect";
import {
  Lodging,
  IPriceRange,
  ViewedHotelListProperties,
  IResult,
  ITrackingProperties,
  ViewHotelsNearAvailabilityProperties,
  LodgingCollectionEnum,
  Place,
  IIdLodgings,
  TravelWalletOffer,
} from "redmond";
import { isEqual } from "lodash-es";
import dayjs from "dayjs";

import {
  performAmenitiesFilter,
  performStarRatingsFilter,
  performMaxPriceFilter,
  performHotelNameFilter,
  performFreeCancelFilter,
  performHotelsOnSaleFilter,
  performPremierAndLifestyleCollectionFilter,
  performPolicyFilter,
  performLoyaltyProgramFilter,
} from "../utils/processFilters";
import {
  orderByPriceLowToHigh,
  orderByStarRatingHighToLow,
} from "../utils/processSort";
import {
  HotelAvailabilitySortOption,
  HotelAvailabilityCallState,
} from "../state";
import { initialFilterState } from "../index";
import { IStoreState } from "../../../../reducers/types";
import {
  getAdultsCount,
  getFromDate,
  getHotelEntryProperties,
  getLocation,
  getUntilDate,
  getRoomsCount,
  getHotelAvailEntryPoint,
  getChildren,
} from "../../../search/reducer";
import {
  getSelectedAccount,
  getSelectedAccountIndex,
} from "../../../rewards/reducer";
import { getActiveCrossSellOffers } from "../../../travel-wallet/reducer";

export const getHotelAvailabilityTrackingProperties = (state: IStoreState) =>
  state.hotelAvailability.availabilityResponse?.trackingPropertiesV2;

export const getHotelAvailabilityLodgings = (state: IStoreState) =>
  state.hotelAvailability.availabilityResponse?.lodgings;

export const getHotelAvailabilityNextPageToken = (state: IStoreState) =>
  state.hotelAvailability.availabilityResponse?.nextPageToken;

export const getHotelAvailabilityCallState = (state: IStoreState) =>
  state.hotelAvailability.hotelAvailabilityCallState;

export const getHotelAvailabilitySearchLocation = (state: IStoreState) =>
  state.hotelAvailability.searchLocation;

export const getHotelAvailabilityLodgingIdInFocus = (state: IStoreState) =>
  state.hotelAvailability.lodgingIdInFocus;

export const getHotelAvailabilityLodgingIdHovered = (state: IStoreState) =>
  state.hotelAvailability.lodgingIdHovered;

export const getHotelAvailabilityAmenitiesFilter = (state: IStoreState) =>
  state.hotelAvailability.amenities;

export const getHotelAvailabilityFiltersBannerMessage = (state: IStoreState) =>
  state.hotelAvailability.filtersBannerMessage;

export const getHotelAvailabilityStarRatingsFilter = (state: IStoreState) =>
  state.hotelAvailability.starRatings;

export const getHotelAvailabilityPolicyFilter = (state: IStoreState) =>
  state.hotelAvailability.isInPolicy;

export const getHotelAvailabilityLoyaltyProgramsFilter = (state: IStoreState) =>
  state.hotelAvailability.loyaltyPrograms;

export const getHotelAvailabilityMaxPriceFilter = (state: IStoreState) =>
  state.hotelAvailability.maxPrice;

export const getHotelAvailabilityFreeCancelFilter = (state: IStoreState) =>
  state.hotelAvailability.freeCancel;

export const getHotelAvailabilityHotelsOnSaleFilter = (state: IStoreState) =>
  state.hotelAvailability.hotelsOnSaleOnly;

export const getSelectedLodgingIndex = (state: IStoreState) =>
  state.hotelAvailability.selectedLodgingIndex;

export const getHotelBestOfferOverall = (state: IStoreState) =>
  state.hotelAvailability.availabilityResponse?.bestOverallOffer;

export const getApplicableHotelOffer = createSelector(
  getHotelBestOfferOverall,
  getActiveCrossSellOffers,
  (
    hotelBestOfferOverall,
    activeCrossSellOffers
  ): TravelWalletOffer | undefined => {
    return hotelBestOfferOverall
      ? hotelBestOfferOverall
      : activeCrossSellOffers.length > 0
      ? activeCrossSellOffers[0]
      : undefined;
  }
);
export const getUserHotelPreferences = (state: IStoreState) =>
  state.hotelAvailability.userHotelPreferences;

export const getUserHotelPreferencesCallState = (state: IStoreState) =>
  state.hotelAvailability.userHotelPreferencesCallState;

export const getHasUserSetHotelPreferences = createSelector(
  getUserHotelPreferences,
  (userHotelPreferences): boolean => {
    return (
      !!userHotelPreferences?.amenities.length ||
      !!userHotelPreferences?.starRatings.length ||
      !!userHotelPreferences?.freeCancellationOnly
    );
  }
);

export const getIsPremierCollectionEnabled = (state: IStoreState) =>
  state.hotelAvailability.isPremierCollectionEnabled;

export const getDeduplicatedLodgings = createSelector(
  getHotelAvailabilityLodgings,
  getIsPremierCollectionEnabled,
  (lodgings, isPremierCollectionEnabled) => {
    let filteredLodgings = [];
    if (lodgings?.length) {
      if (isPremierCollectionEnabled) {
        filteredLodgings = lodgings
          .reduce((acc, curr) => {
            if (
              !acc.some(
                (lodging) =>
                  curr.lodging.id === lodging.lodging.id &&
                  curr.lodgingCollection === lodging.lodgingCollection
              )
            ) {
              acc.push(curr);
            }
            return acc;
          }, [] as Lodging[])
          .filter((lodging) => {
            const lodgingHasMultipleVersions =
              lodgings.filter((l) => l.lodging.id === lodging.lodging.id)
                .length > 1;

            if (lodgingHasMultipleVersions) {
              // prefer premier collection version if available
              return (
                lodging.lodgingCollection === LodgingCollectionEnum.Premier
              );
            }

            // always include the lodging if it's the only version
            return true;
          });
      } else {
        // filter out premier collection versions
        filteredLodgings = lodgings.filter(
          (lodging) =>
            lodging.lodgingCollection !== LodgingCollectionEnum.Premier
        );
      }
      return filteredLodgings;
    } else {
      return [];
    }
  }
);
export const getHotelAvailabilityMinMaxPriceRange = createSelector(
  getHotelAvailabilityLodgings,
  (lodgings): IPriceRange | null => {
    if (!lodgings || lodgings.length === 0) {
      return null;
    }

    let curMin = lodgings[0].price?.nightlyPrice.fiat.value;
    let curMax = lodgings[0].price?.nightlyPrice.fiat.value;

    lodgings.forEach((lodging) => {
      if (lodging.price) {
        const curPrice = lodging.price.nightlyPrice.fiat.value;

        if (!curMin || curPrice < curMin) curMin = curPrice;
        if (!curMax || curPrice > curMax) curMax = curPrice;
      }
    });

    // price happens to be optional, so there could be a case where all lodgings have no price
    if (curMin === undefined || curMax === undefined) return null;

    return {
      min: curMin,
      max: curMax,
    };
  }
);

export const getHotelAvailabilityHotelNameFilter = (state: IStoreState) =>
  state.hotelAvailability.hotelName;

export const hasChangedHotelAvailabilityAmenitiesFilter = createSelector(
  getHotelAvailabilityAmenitiesFilter,
  (amenities) => {
    return !isEqual(amenities, initialFilterState.amenities);
  }
);

export const hasChangedHotelAvailabilityStarRatingsFilter = createSelector(
  getHotelAvailabilityStarRatingsFilter,
  (starRatings) => {
    return !isEqual(starRatings, initialFilterState.starRatings);
  }
);

export const hasChangedHotelAvailabilityHotelNameFilter = createSelector(
  getHotelAvailabilityHotelNameFilter,
  (hotelName) => {
    return !isEqual(hotelName, initialFilterState.hotelName);
  }
);

export const hasChangedHotelAvailabilityFreeCancelFilter = createSelector(
  getHotelAvailabilityFreeCancelFilter,
  (freeCancel) => freeCancel !== initialFilterState.freeCancel
);

export const hasChangedHotelAvailabilityHotelsOnSaleFilter = createSelector(
  getHotelAvailabilityHotelsOnSaleFilter,
  (hotelsOnSaleOnly) => hotelsOnSaleOnly !== initialFilterState.hotelsOnSaleOnly
);

export const hasChangedHotelAvailabilityMaxPriceFilter = createSelector(
  getHotelAvailabilityMaxPriceFilter,
  (maxPrice) => {
    return maxPrice !== initialFilterState.maxPrice;
  }
);

export const hasChangedHotelAvailabilityPolicyFilter = createSelector(
  getHotelAvailabilityPolicyFilter,
  (isInPolicy) => isInPolicy !== initialFilterState.isInPolicy
);

export const getAppliedHotelAvailabilityFilterCount = createSelector(
  hasChangedHotelAvailabilityAmenitiesFilter,
  hasChangedHotelAvailabilityFreeCancelFilter,
  hasChangedHotelAvailabilityHotelNameFilter,
  hasChangedHotelAvailabilityHotelsOnSaleFilter,
  hasChangedHotelAvailabilityMaxPriceFilter,
  hasChangedHotelAvailabilityStarRatingsFilter,
  (
    hasChangedAmenitiesFilter,
    hasChangedFreeCancelFilter,
    hasChangedHotelNameFilter,
    hasChangedHotelsOnSaleFilter,
    hasChangedMaxPriceFilter,
    hasChangedStarRatingsFilter
  ) =>
    [
      hasChangedAmenitiesFilter,
      hasChangedFreeCancelFilter,
      hasChangedHotelNameFilter,
      hasChangedHotelsOnSaleFilter,
      hasChangedStarRatingsFilter,
      hasChangedMaxPriceFilter,
    ].filter((applied) => applied).length
);

export const getHotelAvailabilityCurrency = createSelector(
  getHotelAvailabilityLodgings,
  (lodgings): string => {
    if (lodgings) {
      const lodgingWithPrice = lodgings.find(
        (lodging) => !!lodging.price?.nightlyPrice
      );

      if (lodgingWithPrice?.price) {
        return lodgingWithPrice.price.nightlyPrice.fiat.currencyCode;
      }
    }

    return "USD";
  }
);

export const getShowPremiumStaysOnlyFilter = (state: IStoreState) =>
  state.hotelAvailability.showPremiumStaysOnly;

const getAllHotelAvailabilityFilters = createSelector(
  getHotelAvailabilityAmenitiesFilter,
  getHotelAvailabilityStarRatingsFilter,
  getHotelAvailabilityMaxPriceFilter,
  getHotelAvailabilityHotelNameFilter,
  getHotelAvailabilityFreeCancelFilter,
  getHotelAvailabilityHotelsOnSaleFilter,
  getHotelAvailabilityPolicyFilter,
  getHotelAvailabilityLoyaltyProgramsFilter,
  getShowPremiumStaysOnlyFilter,
  getIsPremierCollectionEnabled,
  (
    amenities,
    starRatings,
    maxPrice,
    hotelName,

    freeCancel,
    hotelsOnSaleOnly,

    isInPolicy,
    loyaltyPrograms,
    showPremiumStaysOnlyFilter,
    isPremierCollectionEnabled
  ) => {
    return {
      amenities,
      starRatings,
      maxPrice,
      hotelName,
      freeCancel,
      hotelsOnSaleOnly,
      isInPolicy,
      loyaltyPrograms,
      showPremiumStaysOnlyFilter: isPremierCollectionEnabled
        ? showPremiumStaysOnlyFilter
        : false,
    };
  }
);

export const getFilteredHotelAvailabilityLodgings = createSelector(
  getAllHotelAvailabilityFilters,
  getDeduplicatedLodgings,
  (filters, lodgings): Lodging[] => {
    const {
      amenities,
      starRatings,
      maxPrice,
      hotelName,
      freeCancel,
      hotelsOnSaleOnly,
      isInPolicy,
      loyaltyPrograms,
      showPremiumStaysOnlyFilter,
    } = filters;

    const meetsFilterPredicates = (lodging: Lodging) => {
      if (
        !performAmenitiesFilter(lodging, amenities) ||
        !performStarRatingsFilter(lodging, starRatings) ||
        !performMaxPriceFilter(lodging, maxPrice) ||
        !performHotelNameFilter(lodging, hotelName) ||
        !performPolicyFilter(lodging, isInPolicy) ||
        !performPremierAndLifestyleCollectionFilter(
          lodging,
          showPremiumStaysOnlyFilter
        ) ||
        !performFreeCancelFilter(lodging, freeCancel) ||
        !performHotelsOnSaleFilter(lodging, hotelsOnSaleOnly) ||
        !performLoyaltyProgramFilter(lodging, loyaltyPrograms)
      ) {
        return false;
      }

      return true;
    };

    return (lodgings ?? []).filter(meetsFilterPredicates);
  }
);

export const getIsFilteredHotelAvailabilityLodgingsEmpty = createSelector(
  getFilteredHotelAvailabilityLodgings,
  getHotelAvailabilityCallState,
  (lodgings, availabilityCallState): boolean => {
    return (
      lodgings.length === 0 &&
      availabilityCallState === HotelAvailabilityCallState.Complete
    );
  }
);

export const getIsFilteredHotelAvailabilityLodgingsEmptyAndDoneSearching =
  createSelector(
    getFilteredHotelAvailabilityLodgings,
    getHotelAvailabilityCallState,
    (lodgings, availabilityCallState): boolean => {
      return (
        lodgings.length === 0 &&
        (availabilityCallState === HotelAvailabilityCallState.Complete ||
          availabilityCallState ===
            HotelAvailabilityCallState.FollowUpSearchCallSuccess)
      );
    }
  );

export const getFilteredHotelAvailabilityLodgingsContainPremierCollection =
  createSelector(getFilteredHotelAvailabilityLodgings, (lodgings): boolean => {
    return lodgings.some((lodging) => {
      return (
        lodging.isLifestyleCollection ||
        lodging.isLuxuryCollection ||
        lodging.lodgingCollection === LodgingCollectionEnum.Lifestyle ||
        lodging.lodgingCollection === LodgingCollectionEnum.Premier
      );
    });
  });

export const getIsUnfilteredHotelAvailabilityLodgingsEmpty = createSelector(
  getDeduplicatedLodgings,
  getHotelAvailabilityCallState,
  (lodgings, availabilityCallState): boolean => {
    return (
      lodgings?.length === 0 &&
      availabilityCallState === HotelAvailabilityCallState.Complete
    );
  }
);

export const getHotelAvailabilitySortOption = (state: IStoreState) =>
  state.hotelAvailability.sortOption;

export const getTotalPropertyCount = (state: IStoreState) =>
  state.hotelAvailability.totalPropertyCount;

export const getFilteredAndSortedHotelAvailabilityLodgings = createSelector(
  getFilteredHotelAvailabilityLodgings,
  getAllHotelAvailabilityFilters,
  getHotelAvailabilitySortOption,
  (lodgings, filters, sortOption): Lodging[] => {
    const { availableLodgings, unavailableLodgings } = lodgings.reduce(
      (prev, curr) => {
        const available =
          typeof curr.available === "undefined" ? true : curr.available;

        if (available) prev.availableLodgings.push(curr);
        else prev.unavailableLodgings.push(curr);

        return prev;
      },
      {
        availableLodgings: [] as Lodging[],
        unavailableLodgings: [] as Lodging[],
      }
    );

    const sortedAvailableHotels = (() => {
      switch (sortOption) {
        case HotelAvailabilitySortOption.Pricing:
          return orderByPriceLowToHigh(availableLodgings);
        case HotelAvailabilitySortOption.StarRating:
          return orderByStarRatingHighToLow(availableLodgings);
        case HotelAvailabilitySortOption.Recommended:
          if (filters.showPremiumStaysOnlyFilter) {
            return availableLodgings.slice().sort((a, b) => {
              if (a.lodgingCollection !== b.lodgingCollection) {
                if (a.lodgingCollection === LodgingCollectionEnum.Premier) {
                  return -1;
                } else if (
                  b.lodgingCollection === LodgingCollectionEnum.Premier
                ) {
                  return 1;
                } else if (
                  a.lodgingCollection === LodgingCollectionEnum.Lifestyle
                ) {
                  return -1;
                } else if (
                  b.lodgingCollection === LodgingCollectionEnum.Lifestyle
                ) {
                  return 1;
                }
              }
              return 0;
            });
          } else {
            return availableLodgings;
          }
      }
    })();

    return sortedAvailableHotels.concat(unavailableLodgings);
  }
);

export const getMapBound = (state: IStoreState) =>
  state.hotelAvailability.mapBound;

export const getOpenDatesModal = (state: IStoreState) =>
  state.hotelAvailability.openDatesModal;

export const getHotelAvailabilitySearchLodgingIds = (state: IStoreState) =>
  state.hotelAvailability.searchLodgingIds;

export const getHotelAvailabilityFromDate = (state: IStoreState) =>
  state.hotelAvailability.searchFromDate;

export const getHotelAvailabilityUntilDate = (state: IStoreState) =>
  state.hotelAvailability.searchUntilDate;

export const getHotelAvailabilityAdultsCount = (state: IStoreState) =>
  state.hotelAvailability.searchAdultsCount;

export const getHotelAvailabilityChildren = (state: IStoreState) =>
  state.hotelAvailability.searchChildren;

export const getHotelAvailabilityRoomsCount = (state: IStoreState) =>
  state.hotelAvailability.searchRoomsCount;

export const getSearchedNightCount = createSelector(
  getHotelAvailabilityFromDate,
  getHotelAvailabilityUntilDate,
  (fromDate, untilDate) => {
    if (!fromDate || !untilDate) {
      return null;
    }

    return dayjs(untilDate).diff(fromDate, "days");
  }
);

export const getHotelAvailabilitySearchLocationResult = (
  state: IStoreState
) => {
  return state.hotelAvailability.searchLocationResult;
};

export const getHotelQueryParams = createSelector(
  getHotelAvailabilityFromDate,
  getHotelAvailabilityUntilDate,
  getHotelAvailabilityAdultsCount,
  getHotelAvailabilityChildren,
  getSelectedAccountIndex,
  getSelectedLodgingIndex,
  getHotelAvailEntryPoint,
  (
    fromDate,
    untilDate,
    adultsCount,
    children,
    selectedAccountIndex,
    selectedLodgingIndex,
    entryPoint
  ) => ({
    fromDate,
    untilDate,
    adultsCount,
    children,
    selectedAccountIndex,
    selectedLodgingIndex,
    entryPoint,
  })
);

export const getUnavailableLodgingCount = createSelector(
  getDeduplicatedLodgings,
  (lodgings) =>
    lodgings?.filter((lodging) =>
      typeof lodging.available === "undefined" ? false : !lodging.available
    ).length || 0
);

export const getAvailableLodgingCount = createSelector(
  getDeduplicatedLodgings,
  (lodgings) =>
    lodgings?.filter((lodging) =>
      typeof lodging.available === "undefined" ? false : lodging.available
    ).length || 0
);

export const getLuxuryCollectionLodgingCount = createSelector(
  getDeduplicatedLodgings,
  (lodgings) =>
    lodgings?.filter(
      (lodging) => lodging.lodgingCollection === LodgingCollectionEnum.Premier
    ).length
);

export const getLifestyleCollectionLodgingCount = createSelector(
  getDeduplicatedLodgings,
  (lodgings) =>
    lodgings?.filter(
      (lodging) => lodging.lodgingCollection === LodgingCollectionEnum.Lifestyle
    ).length
);

export const getLodgingPromotionsCount = createSelector(
  getDeduplicatedLodgings,
  (lodgings) =>
    lodgings?.filter((lodging) => !!lodging.bestPromotionThisLodging).length
);

export const getViewedHotelListProperties = createSelector(
  getHotelEntryProperties,
  getSelectedAccount,
  getHotelAvailabilityTrackingProperties,
  getUnavailableLodgingCount,
  getAvailableLodgingCount,
  getHotelBestOfferOverall,
  getLuxuryCollectionLodgingCount,
  getLodgingPromotionsCount,
  getLifestyleCollectionLodgingCount,
  getHotelAvailabilitySearchLocationResult,
  getFilteredHotelAvailabilityLodgings,
  (
    properties,
    account,
    trackingProperties,
    unavailableLodgingCount,
    availableLodgingCount,
    bestOverallOffer,
    luxuryLodgingCount,
    lodgingPromotionsCount,
    lifestyleLodgingCount,
    location,
    filteredLodgings
  ): ITrackingProperties<ViewedHotelListProperties> => {
    return {
      properties: {
        ...properties.properties,
        preferences_applied: undefined,
        account_type_selected: account?.productDisplayName || "",
        unavailable_hotels: unavailableLodgingCount,
        available_hotels: availableLodgingCount,
        ...bestOverallOffer?.trackingPropertiesV2?.properties,
        has_offer: !!bestOverallOffer,
        account_use_type: account?.accountUseType,
        customer_account_role: account?.customerAccountRole,
        account_allow_rewards_redemption: account?.allowRewardsRedemption,
        pc_shown: luxuryLodgingCount || 0,
        has_pc_properties: (luxuryLodgingCount || 0) > 0,
        has_lodging_promotions: lodgingPromotionsCount,
        lc_shown: lifestyleLodgingCount || 0,
        has_lc_properties: (lifestyleLodgingCount || 0) > 0,
        city_name: (
          location?.id as { lodgingSelection: Place }
        )?.lodgingSelection.placeTypes.includes("locality")
          ? location?.label
          : undefined,
        state:
          filteredLodgings.length > 0
            ? filteredLodgings[0].lodging.state?.name
            : undefined,
        no_availability: !filteredLodgings.length,
        google_place_id: (location?.id as IIdLodgings)?.lodgingSelection
          ?.placeId,
        ...trackingProperties?.properties,
      },
      encryptedProperties: [
        bestOverallOffer?.trackingPropertiesV2?.encryptedProperties ?? "",
        trackingProperties?.encryptedProperties ?? "",
      ],
    };
  }
);

export const getHasViewedUnavailableHotel = (state: IStoreState) =>
  state.hotelAvailability.hasViewedUnavailableHotel;

export const getExistingHotelsAvailabilityRequestParameters = createSelector(
  getHotelAvailabilitySearchLocationResult,
  getHotelAvailabilityFromDate,
  getHotelAvailabilityUntilDate,
  getHotelAvailabilityAdultsCount,
  getHotelAvailabilityChildren,
  getHotelAvailabilityRoomsCount,

  (
    location,
    fromDate,
    untilDate,
    adultsCount,
    children,
    roomsCount
  ): {
    location: IResult | null;
    fromDate: Date | null;
    untilDate: Date | null;
    adultsCount: number | null;
    children: number[];
    roomsCount: number;
  } => ({
    location,
    fromDate,
    untilDate,
    adultsCount,
    children,
    roomsCount,
  })
);
export const areRequestParametersChanged = createSelector(
  getExistingHotelsAvailabilityRequestParameters,
  getLocation,
  getFromDate,
  getUntilDate,
  getAdultsCount,
  getChildren,
  getRoomsCount,
  (
    requestParameters,
    locationSearched,
    fromDateSearched,
    untilDateSearched,
    adultsCountSearched,
    childrenSearched,
    roomsCountSearched
  ): boolean => {
    return (
      (locationSearched !== null &&
        !isEqual(locationSearched, requestParameters.location)) ||
      !isEqual(fromDateSearched, requestParameters.fromDate) ||
      !isEqual(untilDateSearched, requestParameters.untilDate) ||
      !isEqual(adultsCountSearched, requestParameters.adultsCount) ||
      !isEqual(childrenSearched, requestParameters.children) ||
      !isEqual(roomsCountSearched, requestParameters.roomsCount)
    );
  }
);

export const getSearchLocationLabel = (state: IStoreState) => {
  const isMapArea = !!getViewHotelsNearLocation(state) || !!getMapBound(state);
  const location = getLocation(state);
  return location?.label ?? (isMapArea ? "Map area" : null);
};

export const getViewHotelsNearLocation = (state: IStoreState) =>
  state.hotelAvailability.viewHotelsNearLocation;

export const getViewHotelsNearLocationCategories = (state: IStoreState) =>
  state.hotelAvailability.viewHotelsNearLocationCategories;

export const getViewHotelsNearLocationCategoriesLoading = (
  state: IStoreState
) => state.hotelAvailability.viewHotelsNearLocationCategoriesLoading;

export const getViewHotelsNearAvailabilityProperties = createSelector(
  getViewedHotelListProperties,
  getViewHotelsNearLocation,
  (
    viewedHotelListProperties,
    viewHotelsNear
  ): ITrackingProperties<ViewHotelsNearAvailabilityProperties> => {
    return {
      properties: {
        ...viewedHotelListProperties.properties,
        poi_availability: viewHotelsNear?.label || "",
      },
      encryptedProperties: [...viewedHotelListProperties.encryptedProperties],
    };
  }
);

const filterToPremiumLodgings = (lodgings: Lodging[]) =>
  lodgings.filter(
    (lodging) =>
      lodging.lodgingCollection === LodgingCollectionEnum.Premier ||
      lodging.lodgingCollection === LodgingCollectionEnum.Lifestyle
  );

export const getPremiumStaysLodgingsCount = createSelector(
  getFilteredHotelAvailabilityLodgings,
  getDeduplicatedLodgings,
  (filtered, all) => ({
    filtered: filterToPremiumLodgings(filtered).length,
    all: filterToPremiumLodgings(all).length,
  })
);
