import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { PaymentCard } from "./component";
import {
  verifyPaymentMethod,
  listPaymentMethods,
  deletePaymentMethod,
  setSelectedPaymentMethodId,
  fetchProductToEarn,
} from "../../actions/actions";
import {
  getDeletePaymentMethodCallState,
  getListPaymentMethodsCallState,
  getPaymentMethods,
  getVerifyPaymentMethodCallState,
  getSelectedPaymentMethodId,
  getTotalCreditCardPaymentRequired,
  getIsCreditCardPaymentRequired,
  getRewardsPaymentAccount,
  getRewardsPaymentAccountReferenceId,
  getHasError,
  getEarnValuesByRewardAcctId,
  getIsStackedTravelWalletPaymentOnly,
  getIsTravelWalletCreditPaymentOnly,
  getIsTravelWalletOfferPaymentOnly,
  isTravelOffersOrCreditAvailableSelector,
  hotelBookTypeSelector,
  getOffers,
  getCredit,
} from "../../reducer";
import {
  getAllowRewardsWithPolicy,
  getAllowTravelWalletWithPolicy,
  getRewardsAccounts,
  getRewardsAccountWithLargestEarnForBanner,
  getUserIsPrimaryCardHolder,
} from "../../../rewards/reducer";
import { fetchRewardsAccounts } from "../../../rewards/actions/actions";
import { IStoreState } from "../../../../reducers/types";
import { getHotelShopChosenPriceFreezeOffer } from "../../../shop/reducer";

const mapStateToProps = (state: IStoreState) => {
  const offers = getOffers(state);
  const hasOffersOrCredts =
    (offers && offers.length > 0) || Boolean(getCredit(state));
  return {
    paymentMethods: getPaymentMethods(state),
    rewardsAccounts: getRewardsAccounts(state),
    listPaymentMethodCallState: getListPaymentMethodsCallState(state),
    verifyPaymentMethodCallState: getVerifyPaymentMethodCallState(state),
    deletePaymentMethodCallState: getDeletePaymentMethodCallState(state),
    selectedPaymentMethodId: getSelectedPaymentMethodId(state),
    selectedRewardsPaymentAccount: getRewardsPaymentAccount(state),
    selectedRewardsPaymentAccountId: getRewardsPaymentAccountReferenceId(state),
    isCreditCardPaymentRequired: getIsCreditCardPaymentRequired(state),
    totalCreditCardPaymentRequired: getTotalCreditCardPaymentRequired(state),
    hasError: getHasError(state),
    earnValuesByRewardAcctId: getEarnValuesByRewardAcctId(state),
    largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
    isStackedTravelWalletPaymentOnly:
      getIsStackedTravelWalletPaymentOnly(state),
    isTravelCreditPaymentOnly: getIsTravelWalletCreditPaymentOnly(state),
    isTravelWalletOfferPaymentOnly: getIsTravelWalletOfferPaymentOnly(state),
    isTravelOffersOrCreditAvailable:
      isTravelOffersOrCreditAvailableSelector(state),
    hotelBookType: hotelBookTypeSelector(state),
    priceFreezeOffer: getHotelShopChosenPriceFreezeOffer(state),
    canRedeemRewards: getAllowRewardsWithPolicy(state),
    userIsPrimary: getUserIsPrimaryCardHolder(state),
    canUseTravelWallet:
      getAllowTravelWalletWithPolicy(state) && hasOffersOrCredts,
  };
};

const mapDispatchToProps = {
  verifyPaymentMethod,
  listPaymentMethods,
  deletePaymentMethod,
  setSelectedPaymentMethodId,
  fetchRewardsAccounts,
  fetchProductToEarn,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PaymentCardConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedPaymentCard = withRouter(connector(PaymentCard));
