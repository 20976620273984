import {
  AvailabilityResponse,
  BoundingBox,
  LodgingLocation,
  LodgingId,
  AmenityEnum,
  HotelStarRatingEnum,
  IResult,
  HotelEntryTypeEnum,
  ICategorizedResponse,
  UserHotelPreferencesPayload,
  CallState,
  Payment,
} from "redmond";

export enum HotelAvailabilitySortOption {
  Recommended = "Recommended",
  StarRating = "StarRating",
  Pricing = "Pricing",
}

export enum HotelAvailabilityCallState {
  NotCalled = "NotCalled",
  InitialMapSearchCallInProcess = "InitialMapSearchCallInProcess",
  InitialSearchCallInProcess = "InitialSearchCallInProcess",
  FollowUpSearchCallInProcess = "FollowUpSearchCallInProcess",
  InitialSearchCallSuccess = "InitialSearchCallSuccess",
  InitialMapSearchCallSuccess = "InitialMapSearchCallSuccess",
  FollowUpSearchCallSuccess = "FollowUpSearchCallSuccess",
  // when a FollowUpSearch returns empty lodgings
  Complete = "Complete",
  Failed = "Failed",
}

export interface IHotelFilterState {
  filtersBannerMessage?: string;
  amenities: AmenityEnum[];
  starRatings: HotelStarRatingEnum[];
  maxPrice: number;
  hotelName: string;
  freeCancel: boolean;
  hotelsOnSaleOnly: boolean;
  isInPolicy: boolean;
  loyaltyPrograms: string[];
  showPremiumStaysOnly: boolean;
}

export interface IHotelAvailabilityState extends IHotelFilterState {
  availabilityResponse?: AvailabilityResponse;
  hotelAvailabilityCallState: HotelAvailabilityCallState;

  mapBound: BoundingBox | null;
  searchLocation: LodgingLocation | null;
  searchLodgingIds: LodgingId[] | null;

  searchFromDate: Date | null;
  searchUntilDate: Date | null;
  searchLocationResult: IResult | null;

  lodgingIdInFocus: LodgingId | null;
  lodgingIdHovered: LodgingId | null;

  hasViewedUnavailableHotel: boolean;

  sortOption: HotelAvailabilitySortOption;

  // The datesPicker popup needs to be controllable through different components
  openDatesModal: boolean;

  selectedLodgingIndex: number | null;
  searchAdultsCount: number;
  searchChildren: number[];
  searchRoomsCount: number;
  hotelAvailEntryPoint: HotelEntryTypeEnum | undefined;
  totalPropertyCount: number | null;

  viewHotelsNearLocation: IResult | null;
  viewHotelsNearLocationCategories: ICategorizedResponse[];
  viewHotelsNearLocationCategoriesLoading: boolean | null;

  userHotelPreferences?: UserHotelPreferencesPayload;
  userHotelPreferencesCallState: CallState;
  isPremierCollectionEnabled: boolean;

  paymentMethods: Payment[];
  listPaymentMethodCallState: CallState;
}
