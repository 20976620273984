import { Icon, IconName, getTotalAmountText } from "halifax";
import { Box, Typography } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import {
  CFAR_POLICY_SUBTITLE,
  CFAR_POLICY_TITLE,
  getCancellationPolicyInfoV2,
} from "./constants";
import { RouteComponentProps } from "react-router-dom";
import { CfarCancellationPolicyConnectorProps } from "./container";
import { AncillaryKindEnum } from "redmond";

export interface ICfarCancellationPolicyProps
  extends RouteComponentProps,
    CfarCancellationPolicyConnectorProps {}

export const CfarCancellationPolicy = (props: ICfarCancellationPolicyProps) => {
  const {
    hotelCfarQuote,
    additionalInfo,
    isHotelCfarRefundDisplayShowAmountAdditionalPlaces,
    isHotelCfarRefundDisplayNonRefPartialEnabled,
    isHotelCfarModelV1Enabled,
    addedAncillary,
    offerAmountToApply,
  } = props;
  const addedCfar = addedAncillary.find(
    (ancillary) => ancillary.kind === AncillaryKindEnum.Cfar
  );
  const cancellationPolicyInfoV2 = getCancellationPolicyInfoV2({
    additionalInfo: additionalInfo ?? undefined,
    isHotelCfarModelV1Enabled,
    isHotelCfarRefundDisplayNonRefPartialEnabled,
    addedCfar,
    offerAmountToApply,
  });
  const title =
    cancellationPolicyInfoV2?.primaryText ??
    CFAR_POLICY_TITLE(hotelCfarQuote?.coveragePercentage);
  const subtitle =
    cancellationPolicyInfoV2?.secondaryText ??
    CFAR_POLICY_SUBTITLE(
      hotelCfarQuote?.coveragePercentage,
      isHotelCfarRefundDisplayShowAmountAdditionalPlaces &&
        hotelCfarQuote?.coverageAmount
        ? getTotalAmountText(hotelCfarQuote.coverageAmount)
        : undefined
    );

  return (
    <Box
      className={clsx(
        "checkout-template-card-content-container",
        "cancellation-details"
      )}
    >
      <Typography variant="h2">
        <Icon name={IconName.CheckShieldBlue} />
        {title}
      </Typography>
      <Typography
        variant="body2"
        dangerouslySetInnerHTML={{
          __html: subtitle,
        }}
      />
    </Box>
  );
};
