import {
  HotelCfarQuoteAdditionalInfo,
  PassengerAncillaryPricing,
  Amount,
} from "redmond";
import { getTotalPriceText, twoDecimalFormatter } from "halifax";

export const CFAR_POLICY_TITLE = (percentage?: number) =>
  `${percentage?.toFixed(0) ?? 100}% refundable by Capital One Travel`;

export const CFAR_POLICY_SUBTITLE = (
  percentage?: number,
  coverageAmount?: string
) =>
  `Your room is made refundable by Capital One Travel. Cancel for any reason up until your scheduled check-in time and get ${
    coverageAmount
      ? `a ${
          percentage?.toFixed(0) ?? 100
        }% refund of your hotel base rate, plus taxes (${coverageAmount}).`
      : `a refund for ${
          percentage?.toFixed(0) ?? 100
        }% of your hotel base rate and taxes and fees.`
  } Cancellations made after this period are subject to a penalty according to the hotel's policy.${
    coverageAmount
      ? " Cancel for any reason fee will not be included in the refund."
      : ""
  }`;

export const getCancellationPolicyInfoV2 = ({
  additionalInfo,
  isHotelCfarModelV1Enabled,
  isHotelCfarRefundDisplayNonRefPartialEnabled,
  addedCfar,
  offerAmountToApply,
}: {
  additionalInfo: HotelCfarQuoteAdditionalInfo | undefined;
  isHotelCfarModelV1Enabled: boolean;
  isHotelCfarRefundDisplayNonRefPartialEnabled: boolean;
  addedCfar?: PassengerAncillaryPricing;
  offerAmountToApply?: Amount;
}): {
  primaryText: string;
  secondaryText?: string;
} | null => {
  if (!additionalInfo) {
    return null;
  }

  const coverageAmount =
    addedCfar?.coverageOverriding?.overridingCoverage.amount.amount ??
    addedCfar?.coverageAmount?.amount;
  const offer = offerAmountToApply?.amount ?? 0;
  const coverageAmountFormatted = coverageAmount
    ? `${addedCfar?.premium.fiat.currencySymbol}${(
        coverageAmount + offer
      ).toFixed(2)}`
    : undefined;
  const totalRefund = additionalInfo.totalRefundPrices
    ? getTotalPriceText({
        price: additionalInfo.totalRefundPrices?.fiat,
        priceFormatter: twoDecimalFormatter,
      })
    : "";
  if (additionalInfo.shouldRefundCfarPremium && isHotelCfarModelV1Enabled) {
    return {
      primaryText: "Fully refundable by Capital One Travel",
      secondaryText: `Your room is made refundable by Capital One Travel. Cancel for any reason up until your scheduled check-in time and get <strong>a full refund of your hotel base rate plus taxes and fees (${
        coverageAmountFormatted ?? totalRefund
      })</strong>. Cancellations made after this period are subject to a penalty according to the hotel’s policy. Cancel for any reason fee will be included in the refund.`,
    };
  } else if (
    !additionalInfo.shouldRefundCfarPremium &&
    isHotelCfarRefundDisplayNonRefPartialEnabled
  ) {
    return {
      primaryText: "Partially refundable by Capital One Travel",
      secondaryText: `Your room is made refundable by Capital One Travel. Cancel for any reason up until your scheduled check-in time and get <strong>a refund of your hotel base rate plus taxes (${
        coverageAmountFormatted ?? totalRefund
      })</strong>. Cancellations made after this period are subject to a penalty according to the hotel’s policy. Cancel for any reason fee will not be included in the refund.`,
    };
  }

  return null;
};
