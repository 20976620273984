import { actions, actionTypes } from "../actions";
import {
  CallState,
  HotelBookType,
  SELECT_PAYMENT,
  HOTEL_PRICE_FREEZE_SELECT_PAYMENT,
  getHotelPriceFreezePurchaseOfferProperties,
  HotelPriceQuoteScheduleRequestV2,
} from "redmond";
import { trackEvent } from "../../../api/v0/analytics/trackEvent";
import { config } from "../../../api/config";
import { isCorpTenant } from "@capone/common";
import { IHotelBookState } from "./state";

const DO_NOT_APPLY_REWARDS_KEY = "do-not-apply-rewards";

const defaultRewardsAccountRefId = isCorpTenant(config.TENANT)
  ? DO_NOT_APPLY_REWARDS_KEY
  : undefined;

const initialState: IHotelBookState = {
  userPassengers: [],
  userPassengerCallState: CallState.NotCalled,
  userSelectedTravelerId: undefined,
  userSelectedTravelerIds: [],
  userSelectedTravelersList: [],
  confirmationEmailAddress: undefined,
  paymentMethods: [],
  paymentMethod: undefined,
  selectedPaymentMethodId: undefined,
  verifyPaymentMethodResult: undefined,
  listPaymentMethodCallState: CallState.NotCalled,
  fetchPaymentMethodCallState: CallState.NotCalled,
  verifyPaymentMethodCallState: CallState.NotCalled,
  deletePaymentMethodCallState: CallState.NotCalled,
  session: undefined,
  pollPriceQuoteCallState: CallState.NotCalled,
  schedulePriceQuoteCallState: CallState.NotCalled,
  schedulePriceQuoteError: null,
  scheduleBookError: null,
  scheduleBookCallState: CallState.NotCalled,
  priceQuote: null,
  pricingWithAncillaries: null,
  hotelAncillaryQuotes: null,
  hotelPriceFreezePriceQuote: null,
  priceQuoteErrors: [],
  confirmationDetails: null,
  hotelPriceFreezeConfirmationDetails: null,
  confirmationDetailsCallState: CallState.NotCalled,
  confirmationDetailsErrors: [],
  rewardsAccountReferenceId: defaultRewardsAccountRefId,
  rewardsPaymentTotal: null,
  rewardsPaymentInFiatCurrency: null,
  productEarnValue: null,
  priceDifferenceAcknowledged: false,
  rewardsConversionFailed: false,
  offerToApply: undefined,
  offers: [],
  fetchApplicableTravelWalletItemsCallState: CallState.NotCalled,
  bestOfferOverall: undefined,
  earnValuesByRewardAcctId: {},
  hotelBookType: HotelBookType.DEFAULT,
  priceQuoteRequest: {} as HotelPriceQuoteScheduleRequestV2,
  submitForApprovalCallState: CallState.NotCalled,
  submitForApprovalError: null,
  approvalRequestReason: undefined,
};

export function reducer(
  state: IHotelBookState = initialState,
  action: actions.HotelBookActions
): IHotelBookState {
  switch (action.type) {
    case actionTypes.FETCH_USER_PASSENGERS:
      return {
        ...state,
        userPassengerCallState: CallState.InProcess,
      };

    case actionTypes.UPDATE_USER_PASSENGER:
    case actionTypes.UPDATE_USER_PASSENGERS_MULTIPLE:
      return {
        ...state,
        userPassengerCallState: CallState.InProcess,
      };

    case actionTypes.DELETE_USER_PASSENGER:
      return {
        ...state,
        userPassengerCallState: CallState.InProcess,
      };

    case actionTypes.SET_USER_PASSENGERS:
      return {
        ...state,
        userPassengerCallState: CallState.Success,
        userPassengers: [...action.userPassengers],
      };

    case actionTypes.SET_USER_SELECTED_PASSENGER_IDS:
      return {
        ...state,
        // Note: capone consumer only want to select a single traveler here.
        // We are also accounting for this in the halifax traveler workflow with the prop singleTravelerWorkflow
        userSelectedTravelerId: action.userSelectedPassengerIds[0],
        // corporate travel selects multiple travelers for multiroom amadeus bookings
        userSelectedTravelerIds: action.userSelectedPassengerIds,
      };

    case actionTypes.SET_USER_PASSENGERS_CALL_STATE_FAILED:
      return {
        ...state,
        userPassengerCallState: CallState.Failed,
      };

    case actionTypes.FETCH_PAYMENT_METHOD:
      return {
        ...state,
        fetchPaymentMethodCallState: CallState.InProcess,
      };

    case actionTypes.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.paymentMethod,
        selectedPaymentMethodId: action.paymentMethod?.id,
        fetchPaymentMethodCallState: CallState.Success,
      };

    case actionTypes.SET_SELECTED_PAYMENT_METHOD_ID:
      const selectedPaymentMethod = state.paymentMethods.find(
        (method) => method.id === action.paymentMethodId
      );
      switch (state.hotelBookType) {
        case HotelBookType.PRICE_FREEZE_PURCHASE:
          trackEvent({
            eventName: HOTEL_PRICE_FREEZE_SELECT_PAYMENT,
            properties: {
              success: true,
              ...getHotelPriceFreezePurchaseOfferProperties(
                action.priceFreezeOffer ?? null
              ),
            },
          });
          break;
        case HotelBookType.PRICE_FREEZE_EXERCISE:
        case HotelBookType.DEFAULT:
          trackEvent({
            eventName: SELECT_PAYMENT,
            properties: {},
          });
          break;
      }

      return {
        ...state,
        paymentMethod: selectedPaymentMethod,
        paymentMethodRewardsAccountId: action.accountId,
        selectedPaymentMethodId: action.paymentMethodId,
      };
    case actionTypes.RESET_PAYMENT_CARD_SELECTED_ACCOUNTS:
      return {
        ...state,
        paymentMethod: initialState.paymentMethod,
        paymentMethodRewardsAccountId:
          initialState.paymentMethodRewardsAccountId,
        rewardsAccountReferenceId: initialState.rewardsAccountReferenceId,
        selectedPaymentMethodId: initialState.selectedPaymentMethodId,
      };
    case actionTypes.SET_PAYMENT_METHOD_CALL_STATE_FAILED:
      return {
        ...state,
        fetchPaymentMethodCallState: CallState.Failed,
      };

    case actionTypes.LIST_PAYMENT_METHODS:
      return {
        ...state,
        listPaymentMethodCallState: CallState.InProcess,
      };

    case actionTypes.SET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.paymentMethods,
        listPaymentMethodCallState: CallState.Success,
      };

    case actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED:
      return {
        ...state,
        listPaymentMethodCallState: CallState.Failed,
      };

    case actionTypes.DELETE_PAYMENT_METHOD:
      return {
        ...state,
        deletePaymentMethodCallState: CallState.InProcess,
      };

    case actionTypes.DELETE_PAYMENT_METHOD_CALL_STATE_SUCCESS:
      return {
        ...state,
        deletePaymentMethodCallState: CallState.Success,
      };

    case actionTypes.DELETE_PAYMENT_METHOD_CALL_STATE_FAILED:
      return {
        ...state,
        deletePaymentMethodCallState: CallState.Failed,
      };

    case actionTypes.VERIFY_PAYMENT_METHOD:
      return {
        ...state,
        verifyPaymentMethodCallState: CallState.InProcess,
      };

    case actionTypes.VERIFY_PAYMENT_METHOD_CALL_STATE_SUCCESS:
      return {
        ...state,
        verifyPaymentMethodCallState: CallState.Success,
      };

    case actionTypes.VERIFY_PAYMENT_METHOD_CALL_STATE_FAILED:
      return {
        ...state,
        verifyPaymentMethodResult: action.result,
        verifyPaymentMethodCallState: CallState.Failed,
      };

    // This action is called when we need to remove Failed state; for example,
    // when `userPassengerCallState === CallState.Failed` is used as a condition for opening
    // a failure popup, we might want to reset the callState
    case actionTypes.ACKNOWLEDGE_UPDATE_USER_PASSENGER_FAILURE:
      return {
        ...state,
        userPassengerCallState:
          state.userPassengerCallState === CallState.Failed
            ? CallState.NotCalled
            : state.userPassengerCallState,
      };

    case actionTypes.SET_SESSION:
      return {
        ...state,
        session: action.token,
      };

    case actionTypes.CLEAR_SESSION:
      return {
        ...state,
        session: undefined,
      };

    case actionTypes.ACKNOWLEDGE_PRICE_DIFFERENCE:
      return {
        ...state,
        priceDifferenceAcknowledged: true,
      };

    case actionTypes.SCHEDULE_PRICE_QUOTE:
      return {
        ...state,
        schedulePriceQuoteCallState: CallState.InProcess,
        priceDifferenceAcknowledged: false,
      };
    case actionTypes.SET_PRICE_QUOTE_REQUEST:
      return {
        ...state,
        priceQuoteRequest: action.payload,
      };

    case actionTypes.SCHEDULE_PRICE_QUOTE_CALL_STATE_SUCCESS:
      return {
        ...state,
        schedulePriceQuoteError: null,
        schedulePriceQuoteCallState: CallState.Success,
      };

    case actionTypes.SCHEDULE_PRICE_QUOTE_CALL_STATE_FAILED:
      return {
        ...state,
        schedulePriceQuoteCallState: CallState.Failed,
        schedulePriceQuoteError: action.invalid,
      };

    case actionTypes.POLL_PRICE_QUOTE:
      return {
        ...state,
        pollPriceQuoteCallState: CallState.InProcess,
      };

    case actionTypes.SET_POLL_PRICE_QUOTE_CALL_STATE_SUCCESS:
      return {
        ...state,
        pollPriceQuoteCallState: CallState.Success,
      };

    case actionTypes.SET_POLL_PRICE_QUOTE_CALL_STATE_FAILED:
      return {
        ...state,
        pollPriceQuoteCallState: CallState.Failed,
        priceQuoteErrors: action.errors,
      };

    case actionTypes.SET_PRICE_QUOTE: {
      const { props } = action;
      const purchaseQuoteStates = (() => {
        switch (props.type) {
          case "pf-purchase": {
            return {
              hotelPriceFreezePriceQuote: props.hotelPriceFreezePriceQuote,
            };
          }
          case "hotel-checkout":
          default: {
            return {
              priceQuote: props.priceQuote,
              pricingWithAncillaries: props.pricingWithAncillaries,
              hotelAncillaryQuotes: props.hotelAncillaryQuotes,
            };
          }
        }
      })();

      return {
        ...state,
        ...purchaseQuoteStates,
        priceQuoteErrors: [],
        selectedPaymentMethodId: undefined,
        rewardsAccountReferenceId: defaultRewardsAccountRefId,
        rewardsPaymentInFiatCurrency: null,
        rewardsPaymentTotal: null,
      };
    }
    case actionTypes.SCHEDULE_BOOK:
      return {
        ...state,
        scheduleBookCallState: CallState.InProcess,
        approvalRequestReason: action.approvalRequestReason,
      };

    case actionTypes.SET_SCHEDULE_BOOK_SUCCESS:
      return {
        ...state,
        scheduleBookError: null,
        scheduleBookCallState: CallState.Success,
      };

    case actionTypes.SET_SCHEDULE_BOOK_FAILED:
      return {
        ...state,
        scheduleBookError: action.invalid,
        scheduleBookCallState: CallState.Failed,
      };

    case actionTypes.POLL_CONFIRMATION_DETAILS:
      return {
        ...state,
        confirmationDetailsCallState: CallState.InProcess,
      };

    case actionTypes.POLL_CONFIRMATION_DETAILS_CALL_STATE_SUCCESS:
      return {
        ...state,
        confirmationDetailsErrors: [],
        confirmationDetailsCallState: CallState.Success,
      };

    case actionTypes.POLL_CONFIRMATION_DETAILS_CALL_STATE_FAILED:
      return {
        ...state,
        confirmationDetailsCallState: CallState.Failed,
        confirmationDetailsErrors: action.errors,
      };

    case actionTypes.SET_CONFIRMATION_DETAILS: {
      const { props } = action;
      const confirmationStates = (() => {
        switch (props.type) {
          case "pf-purchase": {
            return {
              hotelPriceFreezeConfirmationDetails:
                props.hotelPriceFreezeConfirmationDetails,
            };
          }
          case "hotel-checkout":
          default: {
            return {
              confirmationDetails: props.confirmationDetails,
            };
          }
        }
      })();

      return {
        ...state,
        ...confirmationStates,
        confirmationDetailsCallState: CallState.Success,
      };
    }
    case actionTypes.RESET_BOOK:
      return {
        ...initialState,
        userPassengers: state.userPassengers,
        userPassengerCallState: state.userPassengerCallState,
      };

    case actionTypes.SET_SELECTED_REWARDS_ACCOUNT_REFERENCE_ID:
      return {
        ...state,
        rewardsAccountReferenceId: action.rewardsAccountReferenceId,
      };

    case actionTypes.SET_SELECTED_REWARDS_PAYMENT_TOTAL:
      if (
        action.accountReferenceId === DO_NOT_APPLY_REWARDS_KEY ||
        !action.rewardsPaymentTotal ||
        !action.rewardsPaymentInFiatCurrency
      ) {
        return {
          ...state,
          rewardsPaymentInFiatCurrency: null,
          rewardsPaymentTotal: null,
        };
      }

      const tripTotal = action.tripTotal;

      // This occurs when fetching rewards fails during search, don't allow the user to accidentally use all their rewards
      if (tripTotal && !tripTotal.rewards[action.accountReferenceId]) {
        return {
          ...state,
          rewardsAccountReferenceId: DO_NOT_APPLY_REWARDS_KEY,
          rewardsPaymentInFiatCurrency: null,
          rewardsPaymentTotal: null,
        };
      }

      if (
        tripTotal &&
        tripTotal.rewards[action.accountReferenceId].value <
          action.rewardsPaymentTotal.value
      ) {
        return {
          ...state,
          rewardsPaymentInFiatCurrency: tripTotal.fiat,
          rewardsPaymentTotal: tripTotal.rewards[action.accountReferenceId],
        };
      }

      return {
        ...state,
        rewardsPaymentInFiatCurrency: action.rewardsPaymentInFiatCurrency,
        rewardsPaymentTotal: action.rewardsPaymentTotal,
      };

    case actionTypes.SET_CONTACT_INFO:
      return {
        ...state,
        confirmationEmailAddress: action.email,
        confirmationPhoneNumber: action.phoneNumber,
      };

    case actionTypes.SET_PRODUCT_EARN_VALUE:
      return {
        ...state,
        productEarnValue: action.productEarnValue,
      };

    case actionTypes.SET_ALL_EARN_VALUES:
      return {
        ...state,
        earnValuesByRewardAcctId: action.earnValuesByRewardAcctId,
      };

    case actionTypes.RESET_BOOK_ERRORS:
      return {
        ...state,
        confirmationDetailsCallState: CallState.NotCalled,
        scheduleBookCallState: CallState.NotCalled,
        verifyPaymentMethodCallState: CallState.NotCalled,
        verifyPaymentMethodResult: undefined,
        confirmationDetailsErrors: [],
        scheduleBookError: null,
        rewardsConversionFailed: false,
        ...(action.isPostQuote
          ? {
              selectedPaymentMethodId: initialState.selectedPaymentMethodId,
            }
          : {
              pollPriceQuoteCallState: CallState.NotCalled,
              priceQuoteErrors: [],
              schedulePriceQuoteCallState: CallState.NotCalled,
              schedulePriceQuoteError: null,
            }),
      };

    case actionTypes.REWARDS_CONVERSION_FAILED:
      return {
        ...state,
        rewardsConversionFailed: true,
      };
    case actionTypes.SET_OFFER_TO_APPLY:
      return {
        ...state,
        offerToApply: action.offer,
      };
    case actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS:
      return {
        ...state,
        fetchApplicableTravelWalletItemsCallState: CallState.InProcess,
      };
    case actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_SUCCESS:
      return {
        ...state,
        fetchApplicableTravelWalletItemsCallState: CallState.Success,
      };
    case actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_FAILURE:
      return {
        ...state,
        fetchApplicableTravelWalletItemsCallState: CallState.Failed,
      };
    case actionTypes.SET_TRAVEL_WALLET_OFFERS:
      return {
        ...state,
        offers: action.offers,
      };
    case actionTypes.SET_BEST_OFFER_OVERALL:
      return {
        ...state,
        bestOfferOverall: action.offer,
      };
    case actionTypes.SET_CREDIT_TO_APPLY:
      return {
        ...state,
        creditToApply: action.credit,
      };
    case actionTypes.SET_TRAVEL_WALLET_CREDIT:
      return {
        ...state,
        credit: action.credit,
      };
    case actionTypes.SET_HOTEL_BOOK_TYPE:
      return {
        ...state,
        hotelBookType: action.hotelBookType,
      };

    case actionTypes.SET_SUBMIT_FOR_APPROVAL_FAILURE:
      return {
        ...state,
        submitForApprovalError: action.invalid,
        submitForApprovalCallState: CallState.Failed,
      };
    case actionTypes.SET_SUBMIT_FOR_APPROVAL_SUCCESS:
      return {
        ...state,
        submitForApprovalCallState: CallState.Success,
        submitForApprovalError: null,
      };
    case actionTypes.FETCH_CORP_USER_PASSENGERS:
      return {
        ...state,
        userPassengerCallState: CallState.InProcess,
      };

    case actionTypes.SET_USER_SELECTED_TRAVELERS_LIST:
      return {
        ...state,
        userSelectedTravelersList: action.userSelectedTravelersList,
        userSelectedTravelerIds: action.userSelectedTravelersList.map(
          (traveler) => traveler.id
        ),
      };
    default:
      return state;
  }
}

export * from "./selectors";
