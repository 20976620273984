import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  ButtonWrap,
  Icon,
  IconName,
  MobileHotelMapTooltip,
  PremierCollectionBenefitsModal,
} from "halifax";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  IIdLodgings,
  Lodging,
  LodgingCollectionEnum,
  LodgingSelectionEnum,
  SELECTED_HOTEL_FROM_MAP,
  getLodgingTrackingProperties,
} from "redmond";
import { config } from "../../../../api/config";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  AVAILABLE,
  CONTROL,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS,
  LC_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_PREMIUM_CARDHOLDERS_VARIANTS,
  LODGING_PROMOTIONS,
  LODGING_PROMOTIONS_AVAILABLE,
  LODGING_PROMOTIONS_VARIANTS,
  PREMIER_COLLECTION_EXPERIMENT,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  useExperiments,
} from "../../../../context/experiments";
import {
  PATH_SHOP,
  PREMIER_COLLECTION_PATH_SHOP,
} from "../../../../utils/paths";
import { transformToStringifiedQuery } from "../../../shop/utils/queryStringHelpers";
import { MobileAvailabilityMapRollingGalleryConnectorProps } from "./container";
import "./styles.scss";
import * as textConstants from "./textConstants";

export interface IMobileAvailabilityMapRollingGalleryProps
  extends MobileAvailabilityMapRollingGalleryConnectorProps,
    RouteComponentProps {}

export const MobileAvailabilityMapRollingGallery = (
  props: IMobileAvailabilityMapRollingGalleryProps
) => {
  const {
    lodgings,
    lodgingIdInFocus,
    nightCount,
    selectedAccountId,
    hotelQueryParams,
    searchLocation,
    roomsCount,
    mapBounds,
    isVentureX,
    setLodgingIdInFocus,
    history,
    viewedHotelListProperties,
    isUserPrimary = false,
    largestValueAccount,
  } = props;

  const [openBenefitsModal, setOpenBenefitsModal] = React.useState(false);
  const lodgingInFocus = React.useMemo(
    () =>
      lodgings?.find(
        (lodging: Lodging) => lodging.lodging.id === lodgingIdInFocus
      ),
    [lodgingIdInFocus, lodgings]
  );

  const expState = useExperiments();

  const lodgingPromotions = getExperimentVariantCustomVariants(
    expState.experiments,
    LODGING_PROMOTIONS,
    LODGING_PROMOTIONS_VARIANTS
  );
  const isLodgingPromotionsExperiment = React.useMemo(
    () => lodgingPromotions === LODGING_PROMOTIONS_AVAILABLE,
    [lodgingPromotions]
  );

  const isPremierCollectionEnabled =
    getExperimentVariant(
      expState.experiments,
      PREMIER_COLLECTION_EXPERIMENT
    ) === AVAILABLE;

  const LCForPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const isLCForPremiumCardHoldersEnabled =
    LCForPremiumCardholderVariant !== CONTROL;

  const LCForNonPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const isLCForNonPremiumCardHoldersEnabled =
    LCForNonPremiumCardholderVariant !== CONTROL;

  const renderEachLodging = (lodging?: Lodging) => {
    if (!lodging) return null;

    const lodgingData = lodging.lodging;
    const { lodgingCollection } = lodging;

    const openShopPage = () => {
      let params;

      params = transformToStringifiedQuery({
        lodgingId: lodgingData.id,
        lodgingSelection: mapBounds
          ? {
              LodgingSelection: LodgingSelectionEnum.Location,
              descriptor: mapBounds,
            }
          : (searchLocation?.id as IIdLodgings).lodgingSelection,
        ...hotelQueryParams,
        roomsCount,
        fromHotelAvailability:
          lodgingCollection === LodgingCollectionEnum.Premier ||
          lodgingCollection === LodgingCollectionEnum.Lifestyle
            ? true
            : undefined,
      });

      trackEvent({
        eventName: SELECTED_HOTEL_FROM_MAP,
        properties: {
          ...lodging?.trackingPropertiesV2?.properties,
          ...viewedHotelListProperties.properties,
          is_preferred_cot: lodging.isPreferred,
          ...getLodgingTrackingProperties(lodging),
        },
        encryptedProperties: [
          lodging?.trackingPropertiesV2?.encryptedProperties ?? "",
          ...viewedHotelListProperties.encryptedProperties,
        ],
      });

      const path =
        lodgingCollection === LodgingCollectionEnum.Premier ||
        lodgingCollection === LodgingCollectionEnum.Lifestyle
          ? PREMIER_COLLECTION_PATH_SHOP
          : PATH_SHOP;
      history.push(`${path}${params}`, { fromPage: location.pathname });
    };

    const showPCStyling =
      isPremierCollectionEnabled &&
      lodgingCollection === LodgingCollectionEnum.Premier;
    const showLCStyling =
      (isLCForPremiumCardHoldersEnabled ||
        isLCForNonPremiumCardHoldersEnabled) &&
      lodgingCollection === LodgingCollectionEnum.Lifestyle;

    return (
      <Box
        className={clsx(
          "mobile-lodging-availability-map-rolling-gallery-wrapper",
          {
            "premier-collection": showPCStyling,
            "lifestyle-collection": showLCStyling,
          }
        )}
      >
        {showPCStyling && (
          <>
            <PremierCollectionBenefitsModal
              openBenefitsModal={openBenefitsModal}
              handleClosePCBenefitsModal={() => {
                setOpenBenefitsModal(false);
              }}
              isMobile
              variant="premier-collection"
              largestValueAccount={largestValueAccount}
            />
            <ButtonWrap
              onClick={() => setOpenBenefitsModal(true)}
              className="benefits-modal-button"
              aria-label="benefits modal button"
            >
              <Typography className="modal-button-text">
                {textConstants.PC_BENEFITS_BUTTON_TEXT}
              </Typography>
              <Icon name={IconName.InfoCircle} />
            </ButtonWrap>
            <Icon name={IconName.PremierCollectionRibbon} />
          </>
        )}
        {showLCStyling && (
          <>
            <PremierCollectionBenefitsModal
              openBenefitsModal={openBenefitsModal}
              handleClosePCBenefitsModal={() => {
                setOpenBenefitsModal(false);
              }}
              isMobile
              variant={
                isLCForPremiumCardHoldersEnabled
                  ? "lifestyle-collection-premium"
                  : "lifestyle-collection-non-premium"
              }
              isUserPrimary={isUserPrimary}
              tenant={config.TENANT}
              largestValueAccount={largestValueAccount}
            />
            <ButtonWrap
              onClick={() => setOpenBenefitsModal(true)}
              className="benefits-modal-button"
              aria-label="benefits modal button"
            >
              <Typography className="modal-button-text">
                {textConstants.LC_BENEFITS_BUTTON_TEXT}
              </Typography>
              <Icon name={IconName.InfoCircle} />
            </ButtonWrap>
            <Icon name={IconName.LifestyleCollectionRibbon} />
          </>
        )}
        <span
          className={clsx("mobile-hotel-tooltip-close-button", config.TENANT)}
          onClick={(e) => {
            e.stopPropagation();
            setLodgingIdInFocus(null);
          }}
        >
          <Icon name={IconName.CloseXCircle} />
        </span>
        <MobileHotelMapTooltip
          lodging={lodging}
          onClick={openShopPage}
          nightCount={nightCount || 0}
          rewardsKey={selectedAccountId ?? ""}
          isLodgingPromotionsExperiment={isLodgingPromotionsExperiment}
          isVentureX={isVentureX}
          roomsCount={roomsCount}
        />
      </Box>
    );
  };

  return renderEachLodging(lodgingInFocus);
};
