export const FETCH_LOCATION_CATEGORIES =
  "hotelSearch/FETCH_LOCATION_CATEGORIES";
export type FETCH_LOCATION_CATEGORIES = typeof FETCH_LOCATION_CATEGORIES;

export const SET_LOCATION_CATEGORIES = "hotelSearch/SET_LOCATION_CATEGORIES";
export type SET_LOCATION_CATEGORIES = typeof SET_LOCATION_CATEGORIES;

export const SET_LOCATION = "hotelSearch/SET_LOCATION";
export type SET_LOCATION = typeof SET_LOCATION;

export const SET_FROM_DATE = "hotelSearch/SET_FROM_DATE";
export type SET_FROM_DATE = typeof SET_FROM_DATE;

export const SET_UNTIL_DATE = "hotelSearch/SET_UNTIL_DATE";
export type SET_UNTIL_DATE = typeof SET_UNTIL_DATE;

export const SET_OCCUPANCY_COUNTS = "hotelSearch/SET_OCCUPANCY_COUNTS";
export type SET_OCCUPANCY_COUNTS = typeof SET_OCCUPANCY_COUNTS;

export const SET_LOCATION_AUTOCOMPLETE_ERROR =
  "hotelSearch/SET_LOCATION_AUTOCOMPLETE_ERROR";
export type SET_LOCATION_AUTOCOMPLETE_ERROR =
  typeof SET_LOCATION_AUTOCOMPLETE_ERROR;

export const SET_ROOMS_COUNT = "hotelSearch/SET_ROOMS_COUNT";
export type SET_ROOMS_COUNT = typeof SET_ROOMS_COUNT;

export const FETCH_CALENDAR_BUCKETS = "hotelSearch/FETCH_CALENDAR_BUCKETS";
export type FETCH_CALENDAR_BUCKETS = typeof FETCH_CALENDAR_BUCKETS;

export const SET_CALENDAR_BUCKETS = "hotelSearch/SET_CALENDAR_BUCKETS";
export type SET_CALENDAR_BUCKETS = typeof SET_CALENDAR_BUCKETS;

export const SET_CALENDAR_BUCKETS_ERROR =
  "hotelSearch/SET_CALENDAR_BUCKETS_ERROR";
export type SET_CALENDAR_BUCKETS_ERROR = typeof SET_CALENDAR_BUCKETS_ERROR;

export const SET_APPLY_USER_HOTEL_PREFERENCES =
  "hotelSearch/SET_APPLY_USER_HOTEL_PREFERENCES";
export type SET_APPLY_USER_HOTEL_PREFERENCES =
  typeof SET_APPLY_USER_HOTEL_PREFERENCES;
