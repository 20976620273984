import { GuestsSelection } from "redmond";
import { actions, actionTypes } from "../actions";
import { isCorpTenant } from "@capone/common";
import { config } from "../../../api/config";
import { IHotelSearchState } from "./state";

export const DEFAULT_ADULT_COUNT = 2;
export const CORP_DEFAULT_ADULT_COUNT = 1;

const initialState: IHotelSearchState = {
  location: null,
  locationCategories: [],
  locationCategoriesLoading: false,
  fromDate: null,
  untilDate: null,
  adultsCount: isCorpTenant(config.TENANT)
    ? CORP_DEFAULT_ADULT_COUNT
    : DEFAULT_ADULT_COUNT,
  children: [],
  hasLocationAutocompleteError: false,
  roomsCount: 1,
  fetchCalendarPriceBucketsLoading: false,
  hasCalendarPriceBucketsError: false,
  calendarPriceBuckets: [],
  calendarPriceMonths: [],
  applyUserHotelPreferences: false,
};

export function reducer(
  state: IHotelSearchState = initialState,
  action: actions.HotelSearchActions
): IHotelSearchState {
  switch (action.type) {
    case actionTypes.SET_LOCATION:
      const { location } = action;
      return {
        ...state,
        location: location ? { ...location } : null,
        locationCategoriesLoading: false,
        hasLocationAutocompleteError: false,
      };

    case actionTypes.SET_LOCATION_CATEGORIES:
      const { categories } = action;
      return {
        ...state,
        locationCategories: categories,
        locationCategoriesLoading: false,
      };

    case actionTypes.FETCH_LOCATION_CATEGORIES:
      return {
        ...state,
        locationCategories: [],
        locationCategoriesLoading: true,
      };

    case actionTypes.SET_FROM_DATE:
      return { ...state, fromDate: action.date };

    case actionTypes.SET_UNTIL_DATE:
      return {
        ...state,
        untilDate: action.date,
      };

    case actionTypes.SET_OCCUPANCY_COUNTS:
      const { adults, children } = action.counts as GuestsSelection;
      return {
        ...state,
        adultsCount: adults,
        children: children,
      };
    case actionTypes.SET_LOCATION_AUTOCOMPLETE_ERROR: {
      return {
        ...state,
        hasLocationAutocompleteError: action.error,
        locationCategoriesLoading: false,
      };
    }
    case actionTypes.SET_ROOMS_COUNT: {
      return {
        ...state,
        roomsCount: action.rooms,
      };
    }
    case actionTypes.FETCH_CALENDAR_BUCKETS:
      return {
        ...state,
        fetchCalendarPriceBucketsLoading: true,
      };

    case actionTypes.SET_CALENDAR_BUCKETS:
      return {
        ...state,
        fetchCalendarPriceBucketsLoading: false,
        calendarPriceBuckets: action.buckets,
        calendarPriceMonths: action.monthBuckets,
      };

    case actionTypes.SET_CALENDAR_BUCKETS_ERROR:
      return {
        ...state,
        fetchCalendarPriceBucketsLoading: false,
        hasCalendarPriceBucketsError: action.error,
        calendarPriceBuckets: [],
        calendarPriceMonths: [],
      };

    case actionTypes.SET_APPLY_USER_HOTEL_PREFERENCES:
      return { ...state, applyUserHotelPreferences: action.applyPreferences };

    default:
      return state;
  }
}

export * from "./selectors";
