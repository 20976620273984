import {
  getAgentErrorSubtitle,
  getAgentErrorTitle,
  getRewardsString,
  getTotalPriceText,
  twoDecimalFormatter,
} from "halifax";
import {
  ErrorTitles,
  RewardsPrice,
  FiatPrice,
  HotelBookType,
  ErrorModalType,
  CloseButtonActionEnum,
} from "redmond";

const GENERIC_ERROR_TITLE = "Uh oh! Something went wrong";
const GENERIC_ERROR_SUBTITLE = "Please search again";

// CHECKOUT COMPLETION TEXT
export const ADD_TRAVELERS = "Add Travelers";
export const TRAVELER_INFORMATION = "Traveler Information";
export const CONTACT = "Contact Information";
export const REWARDS_AND_PAYMENT = "Rewards & payment";
export const REWARDS_AND_PAYMENT_SHORTENED = "Payment";

export enum FreezeCheckoutFlowSteps {
  REVIEW_HOTEL_INFORMATION = "Review hotel information",
  CONTACT_INFORMATION = "Contact information",
  REWARDS_AND_PAYMENT = "Rewards & payment",
}

// BUTTON_TEXT
export const CONTINUE = "Continue";
export const TRY_AGAIN = "Try again";
export const SEARCH_AGAIN = "Search again";
export const CHOOSE_ANOTHER_HOTEL = "Choose another hotel";
export const CHANGE_HOTEL = "Change hotel";
export const CHOOSE_ANOTHER_RATE = "Choose another rate";
export const CONFIRM_AND_BOOK = "Confirm and book";
export const UPDATE_PAYMENT_INFO = "Update payment";
export const EDIT_PAYMENT_INFO = "Edit payment information";
export const MODIFY_TRAVELERS = "Modify travelers";
export const GET_A_REFUND = "Get a refund";
export const CONTACT_SUPPORT = "Contact support";

// Icon types
export const ERROR_ICON = "error";
export const UNABLED_ICON = "unabled";
export const PRICE_DECREASE_ICON = "decreased";
export const PRICE_INCREASE_ICON = "increased";

export const GENERIC_ERROR_TITLES = (
  hotelBookType: HotelBookType
): ErrorTitles => ({
  title: GENERIC_ERROR_TITLE,
  subtitle: GENERIC_ERROR_SUBTITLE,
  primaryButtonText: (() => {
    switch (hotelBookType) {
      case HotelBookType.PRICE_FREEZE_EXERCISE:
        return TRY_AGAIN;
      case HotelBookType.DEFAULT:
      default:
        return SEARCH_AGAIN;
    }
  })(),
  secondaryButtonText: (() => {
    switch (hotelBookType) {
      case HotelBookType.PRICE_FREEZE_EXERCISE:
        return CONTACT_SUPPORT;
      case HotelBookType.DEFAULT:
      default:
        return CHOOSE_ANOTHER_HOTEL;
    }
  })(),
  type:
    hotelBookType === HotelBookType.PRICE_FREEZE_EXERCISE
      ? ErrorModalType.HOTEL_PRICE_FREEZE_EXERCISE_GENERIC_FAILED
      : undefined,
});

export const USER_CARD_ERROR_TITLES: ErrorTitles = {
  icon: ERROR_ICON,
  title: "We were unable to process your payment method.",
  subtitle:
    "Choose another payment method, or delete your original payment method and add it again. If the problem continues, please contact Customer Support at 844-422-6922 for further assistance.",
  primaryButtonText: CONTINUE,
};

export const INACTIVITY_TITLES: ErrorTitles = {
  title: "Oh no. Your booking has timed out.",
  subtitle:
    "It looks like you've been away for a little while. Please re-select your details and try again.",
  primaryButtonText: CONTINUE,
  icon: ERROR_ICON,
};

export const PAYMENT_ERROR_TITLES: ErrorTitles = {
  title: "Oh no - we couldn't confirm your booking",
  subtitle: "Please try again or select a new payment method.",
  secondaryButtonText: "Edit payment method",
  primaryButtonText: TRY_AGAIN,
  icon: ERROR_ICON,
};

export const PAYMENT_ERROR_TITLES_VARIANT_2: ErrorTitles = {
  title: "We were unable to process your booking",
  subtitle:
    "Ensure that all payment information was entered correctly and try again.",
  secondaryButtonText: "Edit payment method",
  primaryButtonText: TRY_AGAIN,
  icon: ERROR_ICON,
};

export const LACK_OF_INVENTORY_TITLES: ErrorTitles = {
  title: "Oh no - this price is no longer available",
  subtitle:
    "Prices can change frequently: please try again in a few hours or try another hotel.",
  secondaryButtonText: CHOOSE_ANOTHER_HOTEL,
  primaryButtonText: TRY_AGAIN,
  icon: ERROR_ICON,
};

export const RATE_NOT_AVAILABLE_TITLES: ErrorTitles = {
  title: "Oh no - this rate is no longer available",
  subtitle: "Please select another rate to continue your stay.",
  secondaryButtonText: CHOOSE_ANOTHER_HOTEL,
  primaryButtonText: CHOOSE_ANOTHER_RATE,
  icon: ERROR_ICON,
};

export const REDEMPTION_FAILED_TITLES: ErrorTitles = {
  title: "Rewards Redemption Failure",
  subtitle:
    "We were unable to redeem your rewards at this time. Please try again, and if the issue continues to persist, please try again later.",
  primaryButtonText: TRY_AGAIN,
  icon: UNABLED_ICON,
};

export const FRAUD_TITLES = (hotelBookType: HotelBookType): ErrorTitles => ({
  icon: UNABLED_ICON,
  title: GENERIC_ERROR_TITLE,
  subtitle: `There is an issue with you account. Please contact Capital One Support.`,
  primaryButtonText: (() => {
    switch (hotelBookType) {
      case HotelBookType.PRICE_FREEZE_EXERCISE:
        return CONTACT_SUPPORT;
      case HotelBookType.DEFAULT:
      default:
        return SEARCH_AGAIN;
    }
  })(),
  type:
    hotelBookType === HotelBookType.PRICE_FREEZE_EXERCISE
      ? ErrorModalType.HOTEL_PRICE_FREEZE_EXERCISE_LIKELY_FRAUD
      : undefined,
});

export const CHECK_IN_AGE_NOT_MET = (
  hotelBookType: HotelBookType
): ErrorTitles => ({
  title: "Check-In Minimum Age Not Met",
  subtitle: `The selected traveler does not meet the hotel’s minimum age requirements for check-in.`,
  primaryButtonText: MODIFY_TRAVELERS,
  secondaryButtonText:
    hotelBookType === HotelBookType.DEFAULT ? CHOOSE_ANOTHER_HOTEL : undefined,
  icon: ERROR_ICON,
});

export const REFUND_HOTEL_PRICE_FREEZE_TITLES = (
  refundAmount: FiatPrice | undefined
): ErrorTitles => ({
  title: "This room is not available anymore",
  subtitle: refundAmount
    ? `We’ll refund your ${getTotalPriceText({
        price: refundAmount,
        priceFormatter: twoDecimalFormatter,
      })} payment to your original payment method.`
    : "",
  primaryButtonText: GET_A_REFUND,
  icon: UNABLED_ICON,
  type: ErrorModalType.HOTEL_PRICE_FREEZE_EXERCISE_REFUND,
  showCloseButton: true,
  closeButtonAction: CloseButtonActionEnum.GoToHotelPriceFreezeOverview,
});

export const PAYMENT_METHOD_ERROR_TITLES = (error: string): ErrorTitles => {
  switch (error) {
    case "InvalidCVV":
      return {
        agentSubtitle: getAgentErrorSubtitle(error),
        agentTitle: getAgentErrorTitle(error),
        icon: ERROR_ICON,
        title: "CVV Error",
        subtitle:
          "Ensure your CVV / Security Code is entered correctly and try again.",
        primaryButtonText: EDIT_PAYMENT_INFO,
      };
    case "InvalidAddress":
      return {
        agentSubtitle: getAgentErrorSubtitle(error),
        agentTitle: getAgentErrorTitle(error),
        icon: ERROR_ICON,
        title: "Address Verification Error",
        subtitle:
          "Ensure your Zip Code and Country is entered correctly and try again.",
        primaryButtonText: EDIT_PAYMENT_INFO,
      };
    default:
    case "GenericError":
      return {
        agentSubtitle: getAgentErrorSubtitle(error),
        agentTitle: getAgentErrorTitle(error),
        icon: ERROR_ICON,
        title: "Error Adding Payment Method",
        subtitle:
          "We were unable to add your payment method. Please ensure all of your information is correct and try again.",
        primaryButtonText: EDIT_PAYMENT_INFO,
      };
  }
};

// Pricing

export const ROOM_TOTAL = ({
  nights,
  roomsCount,
  hotelBookType,
}: {
  nights: number | null;
  roomsCount: number;
  hotelBookType: HotelBookType;
}) => {
  const roomsText =
    roomsCount && roomsCount <= 1 ? "Room" : `${roomsCount} Rooms`;
  const nightText = nights && nights <= 1 ? "night" : "nights";

  switch (hotelBookType) {
    case HotelBookType.PRICE_FREEZE_PURCHASE:
    case HotelBookType.PRICE_FREEZE_EXERCISE:
      return `${roomsCount} ${
        roomsCount > 1 ? "rooms" : `room`
      } (${nights} ${nightText})`;
    case HotelBookType.DEFAULT:
    default:
      return `${roomsText} x ${nights} ${nightText}`;
  }
};

export const TOTAL_WITH_PER_NIGHT_FIAT = ({
  nights,
  total,
  perNight,
}: {
  nights: number | null;
  total: FiatPrice;
  perNight?: FiatPrice;
}) =>
  `${getTotalPriceText({
    price: total,
    priceFormatter: twoDecimalFormatter,
  })}${
    perNight && nights && nights > 1
      ? ` (${getTotalPriceText({
          price: perNight,
        })} per night)`
      : ""
  }`;

export const PRICE_FREEZE_DEPOSIT_APPLIED = `Price freeze payment applied:`;
export const TAXES_AND_FEES = "Hotel taxes & fees";
export const TAXES_AND_FEES_TOOLTIP = `Hotel taxes and fees are calculated at checkout based on your booking selections. They’re paid to your hotel to comply with their policies and local taxes. These can include sales and use tax, occupancy tax, room tax, value added tax, and/or other similar taxes.`;
export const CANCEL_FOR_ANY_REASON = "Cancel for any reason";
export const DUE_TODAY = "Due Today";
export const ADDITIONAL_FEES = "Hotel service Fee (Due at check-in)";
export const ADDITIONAL_FEES_TOOLTIP = `Hotel service fees are charged by the hotel. Capital One Travel does not have control over the amount of additional service fees that are due at check-in. Additional service fees vary by hotel and location.`;
export const TOTAL = "Total";
export const REWARDS = "Rewards applied:";
export const REWARDS_BALANCE_REMAINING = "Rewards balance remaining:";
export const AMOUNT_DUE = "Amount due:";
export const TRAVEL_OFFER_APPLIED = "Travel offer applied:";
export const TRAVEL_CREDITS_APPLIED = "Travel credits applied:";
export const TOTAL_TRAVEL_CREDITS_APPLIED = "Total travel credits applied:";

export const EARN_STRING = (amount: number, currency?: string) =>
  `You’ll earn <strong>${amount.toLocaleString("en-US")} ${
    currency || "Miles"
  }</strong> on this trip!`;

export const EARNED_STRING = (rewards: RewardsPrice) =>
  `You've earned <strong>${getRewardsString(rewards)}</strong> on this trip!`;

export const getPriceDifferenceTitle = (isIncrease: boolean, amount: string) =>
  `${!isIncrease ? `You're in luck! ` : ``}The price has ${
    isIncrease ? "increased" : "decreased"
  } by $${amount}.`;
export const PRICE_DECREASE_SUBTITLE =
  "Sometimes hotels change the prices unexpectedly. You can review the new trip total on the final screen before checkout.";
export const PRICE_INCREASE_SUBTITLE =
  "Sometimes hotels change the prices unexpectedly. Sorry about that! You can review the new trip total on the final screen before checkout.";
export const PRICE_INCREASE_WITH_ADD_ON_SUBTITLE = `<p>Sometimes hotels change the prices unexpectedly. Sorry about that! You can review the new trip total in checkout.</p>
<p><strong>Please review the cost of add-ons on checkout. The cost of add-ons may have changed due to the ticket price increase.</strong></p>`;
export const PRICE_INCREASE_OFFER_COVERAGE_TITLE =
  "We’ve detected a price increase. Your Travel Offer no longer covers the total cost of your booking.";
export const PRICE_INCREASE_CREDITS_COVERAGE_TITLE =
  "We’ve detected a price increase. Your travel credits no longer cover the total cost of your booking.";
export const PRICE_INCREASE_WALLET_COVERAGE_SUBTITLE =
  "Please add or select a payment method to complete the booking.";
