import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import {
  hotelShopProgressSelector,
  getHotelShopChosenProduct,
  getHotelShopSelectedLodging,
  isAddOnOptionAvailableSelector,
  isOptionSelectionCompleteSelector,
} from "../../reducer";
import {
  getAdultsCount,
  getChildren,
  getFromDate,
  getRoomsCount,
  getUntilDate,
} from "../../../search/reducer";
import { setHotelShopProgress } from "../../actions/actions";
import { HotelShopProgressBar } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { hotelBookTypeSelector } from "../../../book/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    hotelShopStep: hotelShopProgressSelector(state),
    chosenProduct: getHotelShopChosenProduct(state),
    selectedLodging: getHotelShopSelectedLodging(state),
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    adultsCount: getAdultsCount(state),
    children: getChildren(state),
    roomsCount: getRoomsCount(state),
    isAddOnOptionAvailable: isAddOnOptionAvailableSelector(state),
    isOptionSelectionComplete: isOptionSelectionCompleteSelector(state),
    hotelBookType: hotelBookTypeSelector(state),
  };
};

const mapDispatchToProps = {
  setHotelShopProgress,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HotelShopProgressBarConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedHotelShopProgressBar = connector(
  withRouter(HotelShopProgressBar)
);
