import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { ActionLink } from "halifax";
import React from "react";
import { RouteComponentProps } from "react-router";

import { HotelBookType } from "redmond";
import {
  PATH_ANCILLARY_CUSTOMIZE,
  PATH_AVAILABILITY,
  PATH_BOOK,
  PATH_FREEZE,
  PATH_SHOP,
} from "../../../../utils/paths";
import { goToPriceFreezeOverviewPage } from "../../../freeze/utils/queryStringHelpers";
import { HotelShopStep } from "../../reducer/state";
import {
  goToAncillaryCustomize,
  goToAvailability,
  goToShop,
} from "../../utils/queryStringHelpers";
import * as constants from "./constants";
import { HotelShopProgressBarConnectorProps } from "./container";
import "./styles.scss";

interface IHotelShopProgressBarProps
  extends HotelShopProgressBarConnectorProps,
    RouteComponentProps {
  useCustomizeStep?: boolean;
  usePriceFreezeStep?: boolean;
}

enum UpdatedHotelShopStep {
  AVAILABILITY_STEP = "availability-step",
  CUSTOMIZE_STEP = "customize-step",
  BOOK_STEP = "book-step",
  PRICE_FREEZE_STEP = "price-freeze-step",
  REVIEW_PRICE_FREEZE_STEP = "review-price-freeze-step",
}

type HotelShopStepV2 = HotelShopStep | UpdatedHotelShopStep;

enum CompareStepsResult {
  Before,
  Same,
  After,
  Unknown,
}

export const HotelShopProgressBar = (props: IHotelShopProgressBarProps) => {
  const {
    hotelShopStep,
    chosenProduct,
    selectedLodging,
    fromDate,
    untilDate,
    adultsCount,
    children,
    roomsCount,
    isAddOnOptionAvailable,
    isOptionSelectionComplete,
    setHotelShopProgress,
    useCustomizeStep,
    usePriceFreezeStep,
    history,
    hotelBookType,
  } = props;

  const hasSelectedHotelProduct = !!chosenProduct && !!selectedLodging;

  const ProgressButtons = ({
    progresses,
  }: {
    progresses: HotelShopStepV2[];
  }) => {
    const selectedStep: HotelShopStepV2 = (() => {
      switch (history.location.pathname) {
        case PATH_AVAILABILITY:
          return UpdatedHotelShopStep.AVAILABILITY_STEP;

        case PATH_BOOK:
          return UpdatedHotelShopStep.BOOK_STEP;

        case PATH_ANCILLARY_CUSTOMIZE:
          return UpdatedHotelShopStep.CUSTOMIZE_STEP;

        case PATH_FREEZE:
          return UpdatedHotelShopStep.PRICE_FREEZE_STEP;

        case PATH_SHOP:
        default:
          return hotelShopStep;
      }
    })();

    return (
      <>
        {progresses.map((progress: HotelShopStepV2, index: number) => {
          return (
            <React.Fragment key={index}>
              <HotelShopProgressButton
                key={`button-${index}`}
                hasSelectedHotelProduct={hasSelectedHotelProduct}
                selectedStep={selectedStep}
                progress={progress}
                isAddOnOptionAvailable={isAddOnOptionAvailable}
                isOptionSelectionComplete={isOptionSelectionComplete}
                setHotelShopProgress={setHotelShopProgress}
                goToAvailability={() => {
                  goToAvailability({
                    history,
                    lodging: selectedLodging,
                    fromDate,
                    untilDate,
                    adultsCount,
                    children,
                    roomsCount,
                  });
                }}
                goToCustomize={() => {
                  goToAncillaryCustomize({ history });
                }}
                goToHotelShopStep={() => {
                  goToShop({ history });
                }}
                goToPriceFreezeOverview={() => {
                  goToPriceFreezeOverviewPage({ history });
                }}
                hotelBookType={hotelBookType}
              />
              {index < progresses.length - 1 && (
                <FontAwesomeIcon
                  key={`separator-${index}`}
                  className="progress-button-separator"
                  icon={faChevronRight}
                />
              )}
            </React.Fragment>
          );
        })}
      </>
    );
  };

  const getProgresses = (): HotelShopStepV2[] => {
    if (hotelBookType == HotelBookType.PRICE_FREEZE_EXERCISE) {
      return [
        UpdatedHotelShopStep.REVIEW_PRICE_FREEZE_STEP,
        UpdatedHotelShopStep.BOOK_STEP,
      ];
    }

    const progresses: HotelShopStepV2[] = [
      UpdatedHotelShopStep.AVAILABILITY_STEP,

      // note: HotelShopStep.HotelInfo and HotelShopStep.ChooseRoom share the same page
      HotelShopStep.HotelInfo,
      ...(useCustomizeStep ? [UpdatedHotelShopStep.CUSTOMIZE_STEP] : []),
      ...(usePriceFreezeStep
        ? [UpdatedHotelShopStep.PRICE_FREEZE_STEP]
        : [UpdatedHotelShopStep.BOOK_STEP]),
    ];

    return progresses;
  };

  return (
    <Box className="hotel-shop-progress-bar-root">
      <Box className="hotel-shop-progress-bar-container">
        <ProgressButtons progresses={getProgresses()} />
      </Box>
    </Box>
  );
};

interface IHotelShopProgressButtonProps {
  // isAddOnOptionAvailable: boolean;
  // isOptionSelectionComplete: boolean;
  hasSelectedHotelProduct: boolean;
  selectedStep: HotelShopStepV2;
  progress: HotelShopStepV2;
  isAddOnOptionAvailable: boolean;
  isOptionSelectionComplete: boolean;
  setHotelShopProgress: (progress: HotelShopStep) => void;
  goToHotelShopStep?: (progress: HotelShopStep) => void;
  goToAvailability?: () => void;
  goToCustomize?: () => void;
  goToCheckout?: () => void;
  goToPriceFreezeOverview?: () => void;
  hotelBookType?: HotelBookType;
}

const HotelShopProgressButton = (props: IHotelShopProgressButtonProps) => {
  const {
    // isAddOnOptionAvailable,
    // isOptionSelectionComplete,
    hasSelectedHotelProduct,
    selectedStep,
    progress,
    isAddOnOptionAvailable,
    isOptionSelectionComplete,
    setHotelShopProgress,
    goToHotelShopStep,
    goToAvailability,
    goToCustomize,
    goToCheckout,
    goToPriceFreezeOverview,
    hotelBookType,
  } = props;

  const getProgressText = (progress: HotelShopStepV2) => {
    switch (progress) {
      case UpdatedHotelShopStep.AVAILABILITY_STEP:
        return constants.AVAILABILITY_TEXT;

      case HotelShopStep.HotelInfo:
      case HotelShopStep.ChooseRoom:
        return constants.SHOP_TEXT;

      case UpdatedHotelShopStep.CUSTOMIZE_STEP:
        return constants.CUSTOMIZE_TEXT;

      case UpdatedHotelShopStep.BOOK_STEP:
        return constants.BOOK_TEXT;

      case UpdatedHotelShopStep.PRICE_FREEZE_STEP:
        return constants.PRICE_FREEZE_TEXT;

      case UpdatedHotelShopStep.REVIEW_PRICE_FREEZE_STEP:
        return constants.REVIEW_TEXT;

      default:
        return "";
    }
  };

  const compareSteps = (
    step1: HotelShopStepV2,
    step2: HotelShopStepV2
  ): CompareStepsResult => {
    // Only steps in the normal shop flow are added.
    const stepOrdering: HotelShopStepV2[] = [
      UpdatedHotelShopStep.AVAILABILITY_STEP,
      HotelShopStep.HotelInfo,
      HotelShopStep.ChooseRoom,
      UpdatedHotelShopStep.CUSTOMIZE_STEP,
      UpdatedHotelShopStep.BOOK_STEP,
    ];

    const index1 = stepOrdering.findIndex((s) => s === step1);
    const index2 = stepOrdering.findIndex((s) => s === step2);

    if (index1 === -1 || index2 === -1) {
      return CompareStepsResult.Unknown;
    } else if (index1 === index2) {
      return CompareStepsResult.Same;
    } else if (index1 < index2) {
      return CompareStepsResult.Before;
    } else {
      return CompareStepsResult.After;
    }
  };

  const isDisabled = (
    progress: HotelShopStepV2,
    selectedStep: HotelShopStepV2
  ) => {
    if (compareSteps(selectedStep, progress) === CompareStepsResult.Before) {
      return true;
    }

    switch (progress) {
      case UpdatedHotelShopStep.AVAILABILITY_STEP:
      case HotelShopStep.HotelInfo:
      case HotelShopStep.ChooseRoom:
        return false;

      case UpdatedHotelShopStep.CUSTOMIZE_STEP:
        return !hasSelectedHotelProduct || !isAddOnOptionAvailable;

      case UpdatedHotelShopStep.BOOK_STEP:
        return (
          !(hotelBookType == HotelBookType.PRICE_FREEZE_EXERCISE) &&
          (!hasSelectedHotelProduct ||
            (isAddOnOptionAvailable && !isOptionSelectionComplete))
        );

      case UpdatedHotelShopStep.REVIEW_PRICE_FREEZE_STEP:
        return false;

      case UpdatedHotelShopStep.PRICE_FREEZE_STEP:
        return false;

      default: {
        return true;
      }
    }
  };

  const handleOnClick = () => {
    // note: it prevents the breadscrumb for the selected step from being clicked
    if (compareSteps(selectedStep, progress) === CompareStepsResult.Same) {
      return;
    }

    switch (progress) {
      case UpdatedHotelShopStep.AVAILABILITY_STEP:
        if (goToAvailability) goToAvailability();
        break;

      case UpdatedHotelShopStep.CUSTOMIZE_STEP:
        if (goToCustomize) goToCustomize();
        break;
      case UpdatedHotelShopStep.REVIEW_PRICE_FREEZE_STEP:
        if (goToPriceFreezeOverview) goToPriceFreezeOverview();
        break;
      /*
        note: given the `isDisabled` logic, the final step (book) is never going to be enabled unless the user
        is on the checkout page; therefore, there is no point in passing in goToCheckout since it wouldn't get called
      */
      case UpdatedHotelShopStep.BOOK_STEP:
        if (goToCheckout) goToCheckout();
        break;

      // Similar to the Book step above, PRICE_FREEZE_STEP is the last step in the Price Freeze purchase flow,
      // so there is nowhere to link to. In the previous pages, this button is not shown.
      case UpdatedHotelShopStep.PRICE_FREEZE_STEP:
        break;

      case HotelShopStep.HotelInfo:
      case HotelShopStep.ChooseRoom:
      default: {
        setHotelShopProgress(progress);
        if (goToHotelShopStep) goToHotelShopStep(progress);
        break;
      }
    }
  };

  return (
    <ActionLink
      className={clsx(
        "hotel-shop-progress-button",
        { selected: selectedStep === progress },
        "b2b"
      )}
      onClick={handleOnClick}
      content={getProgressText(progress)}
      disabled={isDisabled(progress, selectedStep)}
    />
  );
};
