import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import {
  CorpLodging,
  CorpPriceQuote,
  CorpRoomInfoProducts,
  CorpRoomProduct,
} from "redmond";

import { IStoreState } from "../../../../../reducers/types";
import {
  setSubmitForApproval,
  setSubmitForApprovalFailure,
  setUserSelectedTravelersList,
  updateUserPassenger,
} from "../../../actions/actions";
import { CorpMobileHotelBookWorkflow } from "./component";
import {
  getOpaquePayments,
  getPriceQuoteRequest,
  getPriceQuote,
  getHotelBookChosenRoomInfo,
  getHotelBookChosenProduct,
  getHotelBookSelectedAvailability,
  getUserSelectedTravelersFromIds,
} from "../../../reducer";
import {
  getHotelQueryParams,
  getSelectedLodgingIndex,
} from "../../../../availability/reducer";
import {
  mapStateToProps as baseMapStateToProps,
  mapDispatchToProps as baseMapDispatchToProps,
} from "../../capone/MobileHotelBookWorkflow/container";
import { getHotelShopCancellationSummary } from "../../../../shop/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    ...baseMapStateToProps(state),
    priceQuote: getPriceQuote(state) as CorpPriceQuote,
    roomInfoProduct: getHotelBookChosenRoomInfo(state) as CorpRoomInfoProducts,
    chosenProduct: getHotelBookChosenProduct(state) as CorpRoomProduct,
    selectedLodging: getHotelBookSelectedAvailability(state) as CorpLodging,
    hotelQueryParams: getHotelQueryParams(state),
    selectedLodgingIndex: getSelectedLodgingIndex(state),
    opaquePayments: getOpaquePayments(state),
    priceQuoteRequest: getPriceQuoteRequest(state),
    selectedTravelersList: getUserSelectedTravelersFromIds(state),
    cancellationSummary: getHotelShopCancellationSummary(state),
  };
};

const mapDispatchToProps = {
  ...baseMapDispatchToProps,
  setSubmitForApproval,
  setSubmitForApprovalFailure,
  updateUserPassenger,
  setUserSelectedTravelersList,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CorpMobileHotelBookWorkflowConnectorProps = ConnectedProps<
  typeof connector
>;

export const CorpConnectedMobileHotelBookWorkflow = withRouter(
  connector(CorpMobileHotelBookWorkflow)
);
