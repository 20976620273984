import { isCorpTenant, PATH_CORP_FAQ } from "@capone/common";
import { config } from "../api/config";

export const BASE_PATH_HOME = "/";

export const PATH_TRIPS = `${BASE_PATH_HOME}trips/`;
export const PATH_FROZEN_PRICES_LIST = `${PATH_TRIPS}?tripsFilter=Frozen%20Prices`;
export const PATH_HOTELS_PRICE_FREEZE_OVERVIEW = `${PATH_TRIPS}hotels/freeze/overview/`;

export const PATH_TERMS = `${BASE_PATH_HOME}terms/`;
export const PATH_TERMS_HOTEL_CFAR = `${PATH_TERMS}hotel-cancel-for-any-reason/`;
export const PATH_TERMS_HOTEL_PRICE_DROP = `${PATH_TERMS}hotel-price-drop-protection/`;
export const PATH_BEST_PRICE_GUARANTEE = `${PATH_TERMS}best-price-guarantee`;

export const PATH_HOME = "/hotels/";
export const PATH_HOME_SEARCH = PATH_HOME + "search/";
export const PATH_AVAILABILITY = PATH_HOME + "availability/";
export const PATH_SHOP = PATH_HOME + "shop/";
export const PATH_ANCILLARY = PATH_HOME + "ancillary/";
export const PATH_ANCILLARY_CUSTOMIZE = PATH_ANCILLARY + "customize/";
export const PATH_BOOK = PATH_HOME + "book/";
export const PATH_BOOK_CONFIRMATION = PATH_BOOK + "confirm/";
export const PATH_FREEZE = PATH_HOME + "freeze/";
export const PATH_FREEZE_CONFIRMATION = PATH_FREEZE + "confirm/";

export const PREMIER_COLLECTION_PATH_SHOP = "/premium-stays/shop/";
export const PATH_HOTEL_PRICE_FREEZE_TERMS = `${PATH_TERMS}frozen-prices-hotel/`;

export const PATH_TRAVEL_SALE = `/travelsale/`;

export const PATH_CUSTOMER_PROFILE = `/profile/`;

// Corporate
export const CONTACT_SUPPORT_URL = isCorpTenant(config.TENANT)
  ? PATH_CORP_FAQ
  : "https://www.capitalone.com/help-center/capital-one-travel/";

export const PATH_PRICE_MATCH_GUARANTEE = `${PATH_TERMS}price-match-guarantee/`;
