import React from "react";
import {
  CloseButtonIcon,
  Header,
  ActionLink,
  UserPreferencesBanner,
} from "halifax";
import { Box, Typography } from "@material-ui/core";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RouteComponentProps } from "react-router";
import clsx from "clsx";
import { MobileHotelSearchV3ConnectorProps } from "./container";
import "./styles.scss";
import { LocationSearch, MobileCalendarPicker } from "./components";
import { PATH_CUSTOMER_PROFILE, PATH_HOME } from "../../../../utils/paths";
import * as textConstants from "./textConstants";
import { OccupancySelection } from "../MobileHotelSearchControl/components";
import { CallState, RecentHotelSearch } from "redmond";
import {
  HOTEL_PREFERENCES_INFO_PROFILE_CTA_TEXT,
  HOTEL_PREFERENCES_WITH_SAVED_PROFILE_CTA_TEXT,
} from "../HotelSearchControl/textConstants";
import { Link } from "react-router-dom";

enum MobileHotelSearchStep {
  LocationSearch,
  CalendarPicker,
  Complete,
}

export interface IMobileHotelSearchControlV3Props
  extends MobileHotelSearchV3ConnectorProps,
    RouteComponentProps {
  recentSearches?: RecentHotelSearch[];
  onRecentSearchClick?: (search: RecentHotelSearch) => void;
  onSelectLocation?: (value: any) => void;
  onSelectDates?: (from: Date | null, until: Date | null) => void;
  isCustomerProfileExperiment?: boolean;
  showCalendarPricingColors?: boolean;
  showCalendarPricing?: boolean;
}

export const MobileHotelSearchControlV3 = (
  props: IMobileHotelSearchControlV3Props
) => {
  const {
    history,
    hasLocationAutocompleteError,
    setLocationAutocompleteError,
    onSelectLocation,
    onSelectDates,
    fetchCalendarBuckets,
    userHasSetHotelPreferences,
    userHotelPreferencesCallState,
    isCustomerProfileExperiment,
    shouldApplyUserHotelPreferences,
    setApplyUserHotelPreferences,
    hasLocation,
    showCalendarPricingColors,
    showCalendarPricing,
  } = props;

  const [currentStep, setCurrentStep] = React.useState<MobileHotelSearchStep>(
    MobileHotelSearchStep.LocationSearch
  );

  const showUserHotelPreferencesBanner =
    isCustomerProfileExperiment &&
    userHasSetHotelPreferences &&
    userHotelPreferencesCallState === CallState.Success;

  const handleGoBack = () => {
    setCurrentStep((step) => {
      return step > MobileHotelSearchStep.LocationSearch ? step - 1 : step;
    });
  };

  React.useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, [currentStep]);

  return (
    <Box
      className={clsx("mobile-hotel-search-v3", {
        "date-range-picker":
          currentStep === MobileHotelSearchStep.CalendarPicker,
        "has-location": hasLocation,
      })}
    >
      <Header
        className="homepage"
        center={<HeaderCenterSection currentStep={currentStep} />}
        left={
          <ActionLink
            className={clsx("mobile-hotel-search-header-go-back", {
              hidden: currentStep === MobileHotelSearchStep.LocationSearch,
            })}
            onClick={handleGoBack}
            content={<FontAwesomeIcon icon={faChevronLeft} />}
          />
        }
        right={
          <ActionLink
            className="mobile-hotel-search-header-close"
            onClick={() => {
              history.push(PATH_HOME);
              hasLocationAutocompleteError &&
                setLocationAutocompleteError(false);
            }}
            content={<CloseButtonIcon />}
            label="Close"
          />
        }
        isMobile={true}
        fullWidth={true}
      />
      {currentStep === MobileHotelSearchStep.LocationSearch && (
        <>
          <OccupancySelection showTotalTravelers autoFocus={false} />
          <LocationSearch
            onComplete={(value) => {
              setCurrentStep(MobileHotelSearchStep.CalendarPicker);
              onSelectLocation?.(value);
              if (showCalendarPricingColors) {
                fetchCalendarBuckets();
              }
            }}
            history={history}
            recentSearches={props.recentSearches}
            onRecentSearchClick={props.onRecentSearchClick}
          />
          {showUserHotelPreferencesBanner && (
            <UserPreferencesBanner
              isMobile
              type="hotel"
              shouldApplyUserPreferences={shouldApplyUserHotelPreferences}
              setShouldApplyUserPreferences={setApplyUserHotelPreferences}
              userHasSetPreferences={userHasSetHotelPreferences}
              userPreferencesCallState={userHotelPreferencesCallState}
              bannerProfileCTA={
                <Link
                  to={`${PATH_CUSTOMER_PROFILE}?section=hotel-preferences`}
                  className="profile-cta"
                >
                  {userHasSetHotelPreferences
                    ? HOTEL_PREFERENCES_WITH_SAVED_PROFILE_CTA_TEXT
                    : HOTEL_PREFERENCES_INFO_PROFILE_CTA_TEXT}
                </Link>
              }
            />
          )}
        </>
      )}
      {currentStep === MobileHotelSearchStep.CalendarPicker && (
        <MobileCalendarPicker
          onComplete={onSelectDates}
          showPricing={showCalendarPricing}
        />
      )}
    </Box>
  );
};

interface IHeaderCenterSectionProps {
  currentStep: number;
}

const HeaderCenterSection = ({ currentStep }: IHeaderCenterSectionProps) => {
  const headerText =
    currentStep === MobileHotelSearchStep.CalendarPicker
      ? textConstants.UPDATE_CALENDAR_TEXT
      : textConstants.HOTELS_HEADER;
  return (
    <Box className="header-center-section">
      {headerText === textConstants.UPDATE_CALENDAR_TEXT ? (
        <Box className="calendar-header">
          <Typography variant="body1">{headerText}</Typography>
        </Box>
      ) : (
        <Box className="mobile-hotel-search-location-label">
          <Typography variant="body1">{headerText}</Typography>
        </Box>
      )}
    </Box>
  );
};
