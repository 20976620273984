import { put, select } from "redux-saga/effects";
import { actions } from "../../actions";
import {
  ADD_HOTEL_LOYALTY,
  ConnectionResultEnum,
  InvalidEnum,
  PaymentSessionToken,
} from "redmond";
import { getPurchaseRequestMetaProps, getPurchaseApis } from "../../utils";
import {
  getOpaquePayments,
  getSession,
  hotelBookTypeSelector,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";

export function* scheduleBookSaga({
  agentFee,
  isRecommended,
  ancillaries,
  loyaltyNumber,
}: actions.ISetScheduleBook) {
  try {
    const state: IStoreState = yield select();
    const hotelBookType = hotelBookTypeSelector(state);
    const { version } = getPurchaseRequestMetaProps({ hotelBookType });
    const { schedulePayment } = getPurchaseApis(version);
    const session = getSession(state);
    if (!session) {
      throw Error("Session is undefined");
    }

    const payments = getOpaquePayments(state);
    if (!payments) {
      throw new Error("Invalid Request.");
    }

    const corporateOutOfPolicyReason = state.hotelBook.approvalRequestReason;

    const result: string = yield schedulePayment({
      token: session,
      payments,
      ancillaries,
      corporateOutOfPolicyReason,
    });

    if (loyaltyNumber) {
      trackEvent({
        eventName: ADD_HOTEL_LOYALTY,
        properties: {},
      });
    }

    // update the session to the fulfill session token
    yield put(actions.setSession({ value: result } as PaymentSessionToken));
    yield put(
      actions.pollConfirmationDetails(
        session!,
        agentFee,
        isRecommended,
        //TODO(replat): these don't do anything yet - pending BE work to support fulfilling trip requests
        loyaltyNumber
      )
    );
    yield put(actions.setScheduleBookSuccess());
  } catch (e) {
    yield put(
      actions.setScheduleBookFailed({
        Invalid: InvalidEnum.Missing,
        ConnectionResult: ConnectionResultEnum.Invalid,
      })
    );
  }
}
