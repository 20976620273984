import { isCorpTenant } from "@capone/common";
import { Tenant } from "redmond";
import {
  CORP_MAXIMUM_ROOMS_COUNT,
  MAXIMUM_ROOMS_COUNT,
} from "../../../../textConstants";

export const INPUT_LABEL = (
  adults: number,
  children: number[],
  rooms: number,
  showRoomCountsOnly?: boolean,
  showTotalTravelers?: boolean
) => {
  const adultsCount = adults === 1 ? `${adults} Adult` : `${adults} Adults`;
  const childrenCount =
    children.length === 1
      ? `${children.length} Child`
      : `${children.length} Children`;
  const travelersCount = `${children.length + adults} Traveler${
    children.length + adults > 1 ? "s" : ""
  }`;
  const roomCount = rooms === 1 ? `${rooms} Room` : `${rooms} Rooms`;
  if (showRoomCountsOnly) {
    return `<span class="rooms-count"> ${roomCount}</span>`;
  }
  if (showTotalTravelers)
    return `<span class="travelers-count">${travelersCount},</span><span class="rooms-count">${roomCount}</span>`;
  return `<span class="adults-count">${adultsCount}</span><span class="children-count">${childrenCount}</span><span class="rooms-count"> ${roomCount}</span>`;
};

export const MODAL_TITLES = {
  modalTitle: "Edit Travelers",
  adultTitle: "Adults",
  adultSubtitle: "",
  childrenTitle: "Children",
  chilrenSubtitle: "",
};

export const MODAL_TITLES_WITH_ROOMS = (
  tenant: Tenant,
  isMultiroomAmadeusEnabled: boolean
) => ({
  modalTitle: isCorpTenant(tenant)
    ? "Edit travelers and rooms"
    : "Edit Travelers",
  adultTitle: "Adults",
  adultSubtitle: "",
  childrenTitle: "Children",
  chilrenSubtitle: "Under 18",
  roomsTitle: "Rooms",
  roomsSubtitle: isMultiroomAmadeusEnabled
    ? `Max ${CORP_MAXIMUM_ROOMS_COUNT}`
    : `Max ${MAXIMUM_ROOMS_COUNT}`,
});
