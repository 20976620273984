import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import {
  BackButton,
  MobilePopoverCard,
  MobileFloatingButton,
  EmailInputField,
} from "halifax";
import { IPerson } from "redmond";
import { Divider, Typography } from "@material-ui/core";
import { getHotelLoyaltyProgram, getUpdatedTraveler } from "@capone/common";
import { useExperimentsById } from "@capone/experiments";
import { LoyaltyInformationForm } from "../LoyaltyInformationForm";
import { getHotelShopChosenProductHotelLoyaltyProgramCode } from "../../../../shop/reducer";
import { getLoyaltyNumberForChosenProduct } from "../LoyaltyInformationForm/component";
import "./styles.scss";

export interface IAdditionalInfoWorkflowProps {
  onGoBack: () => void;
  open: boolean;
  onClickContinue: (traveler: IPerson) => void;
  mobileHeaderElement?: JSX.Element;
  className?: string;
  traveler: IPerson | undefined;
  isLoyaltyEligible: boolean;
}

export const AdditionalInfoWorkflow = ({
  onGoBack,
  open,
  onClickContinue,
  mobileHeaderElement,
  className,
  traveler,
  isLoyaltyEligible,
}: IAdditionalInfoWorkflowProps): React.ReactElement => {
  const [loyaltyNumber, setLoyaltyNumber] = useState("");
  const [contactEmail, setContactEmail] = useState<string>(
    () => traveler?.emailAddress ?? ""
  );

  const isMultiroomAmadeus =
    useExperimentsById("corp-amadeus-multiroom")?.variant === "available";

  const loyaltyProgramCode = useSelector(
    getHotelShopChosenProductHotelLoyaltyProgramCode
  );
  const selectedTravelerLoyaltyNumber = getLoyaltyNumberForChosenProduct(
    loyaltyProgramCode,
    traveler
  );

  const loyaltyProgram = getHotelLoyaltyProgram(loyaltyProgramCode);

  useEffect(() => {
    setLoyaltyNumber(selectedTravelerLoyaltyNumber || "");
  }, [selectedTravelerLoyaltyNumber, traveler?.id]);

  useEffect(() => {
    setContactEmail(traveler?.emailAddress ?? "");
  }, [traveler?.emailAddress]);

  const renderBackButton = () => (
    <BackButton className="additional-info-back-button" onClick={onGoBack} />
  );

  const handleUpdateAdditionalInfo = (
    traveler: IPerson,
    newLoyaltyNumber?: string,
    newEmail?: string
  ) => {
    const updatedTraveler = getUpdatedTraveler(
      traveler,
      loyaltyProgram,
      newLoyaltyNumber,
      newEmail
    );

    onClickContinue(updatedTraveler);
  };

  return (
    <>
      <MobilePopoverCard
        open={open}
        className={clsx("b2b additional-info-workflow-root mobile", className)}
        fullScreen
        topLeftButton={renderBackButton()}
        headerElement={mobileHeaderElement}
      >
        <Typography component="h1" className="additional-info-title">
          {`${traveler?.givenName} ${traveler?.surname}`}
        </Typography>
        {isLoyaltyEligible && (
          <>
            <Divider />
            <LoyaltyInformationForm
              isMobile
              onChange={setLoyaltyNumber}
              traveler={traveler}
            />
          </>
        )}
        {isMultiroomAmadeus && (
          <>
            <Divider style={{ margin: 0 }} />
            <div className="form-container">
              <Typography className="title" variant="h6">
                Contact information
              </Typography>

              <Typography className="subtitle" variant="body2">
                A copy of the hotel confirmation email will be sent to this
                traveler.
              </Typography>
              <EmailInputField
                fullWidth
                onChange={(value) => {
                  setContactEmail(value);
                }}
                email={contactEmail}
              />
            </div>
          </>
        )}

        <MobileFloatingButton
          secondary={!loyaltyNumber}
          className="additional-info-workflow-continue-button"
          wrapperClassName="additional-info-workflow-continue-button-container"
          onClick={() => {
            traveler &&
              handleUpdateAdditionalInfo(traveler, loyaltyNumber, contactEmail);
          }}
        >
          Continue
        </MobileFloatingButton>
      </MobilePopoverCard>
    </>
  );
};
