import { isCorpTenant } from "@capone/common";
import { useExperimentsById } from "@capone/experiments";
import { Box } from "@material-ui/core";
import {
  B2BButton,
  DesktopPopupModal,
  Icon,
  IconName,
  PassengerCountPicker,
  PassengerCountPickerType,
} from "halifax";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { GuestsSelection } from "redmond";

import { config } from "../../../../../../api/config";
import {
  CORP_MAXIMUM_ROOMS_COUNT,
  CORP_MAXIMUM_TRAVELERS_COUNT,
  MAXIMUM_ROOMS_COUNT,
  MAXIMUM_TRAVELERS_COUNT,
} from "../../../../textConstants";
import { OccupancySelectionConnectorProps } from "./container";
import "./styles.scss";
import * as textConstants from "./textConstants";

export interface IOccupancySelectionProps
  extends OccupancySelectionConnectorProps,
    RouteComponentProps {
  showTotalTravelers?: boolean;
}
export const OccupancySelection = (props: IOccupancySelectionProps) => {
  const {
    adultsCount,
    children,
    roomsCount,
    setOccupancyCounts,
    setRoomsCount,
    showTotalTravelers,
  } = props;

  const isMultiroomAmadeusEnabled =
    isCorpTenant(config.TENANT) &&
    useExperimentsById("corp-amadeus-multiroom")?.variant === "available";

  const [openPassengerCountPicker, setOpenPassengerCountPicker] =
    React.useState(false);

  return (
    <Box className="desktop-hotel-occupancy-selection">
      <B2BButton
        aria-label={`Guests ${textConstants.INPUT_LABEL(
          adultsCount,
          children
        )}`}
        className="num-travelers-input b2b"
        variant="traveler-selector"
        onClick={() => setOpenPassengerCountPicker(true)}
      >
        <Box className="num-traveler-content">
          <Box className="num-traveler-with-icon">
            <Icon
              aria-hidden={true}
              className="icon-start"
              name={IconName.B2BUser}
              ariaLabel=""
            />
            <Box
              className="text"
              dangerouslySetInnerHTML={{
                __html: textConstants.INPUT_LABEL_WITH_ROOMS(
                  adultsCount,
                  children,
                  roomsCount,
                  showTotalTravelers
                ),
              }}
            />
          </Box>
          <Icon
            aria-hidden={true}
            className="icon-end"
            name={IconName.B2BEditPencil}
            ariaLabel=""
          />
        </Box>
      </B2BButton>
      {openPassengerCountPicker && (
        <DesktopPopupModal
          open={openPassengerCountPicker}
          aria-labelledby="passenger-count-picker"
          className="desktop-passenger-count-picker-popup"
          contentClassName="desktop-passenger-count-picker-popup-container"
          onClose={() => setOpenPassengerCountPicker(false)}
          invisibleBackdrop={false}
          headerElement={textConstants.MODAL_TITLES.modalTitle}
        >
          <PassengerCountPicker
            titles={textConstants.MODAL_TITLES_WITH_ROOMS(
              config.TENANT,
              isMultiroomAmadeusEnabled
            )}
            setPassengerCounts={(counts: PassengerCountPickerType) => {
              const { adults, children, rooms } = counts as GuestsSelection;
              setOccupancyCounts({ adults, children });
              setRoomsCount(rooms);
            }}
            onClickApply={() => setOpenPassengerCountPicker(false)}
            counts={{
              adults: adultsCount,
              children: children,
              rooms: roomsCount,
            }}
            className="b2b"
            showChildAgeInputs
            maximumRoomsCount={
              isMultiroomAmadeusEnabled
                ? CORP_MAXIMUM_ROOMS_COUNT
                : MAXIMUM_ROOMS_COUNT
            }
            maximumTravelersCount={
              isMultiroomAmadeusEnabled
                ? CORP_MAXIMUM_TRAVELERS_COUNT
                : MAXIMUM_TRAVELERS_COUNT
            }
          />
        </DesktopPopupModal>
      )}
    </Box>
  );
};
