import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import { Box, Typography } from "@material-ui/core";
import {
  IconName,
  ActionButton,
  GenericAddOnItem as AddOnItem,
  ErrorOutsideComponentType,
  getTotalPriceText,
  getRewardsString,
  twoDecimalFormatter,
} from "halifax";
import {
  FiatPrice,
  RewardsPrice,
  CFAR_HEADER,
  CAP1_HOTEL_CFAR_ADD_ON_CHOICE,
  getHotelCfarAddOnChoiceHotelCfarOfferFacts,
} from "redmond";
import clsx from "clsx";
import { PriceBreakdown } from "../../../../book/components";
import { goToCheckout } from "../../../../shop/utils/queryStringHelpers";
import { trackEvent } from "../../../../../api/v0/analytics/trackEvent";
import { DO_NOT_APPLY_OPTION_KEY } from "../../../reducer";
import { CfarDetails, CfarOption } from "../index";
import { AddOnCustomizeConnectorProps } from "./container";
import * as constants from "./constants";
import "./styles.scss";

export interface IAddOnCustomizeProps
  extends AddOnCustomizeConnectorProps,
    RouteComponentProps {
  // note: this callback function is used to overwrite the default (successful) on-continue behaviour
  onContinueSuccess?: () => void;
  hidePricingBreakdown?: boolean;
  disableCollapse?: boolean;
}

enum AncillaryType {
  Cfar,
}

export const AddOnCustomize = (props: IAddOnCustomizeProps) => {
  const {
    history,
    isCfarAvailable,
    selectedCfarId,
    cfarQuote,
    cancellationPolicy,
    additionalInfo,
    isOptionSelectionComplete,
    fetchCfarQuotesCallState,
    rewardsKey,
    hotelShopChosenProduct,
    refundableRoomProductCorrespondingToChosenRoom,
    hidePricingBreakdown,
    disableCollapse,
    onContinueSuccess = () => {
      goToCheckout({ history });
    },
    isHotelCfarRefundDisplayShowAmountAdditionalPlaces,
  } = props;
  const [isCfarCollapsed, setIsCfarCollapsed] = useState<boolean>(false);
  const [cfarError, setCfarError] = useState<ErrorOutsideComponentType>();
  const cfarOfferPrices = cfarQuote?.premiumPrices;
  const headerStyle = "default";

  const handleOnContinue = () => {
    if (isOptionSelectionComplete) {
      onContinueSuccess();
    } else {
      if (!selectedCfarId) {
        setCfarError(ErrorOutsideComponentType.NoOptionSelected);
      }
      // other ancillary errors
    }
  };

  const getFormattedTotalPriceText = (fiat: FiatPrice | undefined) => {
    if (fiat) {
      return getTotalPriceText({
        price: fiat,
        priceFormatter: twoDecimalFormatter,
      });
    }
    return "";
  };

  const getFormattedRewardsText = (
    priceFreezeRewards: { [key: string]: RewardsPrice } | undefined | null,
    selectedRewardsAccountId: string | null | undefined
  ) => {
    if (priceFreezeRewards && selectedRewardsAccountId) {
      const rewards = priceFreezeRewards[selectedRewardsAccountId];
      return getRewardsString(rewards);
    }
    return "";
  };

  const isAncillaryDisplayed = (type: AncillaryType): boolean => {
    switch (type) {
      case AncillaryType.Cfar:
        return isCfarAvailable;
      default:
        return false;
    }
  };

  const renderCfarAddOnItem = () => {
    const Header = () => (
      <Typography
        variant="inherit"
        dangerouslySetInnerHTML={{
          __html: CFAR_HEADER({ textVersion: "short" }),
        }}
      />
    );

    if (!cfarQuote) {
      return null;
    }

    return (
      <AddOnItem
        key="cfar"
        type="cancel-for-any-reason"
        header={<Header />}
        headerStyle={headerStyle}
        subtitle={constants.CFAR_SUBTITLE_COPY}
        iconName={IconName.CheckShieldNoCircle}
        content={
          <CfarDetails
            contentOnly={true}
            cardContentProps={{
              hideTopContent: true,
              hideSubtitle: true,
              hideContinueCta: true,
              disablePartialScroll: true,
              readTermsAndConditionsLinkPosition: "left",
              onSelectValueCallback: (option: CfarOption) => {
                trackEvent({
                  eventName: CAP1_HOTEL_CFAR_ADD_ON_CHOICE,
                  properties: {
                    ...getHotelCfarAddOnChoiceHotelCfarOfferFacts({
                      roomProduct: hotelShopChosenProduct,
                      hotelCfarQuote: cfarQuote,
                      additionalInfo,
                      cfar_choice: option === CfarOption.Yes ? 1 : 0,
                      cfar_rr_hotel_choice:
                        refundableRoomProductCorrespondingToChosenRoom ===
                        undefined
                          ? "none"
                          : 0,
                    }),
                  },
                });
                setCfarError(undefined);
              },
              onConfirmSelectedValueCallback: () => {
                setTimeout(() => {
                  setIsCfarCollapsed(true);
                }, 800);
              },
              error: cfarError,
            }}
            disabled={false}
            currentHotelCfarQuote={cfarQuote}
            currentCancellationPolicy={cancellationPolicy}
            currentAdditionalInfo={additionalInfo}
            showCoverageAmount={
              isHotelCfarRefundDisplayShowAmountAdditionalPlaces
            }
          />
        }
        fetchAncillaryOfferCallState={fetchCfarQuotesCallState}
        error={cfarError}
        isCollapsed={isCfarCollapsed}
        setIsCollapsed={setIsCfarCollapsed}
        hasSelectedOption={!!selectedCfarId}
        priceCopy={
          selectedCfarId?.value !== DO_NOT_APPLY_OPTION_KEY && cfarOfferPrices
            ? constants.getPriceAndRewardsCopy({
                price: getFormattedTotalPriceText(cfarOfferPrices.fiat),
                rewards: getFormattedRewardsText(
                  cfarOfferPrices.rewards,
                  rewardsKey
                ),
              })
            : undefined
        }
        disableCollapse={disableCollapse}
        disabled={false}
      />
    );
  };

  const availableAncillariesList: AncillaryType[] = [
    isCfarAvailable ? [AncillaryType.Cfar] : [],
  ].flat();
  const addOnItems: (JSX.Element | null)[] = availableAncillariesList.map(
    (ancillaryType) => {
      if (!isAncillaryDisplayed(ancillaryType)) {
        return null;
      }

      switch (ancillaryType) {
        case AncillaryType.Cfar:
          return renderCfarAddOnItem();
        default:
          return null;
      }
    }
  );

  return (
    <Box className="hotel-add-on-customize-root">
      <Box className={clsx("hotel-add-on-customize-container")}>
        <Box className="add-on-items-section">{addOnItems}</Box>
        {!hidePricingBreakdown && (
          <Box className="checkout-breakdown-section">
            <Typography className="header-copy" variant="h2">
              {constants.CHECKOUT_BREAKDOWN}
            </Typography>
            <Box className="price-breakdown-wrapper">
              <PriceBreakdown variant="customize" />
            </Box>
            <ActionButton
              className="continue-button"
              message={constants.CONTINUE_TO_CHECKOUT_COPY}
              onClick={handleOnContinue}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
