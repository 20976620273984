import { isCorpTenant } from "@capone/common";
import { Tenant } from "redmond";
import {
  MAXIMUM_ROOMS_COUNT,
  CORP_MAXIMUM_ROOMS_COUNT,
} from "../../../../textConstants";

export const INPUT_LABEL = (adults: number, children: number[]) => {
  const numTravelers = adults + children.length;
  return numTravelers === 1
    ? `${numTravelers} Traveler`
    : `${numTravelers} Travelers`;
};

export const INPUT_LABEL_WITH_ROOMS = (
  adults: number,
  children: number[],
  rooms: number,
  showTotalTravelers?: boolean
) => {
  const numTravelers = adults + children.length;
  const adultsCount = adults === 1 ? `${adults} Adult` : `${adults} Adults`;
  const childrenCount =
    children.length === 1
      ? `${children.length} Child`
      : `${children.length} Children`;
  const roomCount = rooms === 1 ? `${rooms} Room` : `${rooms} Rooms`;
  if (showTotalTravelers) {
    return numTravelers === 1
      ? `<span class="adults-count">${numTravelers} Traveler,  ${roomCount}</span>`
      : `<span class="adults-count">${numTravelers} Travelers,  ${roomCount}</span>`;
  }
  return `<span class="adults-count">${adultsCount}</span><span class="children-count">${childrenCount}</span><span class="rooms-count"> ${roomCount}</span>`;
};

export const MODAL_TITLES = {
  modalTitle: "Edit Travelers",
  adultTitle: "Adults",
  adultSubtitle: "",
  childrenTitle: "Children",
  chilrenSubtitle: "",
  childAgeInputSubtitle:
    "To ensure the most accurate rates, please enter the exact age of the children on this booking.",
};

export const MODAL_TITLES_WITH_ROOMS = (
  tenant: Tenant,
  isMultiroomAmadeusEnabled: boolean
) => ({
  modalTitle: isCorpTenant(tenant)
    ? "Edit travelers and rooms"
    : "Edit Travelers",
  adultTitle: "Adults",
  adultSubtitle: "",
  childrenTitle: "Children",
  chilrenSubtitle: "Under 18",
  roomsTitle: "Rooms",
  roomsSubtitle: isMultiroomAmadeusEnabled
    ? `Max ${CORP_MAXIMUM_ROOMS_COUNT}`
    : `Max ${MAXIMUM_ROOMS_COUNT}`,
  childAgeInputSubtitle:
    "To ensure the most accurate rates, please enter the exact age of the children on this booking.",
});
