import {
  IResult,
  ICategorizedResponse,
  GuestsSelection,
  CalendarPriceBucket,
  IMonthBucket,
} from "redmond";

import * as actionTypes from "./constants";

export interface IFetchLocationCategories {
  type: actionTypes.FETCH_LOCATION_CATEGORIES;
  queryString: string;
}

export const fetchLocationCategories = (
  queryString: string
): IFetchLocationCategories => ({
  type: actionTypes.FETCH_LOCATION_CATEGORIES,
  queryString,
});

export interface ISetLocationCategories {
  type: actionTypes.SET_LOCATION_CATEGORIES;
  categories: ICategorizedResponse[];
}

export const setLocationCategories = (
  categories: ICategorizedResponse[]
): ISetLocationCategories => ({
  type: actionTypes.SET_LOCATION_CATEGORIES,
  categories,
});

export interface ISetLocation {
  type: actionTypes.SET_LOCATION;
  location: IResult | null;
}

export const setLocation = (location: IResult | null): ISetLocation => ({
  type: actionTypes.SET_LOCATION,
  location,
});

export interface ISetFromDate {
  type: actionTypes.SET_FROM_DATE;
  date: Date | null;
}

export const setFromDate = (date: Date | null): ISetFromDate => ({
  type: actionTypes.SET_FROM_DATE,
  date,
});

export interface ISetUntilDate {
  type: actionTypes.SET_UNTIL_DATE;
  date: Date | null;
}

export const setUntilDate = (date: Date | null): ISetUntilDate => ({
  type: actionTypes.SET_UNTIL_DATE,
  date,
});

export interface ISetOccupancyCounts {
  type: actionTypes.SET_OCCUPANCY_COUNTS;
  counts: Omit<GuestsSelection, "rooms">;
}

export const setOccupancyCounts = (
  counts: Omit<GuestsSelection, "rooms">
): ISetOccupancyCounts => ({
  type: actionTypes.SET_OCCUPANCY_COUNTS,
  counts,
});

export interface ISetLocationAutocompleteError {
  type: actionTypes.SET_LOCATION_AUTOCOMPLETE_ERROR;
  error: boolean;
}

export const setLocationAutocompleteError = (
  error: boolean
): ISetLocationAutocompleteError => ({
  type: actionTypes.SET_LOCATION_AUTOCOMPLETE_ERROR,
  error,
});

export interface ISetRoomsCount {
  type: actionTypes.SET_ROOMS_COUNT;
  rooms: number;
}

export const setRoomsCount = (rooms: number): ISetRoomsCount => ({
  type: actionTypes.SET_ROOMS_COUNT,
  rooms,
});

export interface IFetchCalendarBuckets {
  type: actionTypes.FETCH_CALENDAR_BUCKETS;
}

export const fetchCalendarBuckets = (): IFetchCalendarBuckets => ({
  type: actionTypes.FETCH_CALENDAR_BUCKETS,
});

export interface ISetCalendarBuckets {
  type: actionTypes.SET_CALENDAR_BUCKETS;
  buckets: CalendarPriceBucket[];
  monthBuckets: IMonthBucket[];
}

export const setCalendarBuckets = (
  buckets: CalendarPriceBucket[],
  monthBuckets: IMonthBucket[]
): ISetCalendarBuckets => ({
  type: actionTypes.SET_CALENDAR_BUCKETS,
  buckets,
  monthBuckets,
});

export interface ISetCalendarBucketsError {
  type: actionTypes.SET_CALENDAR_BUCKETS_ERROR;
  error: boolean;
}

export const setCalendarBucketsError = (
  error: boolean
): ISetCalendarBucketsError => ({
  type: actionTypes.SET_CALENDAR_BUCKETS_ERROR,
  error,
});

export interface ISetApplyUserHotelPreferences {
  type: actionTypes.SET_APPLY_USER_HOTEL_PREFERENCES;
  applyPreferences: boolean;
}

export const setApplyUserHotelPreferences = (
  applyPreferences: boolean
): ISetApplyUserHotelPreferences => ({
  type: actionTypes.SET_APPLY_USER_HOTEL_PREFERENCES,
  applyPreferences,
});

export type HotelSearchActions =
  | IFetchLocationCategories
  | ISetLocationCategories
  | ISetLocation
  | ISetFromDate
  | ISetUntilDate
  | ISetOccupancyCounts
  | ISetLocationAutocompleteError
  | ISetRoomsCount
  | IFetchCalendarBuckets
  | ISetCalendarBuckets
  | ISetCalendarBucketsError
  | ISetApplyUserHotelPreferences;
