import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import {
  getAdultsCount,
  getChildren,
  getFromDate,
  getRoomsCount,
  getUntilDate,
} from "../../../search/reducer";
import { fetchHotelShop, setHotelShopProgress } from "../../actions/actions";
import { IStoreState } from "../../../../reducers/types";
import { MobileShopHeader } from "./component";
import {
  getHotelShopSelectedLodging,
  hotelShopProgressSelector,
} from "../../reducer/selectors";
import { actions } from "../../../search/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    adultsCount: getAdultsCount(state),
    children: getChildren(state),
    selectedLodging: getHotelShopSelectedLodging(state),
    hotelShopStep: hotelShopProgressSelector(state),
    roomsCount: getRoomsCount(state),
  };
};

const mapDispatchToProps = {
  fetchHotelShop,
  setFromDate: actions.setFromDate,
  setUntilDate: actions.setUntilDate,
  setHotelShopProgress,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileShopHeaderConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMobileShopHeader = withRouter(
  connector(MobileShopHeader)
);
