import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Tooltip, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { AMADEUS } from "@capone/common";
import { CallState, IPerson, ITravelerStepErrors } from "redmond";
import { Icon, IconName, TravelerSelectStep } from "halifax";
import { useExperimentsById } from "@capone/experiments";
import { HotelTravelerSelectDropdownFormConnectorProps } from "./container";
import { TravelerSelectDropdown } from "./components/TravelerSelectDropdown";
import {
  MULTIROOM_TOOLTIP_TEXT,
  MULTIROOM_TOOLTIP_TITLE,
  TRAVELER_SELECT_DROPDOWN_DESCRIPTION,
} from "./constants";
import "./styles.scss";

export type SelectedTravelers = IPerson[];

export interface HotelTravelerSelectDropdownFormProps
  extends HotelTravelerSelectDropdownFormConnectorProps {
  disableEditTravelerInfo: boolean;
  selectedTravelers: SelectedTravelers;
  updateSelectedTravelersList: (traveler: IPerson) => void;
  removeSelectedTraveler: (traveler: IPerson) => void;
  setProgress: (progress: TravelerSelectStep) => void;
  showErrors: ITravelerStepErrors;
  setShowErrors: (showErrors: ITravelerStepErrors) => void;
  isLoyaltyEligible: boolean;
}

export const HotelTravelerSelectDropdownForm = ({
  fetchUserPassengers,
  roomsCount,
  travelers,
  disableEditTravelerInfo,
  selectedTravelers,
  updateSelectedTravelersList,
  updateUserPassenger,
  setProgress,
  removeSelectedTraveler,
  showErrors,
  setShowErrors,
  isLoyaltyEligible,
  chosenProduct,
  loyaltyProgram,
  userPassengerCallState,
  fetchCorpUserPassengers,
}: HotelTravelerSelectDropdownFormProps) => {
  const [dropdownCount, setDropdownCount] = useState(
    selectedTravelers.length || 1
  );

  const streamlineTravelerProfileEnabled =
    useExperimentsById("corp-streamline-traveler-profile-fe")?.variant === "m2";

  useEffect(() => {
    streamlineTravelerProfileEnabled
      ? fetchCorpUserPassengers()
      : fetchUserPassengers();
  }, []);

  const isAmadeusProvider = chosenProduct?.providerName === AMADEUS;

  return (
    <div className="traveler-select-dropdown-container">
      <div className="form-container">
        <Typography variant="h3" className="title">
          Select or add travelers
        </Typography>
        <Typography variant="body2" component="p" color="textPrimary">
          {TRAVELER_SELECT_DROPDOWN_DESCRIPTION(
            roomsCount,
            isAmadeusProvider,
            true
          )}
        </Typography>
        {[...Array(dropdownCount)].map((_, index) => {
          return (
            <>
              {index > 0 && <Divider style={{ margin: "20px 0" }} />}
              <TravelerSelectDropdown
                travelers={travelers}
                disableEditTravelerInfo={disableEditTravelerInfo}
                selectedTravelers={selectedTravelers}
                updateSelectedTravelersList={updateSelectedTravelersList}
                removeSelectedTraveler={(traveler) => {
                  removeSelectedTraveler(traveler);
                  setDropdownCount((prevCount) =>
                    prevCount === 1 ? 1 : prevCount - 1
                  );
                }}
                setProgress={setProgress}
                index={index}
                updateUserPassenger={updateUserPassenger}
                showErrors={showErrors}
                setShowErrors={setShowErrors}
                isLoyaltyEligible={isLoyaltyEligible}
                loyaltyProgram={loyaltyProgram}
                loading={userPassengerCallState === CallState.InProcess}
              />
            </>
          );
        })}
        {isAmadeusProvider && roomsCount > 1 && dropdownCount < roomsCount && (
          <Button
            variant="outlined"
            color="secondary"
            className="add-traveler-button"
            startIcon={<Add />}
            disabled={
              dropdownCount > Object.keys(selectedTravelers).length ||
              disableEditTravelerInfo
            }
            onClick={() => setDropdownCount((prevCount) => prevCount + 1)}
          >
            Add additional traveler
          </Button>
        )}
        {!isAmadeusProvider && roomsCount > 1 && (
          <Tooltip
            title={
              <Typography variant="subtitle2">
                {MULTIROOM_TOOLTIP_TEXT}
              </Typography>
            }
            placement="top"
            tabIndex={0}
          >
            <Box
              display="flex"
              className="multiroom-tooltip"
              alignItems="center"
              gridGap="6px"
            >
              <Typography variant="subtitle2">
                {MULTIROOM_TOOLTIP_TITLE}
              </Typography>
              <Icon name={IconName.InfoCircle} />
            </Box>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
