import { useExperimentIsVariant } from "@capone/experiments";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import {
  PolicyDetailsModal,
  PolicyModalButton,
  UserPreferencesAppliedBanner,
} from "halifax";
import React, { useContext } from "react";
import {
  CLEAR_ALL_FILTERS,
  IIdLodgings,
  ModalScreens,
  POLICY_MODAL,
  VIEWED_POLICY_MODAL,
} from "redmond";
import { ClientContext } from "../../../../App";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  CONTROL,
  CUSTOMER_PROFILE_EXPERIMENT,
  CUSTOMER_PROFILE_VARIANTS,
  addTrackingProperties,
  getExperimentVariantCustomVariants,
  useExperiments,
} from "../../../../context/experiments";
import { PATH_AVAILABILITY } from "../../../../utils/paths";
import { HotelSearchControl } from "../../../search/components/HotelSearchControl";
import { HotelSearchButton } from "../../../search/components/HotelSearchControl/components/SearchButton";
import { transformToStringifiedAvailabilityQuery } from "../../../shop/utils/queryStringHelpers";
import { AvailabilityFilter } from "../AvailabilityFilter";
import { AvailabilitySearchControlConnectorProps } from "./container";
import "./styles.scss";
import * as textConstants from "./textConstants";

interface IAvailabilitySearchControlProps
  extends AvailabilitySearchControlConnectorProps {
  displaySearchOnChange?: boolean;
}
export const AvailabilitySearchControl = (
  props: IAvailabilitySearchControlProps
) => {
  const {
    fetchInitialHotelAvailability,
    location,
    fromDate,
    untilDate,
    adultsCount,
    children,
    displaySearchOnChange = true,
    mapBounds,
    setMapBound,
    setViewHotelsNearLocation,
    areRequestParametersChanged,
    resetFilters,
    roomsCount,
    shouldApplyUserHotelPreferences,
  } = props;

  const expState = useExperiments();

  const isPolicyDescriptorsEnabled = useExperimentIsVariant(
    "corp-admin-policy-descriptors",
    "available"
  );

  const isCustomerProfileExperiment =
    getExperimentVariantCustomVariants(
      expState.experiments,
      CUSTOMER_PROFILE_EXPERIMENT,
      CUSTOMER_PROFILE_VARIANTS
    ) !== CONTROL;

  const [isPolicyModalOpen, setIsPolicyModalOpen] = React.useState(false);

  const { policies } = useContext(ClientContext);

  const onShowPolicyDetailsModal = () => {
    setIsPolicyModalOpen(true);
    trackEvent({
      eventName: VIEWED_POLICY_MODAL,
      properties: {
        type: POLICY_MODAL,
        entry_point: ModalScreens.HOTELS_AVAILABILITY,
        funnel: "hotels",
      },
    });
  };

  return (
    <Box className="hotel-availability-search-container">
      <Box
        className={clsx("hotel-availability-search-control", {
          "show-policy-button": isPolicyDescriptorsEnabled,
        })}
      >
        <Box className="search-components-section">
          <HotelSearchControl
            onSearch={fetchInitialHotelAvailability}
            displaySearchOnChange={true}
            showTravelerSelection={true}
            showSearchButton={false}
            hideCalendarSeparator={true}
            showTotalTravelers={true}
          />
        </Box>
        <Box className="sort-filter-section">
          {(!displaySearchOnChange || areRequestParametersChanged) && (
            <HotelSearchButton
              className={clsx("hotel-search-control-button", "b2b")}
              message={textConstants.SEARCH_AGAIN}
              onClick={(history) => {
                history.push(
                  `${PATH_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
                    (location?.id as IIdLodgings).lodgingSelection.searchTerm,
                    fromDate,
                    untilDate,
                    adultsCount,
                    children,
                    roomsCount
                  )}`
                );

                mapBounds && setMapBound(null);
                setViewHotelsNearLocation(null);
                resetFilters();
                fetchInitialHotelAvailability(history, false, false);
              }}
            />
          )}
          <AvailabilityFilter />
          {isCustomerProfileExperiment && shouldApplyUserHotelPreferences && (
            <UserPreferencesAppliedBanner
              resetFilters={() => {
                resetFilters();

                trackEvent({
                  eventName: CLEAR_ALL_FILTERS,
                  properties: addTrackingProperties(
                    expState.trackingProperties
                  ),
                });
              }}
              type={"hotel"}
            />
          )}
        </Box>
      </Box>
      {isPolicyDescriptorsEnabled && (
        <>
          <Box className="policy-modal-button-container">
            <PolicyModalButton
              underline={false}
              onClick={onShowPolicyDetailsModal}
            />
          </Box>
          <PolicyDetailsModal
            policies={policies}
            isOpen={isPolicyModalOpen}
            setIsOpen={setIsPolicyModalOpen}
            productType="hotel"
          />
        </>
      )}
    </Box>
  );
};
