import React, { useState } from "react";
import {
  CorpLodging,
  Lodging,
  RoomInfoProductsWithTransformedIndexes,
} from "redmond";
import { Box, Button } from "@material-ui/core";

import "./styles.scss";

interface DebuggingPanelProps {
  lodging: Lodging | CorpLodging;
  roomInfoProductsType?: any;
}
export const CorporateDebuggingPanel = (props: DebuggingPanelProps) => {
  if (!props.roomInfoProductsType) {
    return null;
  }
  const [isToggled, setIsToggled] = useState(false);
  const lodgingChain = props.lodging.trackingPropertiesV2?.properties?.chain;
  const rooms = props.roomInfoProductsType
    .roomInfoProductsWithTransformedIndexes as RoomInfoProductsWithTransformedIndexes[];

  return (
    <Box className="corp-debugging-pannel">
      <Button
        className="corp-debugging-pannel__button"
        onClick={() => setIsToggled(!isToggled)}
      >
        {isToggled ? "Show" : "Hide"}
      </Button>
      {!isToggled &&
        rooms.map((room) => {
          const roomProduct = room.products[0] as any;
          return (
            <Box className="corp-debugging-pannel__inner-box">
              <div>
                <h3>{room.roomInfo.name}</h3>
              </div>
              <div>
                <strong>Provider name</strong> <br />
                {roomProduct.providerName}
                {lodgingChain ? ` (chain: ${lodgingChain})` : ""}
              </div>
              <div>
                <strong>Cancellation policy</strong> <br />
                {roomProduct?.cancellationPolicyV2?.primaryText}
              </div>
              <div>
                <strong>Loyalty eligible</strong> <br />
                {roomProduct?.loyaltyProgramCode ? "✅" : "❌"}
              </div>
              <div>
                <strong>Total Price including taxes and fees</strong>
                <br />
                {roomProduct?.tripTotal.fiat.currencySymbol}
                {roomProduct?.tripTotal.fiat.value}{" "}
                {roomProduct?.tripTotal.fiat.currencyCode}
              </div>
              <div>
                <strong>Total Price excluding taxes and fees</strong>
                <br />
                {roomProduct?.sellRate.fiat.currencySymbol}
                {roomProduct?.sellRate.fiat.value}{" "}
                {roomProduct?.sellRate.fiat.currencyCode}
              </div>
              <hr />
            </Box>
          );
        })}
    </Box>
  );
};
